import { Component, AfterContentInit } from '@angular/core';
import {
  SelectionMode,
  SelectionModeHandler,
  SelectionModeNoneHandler,
} from './selection-mode';
import { Store, select } from '@ngrx/store';
import { MapState } from '../map/map.states';
import { MapActions } from '../map/map.actions';
// import { SelectionModeWandHandler } from './selection-mode-wand';
// import { SelectionModeRectangleHandler } from './selection-mode-rectangle';
// import { SelectionModeHighlighterHandler } from './selection-mode-highlighter';
import { Observable } from 'rxjs';
import { selectSelectionMode } from '../map/map.selector';
import { SelectionModeCombinationHandler } from './selection-mode-combination';

type SelectionModeObj = {
  id: number;
  selectionMode: SelectionMode;
  asset: string;
  handler: SelectionModeHandler;
};

@Component({
  selector: 'app-selection-modes',
  templateUrl: './selection-modes.component.html',
  styleUrls: ['./selection-modes.component.scss'],
})
export class SelectionModesComponent {
  activeSelectionMode: SelectionMode = SelectionMode.None;
  selectionModes: SelectionModeObj[] = [
    // {
    //   id: 2,
    //   selectionMode: SelectionMode.Highlighter,
    //   asset: 'selection_highlighter.svg',
    //   handler: new SelectionModeHighlighterHandler(),
    // },
    // {
    //   id: 1,
    //   selectionMode: SelectionMode.MagicWand,
    //   asset: 'selection_magic_wand.svg',
    //   handler: new SelectionModeWandHandler(),
    // },
    // {
    //   id: 3,
    //   selectionMode: SelectionMode.Rectangle,
    //   asset: 'selection_rectangle.svg',
    //   handler: new SelectionModeRectangleHandler(),
    // },
    {
      id: 4,
      selectionMode: SelectionMode.Combination,
      asset: 'selection_highlighter.svg',
      handler: new SelectionModeCombinationHandler(),
    },
  ];

  selectionMode$: Observable<SelectionModeHandler>;

  constructor(
    public store: Store<{
      mapdata: MapState;
    }>
  ) {
    this.selectionMode$ = this.store
      .select('mapdata')
      .pipe(select(selectSelectionMode));
  }

  toggleSelectionMode(mode: SelectionModeObj): void {
    const newSelectionMode =
      mode.selectionMode == this.activeSelectionMode
        ? SelectionMode.None
        : mode.selectionMode;
    this.activeSelectionMode = newSelectionMode;
    if (this.activeSelectionMode == SelectionMode.None) {
      this.store.dispatch(
        MapActions.changeSelectionMode({
          newSelectionHandler: SelectionModeNoneHandler,
        })
      );
    } else {
      this.store.dispatch(
        MapActions.changeSelectionMode({ newSelectionHandler: mode.handler })
      );
    }
  }
}
