import { createReducer, on } from "@ngrx/store";
import { AuthActions } from "./auth.actions";
import { initialAuthState } from "./auth.states";


export const initialLoggedInState = false;

export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.loadingDone, (state) => ({...state, isLoading: false})),
  on(AuthActions.logout, (state) => ({isLoading: false, isAuthenticated: false, profile: undefined, parsedAccessToken: undefined})),
  on(AuthActions.tokenReceived, (state, {isAuthenticated, parsedAccessToken}) => ({isLoading: false, isAuthenticated: isAuthenticated, profile: state.profile, parsedAccessToken})),

  on(AuthActions.newProfile, (state, {profile}) => ({...state, profile: profile}))
);
