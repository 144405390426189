import { Component, OnChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Field, Scenario } from '../fields/field.model';
import { FieldActions } from '../fields/fields.actions';
import { FieldState } from '../fields/fields.states';

//TODO: check security on sourcing package.json
import packageJson from 'package.json'; //AMAPLAN-23 show version number
import { FieldFilter } from '../field-filter/field-filter.model';
import { ImportActions } from '../field-import/import.actions';
import { isImportDialogOpenSelector } from '../field-import/import.selector';
import { ImportState } from '../field-import/import.states';
import {
  selectCurSelectedFields,
  selectFieldListOpen,
  selectFields,
} from '../fields/fields.selector';
import { MapReferenceService } from '../map/map-reference.service';
import { MapActions } from '../map/map.actions';
import { ExportActions } from '../scenario-export/export.actions';
import { isExportDialogOpenSelector } from '../scenario-export/export.selector';
import { ExportState } from '../scenario-export/export.state';

@Component({
  selector: 'app-field-list',
  templateUrl: './field-list.component.html',
  styleUrls: ['./field-list.component.scss'],
})
export class FieldListComponent implements OnChanges {
  public fields$: Observable<Field[]>;
  public fieldsCurSelectedSub: Subscription;

  public appVersion = packageJson.version;

  isFieldListOpen$: Observable<boolean>;

  areFieldDetailsVisible = false;

  areFieldFilterVisible = false;

  sortFieldDesc = true;

  searchOpen = false;

  filter = new FieldFilter();

  constructor(
    public translate: TranslateService,
    private store: Store<{
      fields: FieldState;
      imports: ImportState;
      exports: ExportState;
    }>,
    private mapReferenceService: MapReferenceService
  ) {
    this.fields$ = this.store.select('fields').pipe(select(selectFields));
    this.isFieldListOpen$ = this.store
      .select('fields')
      .pipe(select(selectFieldListOpen));

    this.fieldsCurSelectedSub = this.store
      .select('fields')
      .pipe(select(selectCurSelectedFields))
      .subscribe((newSelectedFields: Field[]) => {
        if (newSelectedFields.length > 0) {
          setTimeout(() => {
            this.areFieldDetailsVisible = true; // current check for only one selected field at a time
          }, 500);
        } else {
          this.areFieldDetailsVisible = false; // current check for only one selected field at a time
        }
      });
  }

  ngOnChanges(/*changes: SimpleChanges*/): void {
    this.store
      .select('fields')
      .pipe(select(selectFields))
      .subscribe((fields) => {
        console.log('new fields is', fields);
      });
  }

  onSelectField(field: Field) {
    this.store.dispatch(
      FieldActions.selectField({ field: field, select: true })
    );
  }

  clickStartCreateFieldProcess() {
    this.store.dispatch(MapActions.startCreateField());
  }

  clickStartImportFieldProcess() {
    this.store.dispatch(ImportActions.openImportDialog());
  }

  clickStartExportFieldProcess() {
    this.store.dispatch(ExportActions.openExportDialog());
  }

  toggleSort() {
    this.sortFieldDesc = !this.sortFieldDesc;
  }
  toggleFilter(open: boolean) {
    this.areFieldFilterVisible = open;
  }
  toggleSearch() {
    this.searchOpen = !this.searchOpen;
  }

  onInputSearchText(event: Event) {
    let searchText = (<HTMLInputElement>event.target).value;
    // we have to set the filter again to trigger filter pipe
    let tempFilter = this.filter.clone();
    tempFilter.searchText = searchText;
    this.filter = tempFilter;
  }

  isFilterActive() {
    return (
      this.filter.searchText.length > 0 ||
      this.filter.location ||
      this.filter.area.min > FieldFilter.MinAreaRange ||
      this.filter.area.max > -1
    );
  }

  hasValidScenario(scenarios: Scenario[]): boolean {
    let valid = false;
    scenarios.forEach((scenario) => {
      if (scenario.state == 'valid') {
        valid = true;
      }
    });
    return valid;
  }
}
