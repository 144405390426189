class Helper {
  static toSnakeCase(value: string) : string {
    return value.split('').map((character, index) => {
        if(character == character.toUpperCase()) {
          if(index == 0) {
            return character.toLowerCase();
          }
          return '_' + character.toLowerCase();
        }
        return character;
      }).join('');
  }
  static async waitFor(valueFactory: () => boolean, intervalMs: number) {
    if(!valueFactory()) {
      await new Promise(() => setTimeout(() => Helper.waitFor(valueFactory, intervalMs), intervalMs));
    }
  }
  static async waitForCondition(condition: () => boolean) {
    if(!condition()) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }
}

export {
  Helper
}
