import { Field, Scenario } from '../fields/field.model';
import {
  SelectionModeHandler,
  SelectionModeNoneHandler,
} from '../selection-modes/selection-mode';
import {
  SelectedSpreadingType,
  SpreadingType,
} from '../spreading-type-selector/spreading-type';
import { MAPTYPE, MAPMODE, DRAWINGSTATE } from './map-state-control';

export interface MapState {
  mapType: MAPTYPE;
  mapMode: MAPMODE;
  mapCenter: object;
  zoomLevel: number;
  browserLocation: object | undefined; //undefined if user does not allow usage of browser location
  drawingState: DRAWINGSTATE;
  selectionHandler: SelectionModeHandler;

  //fieldCreationState: FIELDCREATIONSTATE,
  //scenarioCreationState: SCENARIOCREATIONSTATE,
  field: Field | undefined;
  scenario: Scenario | undefined;
  nameValid: boolean;
  currentSpreadingType: SelectedSpreadingType;

  // history-steps:
  undoneSteps: number;
  undoableSteps: number;
}

export const initialMapState: MapState = {
  mapType: MAPTYPE.HYBRID,
  mapMode: MAPMODE.DEFAULT,
  mapCenter: { lat: 52.2540982512599, lng: 7.939411175361887 }, //default coordinates of Amazone headquarters
  zoomLevel: 17,
  browserLocation: undefined,
  drawingState: DRAWINGSTATE.UNFINISHED,
  selectionHandler: SelectionModeNoneHandler,
  //fieldCreationState: FIELDCREATIONSTATE.DRAWING,
  //scenarioCreationState: SCENARIOCREATIONSTATE.CREATING,
  field: undefined,
  scenario: undefined,
  nameValid: false,
  currentSpreadingType: new SelectedSpreadingType(
    SpreadingType.NormalSpreading,
    SpreadingType.NormalSpreading
  ),
  undoneSteps: 0,
  undoableSteps: 0,
};
