class DOMHelper {
    static downloadFile(blob: Blob, name: string) {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      a.remove();
      
    }
  static getLocale() {
    if (navigator.languages != undefined)
      return navigator.languages[0];
    return navigator.language;
  }
}

export {
  DOMHelper
}
