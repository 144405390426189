import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Field } from '../fields/field.model';
import { ImportBase } from './import.service';
import { ImportField } from './import.states';

export const ImportActions = createActionGroup({
  source: 'Imports',
  events: {
    // defining an event without payload using the `emptyProps` function
    'open Import Dialog': emptyProps(),
    'close Import Dialog': emptyProps(),

    'select File Type': props<{ importHandler: ImportBase }>(),

    'load Files': props<{
      files: File[];
      importHandler: ImportBase;
      existingFields: Field[];
      simplify: boolean;
    }>(),
    'reset File': emptyProps(),

    'error loading fields': props<{ error: any }>(),
    'Fields received': props<{ importedFields: ImportField[] }>(),

    'Fields imported': props<{ importedFields: ImportField[] }>(),
  },
});
