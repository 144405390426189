import { createSelector } from '@ngrx/store';
import { ProcessProgressState } from './process-progress.states';
import { DRAWINGSTATE, MAPMODE } from '../map/map-state-control';

export const selectProcessMode = createSelector(
  (state: ProcessProgressState) => state.processMode,
  (processMode: MAPMODE) => processMode
);

export const selectProcessLabel = createSelector(
  (state: ProcessProgressState) => state.processLabel,
  (processLabel: String) => processLabel
);

export const selectDrawingState = createSelector(
  (state: ProcessProgressState) => state.drawingState,
  (drawingState: DRAWINGSTATE) => drawingState
);

export const selectStepCounter = createSelector(
  (state: ProcessProgressState) => state.stepCounter,
  (stepCounter: number) => stepCounter
);
