
import { createSelector } from "@ngrx/store";
import { ExportState } from "./export.state";

export const isExportedSelector = createSelector(
    (state:ExportState) => state.isExported,
    (isExported:boolean) => isExported
);

export const isExportDialogOpenSelector = createSelector(
    (state:ExportState) => state.isExportDialogOpen,
    (isExportDialogOpen:boolean) => isExportDialogOpen
);

export const hasError = createSelector(
    (state: ExportState) => state.error,
    (error: boolean) => error
)