import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Field, Scenario } from '../fields/field.model';

export const ExportActions = createActionGroup({
  source: 'Export',
  events: {
    // defining an event without payload using the `emptyProps` function
    'open Export Dialog': emptyProps(),
    'close Export Dialog': emptyProps(),

    'export all scenarios': emptyProps(),
    'export scenarios': props<{ scenarios: Scenario[] }>(),

    'error exporting': props<{ error: any }>(),
    'successfull export': props<{ exported: Blob }>(),
  },
});
