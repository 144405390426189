<section class="assign-name-css">
  <div mat-dialog-title>
    {{ "map.default.toolbar.save.name" | translate }}
  </div>
  <div mat-dialog-content>
    <input
      matInput
      style="margin-top: 16px"
      id="nameInput"
      class="search-input"
      [ngModel]="assignedName"
      (keyup.enter)="onSave()"
      (ngModelChange)="onChangeFieldName($event)"
    />
    <div
      style="margin-top: 4px; display: flex; align-items: center"
      class="name-input-error-wrapper"
    >
      <div *ngIf="displayNameError" style="display: flex; align-items: center">
        <img
          src="/assets/images/common/error_triangle.svg"
          alt="error symbol"
          height="24"
          width="24"
          style="margin-left: 8px"
        />
        <div *ngIf="nameTooShortError" style="margin-left: 12px">
          <div>
            {{ "map.default.toolbar.save.nameLengthError" | translate }}
          </div>
          <div>
            {{
              "map.default.toolbar.save.nameLengthErrorResolution" | translate
            }}
          </div>
        </div>
        <div *ngIf="nameAlreadyExistsError" style="margin-left: 12px">
          <div>
            {{ "map.default.toolbar.save.nameAlreadyExistsError" | translate }}
          </div>
          <div>
            {{
              "map.default.toolbar.save.nameAlreadyExistsErrorResolution"
                | translate
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <div style="display: flex; justify-content: space-between">
      <button
        style="margin-top: 8px; width: 49%"
        mat-flat-button
        (click)="onBack()"
        class="name-overlay-btn-back"
      >
        {{ "map.default.progressbar.back.label" | translate }}
      </button>
      <button
        style="margin-top: 8px; width: 49%"
        mat-flat-button
        (click)="onSave()"
        [class]="{
          'name-overlay-btn-save-active': true,
          'name-overlay-btn-save-inactive': false
        }"
      >
        {{ "map.default.toolbar.save.label" | translate }}
      </button>
    </div>
  </div>
</section>
