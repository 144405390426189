import { AdvancedMarker } from 'src/app/map/map-drawing-logic';
import { TempScenarioData } from 'src/app/map/scenario/temp-scenario-data';
import { leftMouseButtonDown } from 'src/app/selection-modes/selection-mode';

export class SnapToRoute {
  normalProj_: google.maps.Projection | undefined;
  routePixels_: google.maps.Point[];
  map_: google.maps.Map;
  marker?: google.maps.Marker;
  editable_: boolean;
  polyline_: google.maps.Polyline;
  snapOnMouseMove_: boolean;
  snapIfMouseDown_: boolean;
  scenarioDataRef_: TempScenarioData; // needed for getting the current mousebutton-state

  constructor(
    map: google.maps.Map,
    marker: google.maps.Marker | undefined,
    polyline: google.maps.Polyline,
    scenarioData: TempScenarioData,
    snapOnMouseMove: boolean = false,
    snapIfMouseDown: boolean = false
  ) {
    this.routePixels_ = [];
    this.normalProj_ = map.getProjection();
    this.map_ = map;
    this.marker = marker;
    this.editable_ = Boolean(false);
    this.polyline_ = polyline;
    this.snapOnMouseMove_ = snapOnMouseMove;
    this.snapIfMouseDown_ = snapIfMouseDown;
    this.scenarioDataRef_ = scenarioData;
    this.init_();
  }

  init_() {
    this.loadLineData_();
    this.loadMapListener_();
  }

  unload() {
    //TODO:
    console.warn('remove event listener for OLD snap-to-route');
  }

  updateMarker(marker?: AdvancedMarker) {
    this.marker = marker;
  }

  updatePolyline(polyline: google.maps.Polyline) {
    this.polyline_ = polyline || this.polyline_;
    this.loadLineData_();
  }

  loadMapListener_() {
    // eslint-disable-next-line
    const me = this;

    if (this.snapOnMouseMove_ === true) {
      google.maps.event.addListener(me.map_, 'mousemove', function (evt: any) {
        if (
          (me.snapIfMouseDown_ && leftMouseButtonDown(evt)) ||
          !leftMouseButtonDown(evt)
        ) {
          me.updateMarkerLocation_(evt.latLng);
        }
      });
    } else {
      if (!me.marker) {
        console.warn(
          '[SnapToRoute] can not add dragging listener for undefined marker!'
        );
      } else {
        google.maps.event.addListener(
          me.marker,
          'dragend',
          function (evt: any) {
            me.updateMarkerLocation_(evt.latLng);
          }
        );

        google.maps.event.addListener(me.marker, 'drag', function (evt: any) {
          me.updateMarkerLocation_(evt.latLng);
        });
      }
    }

    google.maps.event.addListener(me.map_, 'zoomend', function (evt: any) {
      me.loadLineData_();
    });
  }

  loadLineData_() {
    if (this.normalProj_ != undefined) {
      this.routePixels_ = [];
      const path = this.polyline_.getPath();
      for (let i = 0; i < path.getLength(); i++) {
        const Px = this.normalProj_.fromLatLngToPoint(path.getAt(i));
        if (Px !== null) {
          this.routePixels_.push(Px);
        }
      }
    }
  }

  updateMarkerLocation_(mouseLatLng: google.maps.LatLng) {
    const markerLatLng = this.getClosestLatLng(mouseLatLng);
    this.marker?.setPosition(markerLatLng);
  }

  getClosestLatLng(latlng: google.maps.LatLng) {
    if (this.normalProj_ === undefined) return latlng;
    const r = this.distanceToLines_(latlng);
    if (r.x === undefined || r.y === undefined) return latlng;
    return this.normalProj_.fromPointToLatLng(new google.maps.Point(r.x, r.y));
  }

  getClosestDistance(pos: google.maps.LatLng): number {
    const closestLatLng = this.getClosestLatLng(pos);
    if (!closestLatLng) {
      console.warn('could not find a close point');
      return -1;
    }
    return google.maps.geometry.spherical.computeDistanceBetween(
      pos,
      closestLatLng
    );
  }

  /*   getDistAlongRoute(latlng: google.maps.LatLng) {
    if (typeof(latlng) === 'undefined') {
      latlng = this.marker_.getPosition()!;
    }
    const r = this.distanceToLines_(latlng);
    return this.getDistToLine_(r.i, r.to);
  } */

  distanceToLines_(mouseLatLng: google.maps.LatLng) {
    if (this.normalProj_ === undefined) return { x: 0, y: 0 };
    const mousePx = this.normalProj_.fromLatLngToPoint(mouseLatLng);
    if (mousePx === null) return { x: 0, y: 0 };
    const routePixels_ = this.routePixels_;
    return this.getClosestPointOnLines_(mousePx, routePixels_);
  }

  getDistToLine_(line: number, to: number) {
    const routeOverlay = this.polyline_;
    let d = 0;
    for (let n = 1; n < line; n++) {
      d += google.maps.geometry.spherical.computeDistanceBetween(
        (routeOverlay.getPath() as google.maps.MVCArray).getArray()[n - 1],
        (routeOverlay.getPath() as google.maps.MVCArray).getArray()[n]
      );
    }
    d +=
      google.maps.geometry.spherical.computeDistanceBetween(
        (routeOverlay.getPath() as google.maps.MVCArray).getArray()[line - 1],
        (routeOverlay.getPath() as google.maps.MVCArray).getArray()[line]
      ) * to;
    return d;
  }

  getClosestPointOnLines_(pXy: google.maps.Point, aXys: google.maps.Point[]) {
    let minDist;
    let to;
    let from;
    let x;
    let y;
    let i;
    let dist;

    if (aXys.length > 1) {
      for (let n = 1; n < aXys.length; n++) {
        if (aXys[n].x !== aXys[n - 1].x) {
          const a = (aXys[n].y - aXys[n - 1].y) / (aXys[n].x - aXys[n - 1].x);
          const b = aXys[n].y - a * aXys[n].x;
          dist = Math.abs(a * pXy.x + b - pXy.y) / Math.sqrt(a * a + 1);
        } else {
          dist = Math.abs(pXy.x - aXys[n].x);
        }

        const rl2 =
          Math.pow(aXys[n].y - aXys[n - 1].y, 2) +
          Math.pow(aXys[n].x - aXys[n - 1].x, 2);
        const ln2 =
          Math.pow(aXys[n].y - pXy.y, 2) + Math.pow(aXys[n].x - pXy.x, 2);
        const lnm12 =
          Math.pow(aXys[n - 1].y - pXy.y, 2) +
          Math.pow(aXys[n - 1].x - pXy.x, 2);
        const dist2 = Math.pow(dist, 2);
        const calcrl2 = ln2 - dist2 + lnm12 - dist2;
        if (calcrl2 > rl2) {
          dist = Math.sqrt(Math.min(ln2, lnm12));
        }

        if (minDist == null || minDist > dist) {
          to = Math.sqrt(lnm12 - dist2) / Math.sqrt(rl2);
          from = Math.sqrt(ln2 - dist2) / Math.sqrt(rl2);
          minDist = dist;
          i = n;
        }
      }
      if (to && to > 1) {
        to = 1;
      }
      if (from && from > 1) {
        to = 0;
        from = 1;
      }
      if (i !== undefined && to !== undefined) {
        const dx = aXys[i - 1].x - aXys[i].x;
        const dy = aXys[i - 1].y - aXys[i].y;

        x = aXys[i - 1].x - dx * to;
        y = aXys[i - 1].y - dy * to;
      }
    }
    return {
      x: x,
      y: y,
      i: i,
      to: to,
      from: from,
    };
  }
}
