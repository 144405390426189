<div class="user-login-logout" *ngIf="isAuthenticated$ | async">
  <div class="user-avatar">
    <div class="user-avatar-initials">
      {{(profile$ | async) | userinitials}}
    </div>
  </div>
  <div class="container" style="width: 96px">
    <img src="/assets/images/common/icon_arrow_right.svg" alt="->" width="20" height="20" style="cursor: pointer;" (click)="logout()"/>
    <a class="btn-logout" (click)="logout()">{{'header.logout.label' | translate }}</a>
  </div>
</div>

<div class="user-login-logout" *ngIf="!(isAuthenticated$ | async)">
  <div class="user-avatar"></div>
  <div class="container" style="width: 96px">
    <img src="/assets/images/common/icon_arrow_right.svg" alt="->" width="20" height="20" style="cursor: pointer;" (click)="login()"/>
    <a class="btn-logout" (click)="login()">{{'header.login.label' | translate }}</a>
  </div>
</div>
