import { computeDistanceBetween } from 'spherical-geometry-js';
import { SnapToRoute } from 'src/utilities/snap-to-route';
import {
  liveHighlighterHoverlineColor,
  scenarioHighlighterPolylineColor,
} from '../map/map-drawing-colors';
import {
  AdvancedMarker,
  AdvancedPolyline,
  drawMarker,
} from '../map/map-drawing-logic';
import { MapComponent } from '../map/map.component';
import {
  SelectionMode,
  SelectionModeHandler,
  leftMouseButtonDown,
} from './selection-mode';
import { DOTTYPE } from '../map/map-state-control';

const distanceOnLine = 3;
const distanceDrawingEnabled = 10; //distance to line (in m) when drawing mode is enabled
const hoverOpacity = 0.6;

enum HandlerMode {
  NONE,
  CLOSE_TO_LINE,
  OVER_LINE,
}

type HighlighterDistance = {
  distance: number;
  lineGroup: number;
  pos?: google.maps.LatLng;
};

/**
 * Generic SelectionMode Handler for Scenario editing
 *
 * Modes:
 *  - not close to a line
 *  - close to a line
 *  - over a line
 *
 * Behaviour:
 *  - while moving mouse check if close enough to a line (distanceDrawingEnabled)
 *  - once close enough enable drawing mode and create relevant settings so that changes will snap to close polygon
 *  - if mouse is even over a line (distanceOnLine) then highlight whole line
 */
export class SelectionModeCombinationHandler extends SelectionModeHandler {
  currentHandlerMode: HandlerMode = HandlerMode.NONE; //current HandlerMode
  snapRoutes: SnapToRoute[] = []; // SnapToRoute-instances for snapping the highlighterVicinityCheckIcon

  highlightStartPos: google.maps.LatLng | undefined = undefined;
  highlighterHighlightedPath: AdvancedPolyline | undefined = undefined; // displays the currently highlighted part of the highlighterLivePolyline
  highlightedPathIndices: number[] = [];

  hoverPolyLine: google.maps.Polyline | undefined = undefined; //Polyline used when line is hovered
  currentSnapGroup: number = -1; //ensure that it's set first time
  highlighterCurrentPositionIcon: AdvancedMarker | undefined = undefined; // current position of highlighter icon. visible when close enough to a line (bCursorInVicinityOfPoly)

  directionForward: boolean | undefined = undefined;

  override initializeMap(map: MapComponent): SelectionModeHandler {
    return new SelectionModeCombinationHandler(map);
  }

  constructor(map: MapComponent | undefined = undefined) {
    super(SelectionMode.Combination, map);
  }

  override onStartSelectionMode(): void {
    if (!this.mapInstance) return;

    this.hoverPolyLine = new google.maps.Polyline({
      clickable: false,
      path: [],
      geodesic: true,
      strokeColor: liveHighlighterHoverlineColor, //default value, should be overridden by current selected spreading type
      strokeOpacity: hoverOpacity,
      strokeWeight: 18,
      map: this.mapInstance.map,
      zIndex: 1001,
    });

    this.generateSnapRoutes();
    this.generateHighlighterMarker(this.mapInstance.map.getCenter()!); //start at off position
  }

  generateSnapRoutes() {
    if (!this.mapInstance) return;
    const map = this.mapInstance.map;
    const scenarioData = this.mapInstance.scenarioData;

    const grpCnt = scenarioData.lines.length;
    for (let grp = 0; grp < grpCnt; grp++) {
      const polyline = this.generateScenarioGroupPolyline(grp);
      const snap = new SnapToRoute(
        map,
        undefined,
        polyline,
        scenarioData,
        true,
        true
      );
      this.snapRoutes.push(snap);
    }
  }

  updateSnapRoutes() {
    if (!this.mapInstance) return;
    const scenarioData = this.mapInstance.scenarioData;

    const grpCnt = scenarioData.lines.length;
    for (let grp = 0; grp < grpCnt; grp++) {
      const polyline = this.generateScenarioGroupPolyline(grp);
      const snap = this.snapRoutes[grp];
      //get old polygon, remove reference to map and set new one
      const oldPolygon = snap.polyline_;
      snap.updatePolyline(polyline);
      oldPolygon?.setMap(null);
    }
  }

  override onAfterRedraw(): void {
    this.updateSnapRoutes();
  }

  //generate line of outer boundaries as polygon
  generateScenarioGroupPolyline(group = 0): google.maps.Polyline {
    const path: google.maps.LatLng[] =
      this.mapInstance!.scenarioData.getScenarioPathForGroup(group);

    // the line is drawn (for now) as a test - later on, only the values are really needed
    return new google.maps.Polyline({
      clickable: false,
      path,
      geodesic: true,
      strokeColor: scenarioHighlighterPolylineColor,
      strokeOpacity: 0,
      strokeWeight: 0,
      map: this.mapInstance!.map,
    });
  }

  generateHighlighterMarker(pos: google.maps.LatLng) {
    if (!this.mapInstance) return;

    // create liveIcon for the first time if not done already
    if (this.highlighterCurrentPositionIcon === undefined) {
      this.highlighterCurrentPositionIcon = new google.maps.Marker({
        icon: {
          url: 'assets/images/selection-mode/highlighterDotMarker.svg',
          size: new google.maps.Size(17, 17), // alter to display the current marker position
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(9, 9),
          scaledSize: new google.maps.Size(17, 17),
        },
        position: pos,
        map: this.mapInstance.map,
        clickable: false,
        draggable: false,
        zIndex: 5,
      });
      this.highlighterCurrentPositionIcon.uuid = 'highlighterDotMarker';
      this.highlighterCurrentPositionIcon.setOpacity(0);
    }
    // SnapToRoute for this marker happens on mousedown (when in vicinity of the lines)
  }

  override onExitSelectionMode() {
    //TODO: cleanup SnapRoutes

    this.highlighterHighlightedPath?.setMap(null);
    this.highlighterHighlightedPath = undefined;

    this.highlighterCurrentPositionIcon?.setMap(null);
    this.highlighterCurrentPositionIcon = undefined;
  }

  override onMouseUp(event: google.maps.MapMouseEvent): void {
    switch (this.currentHandlerMode) {
      case HandlerMode.OVER_LINE:
      case HandlerMode.CLOSE_TO_LINE:
        if (this.highlightStartPos) {
          // we where highlighting...
          if (this.highlightedDistance() < 2) {
            if (this.currentHandlerMode == HandlerMode.OVER_LINE) {
              //highlighting distance not long enough... activate whole line as likely clicked on it
              //mouse down event has to happen close to line
              let lineData =
                this.mapInstance?.scenarioData.getLineRefForPosition(
                  this.highlightStartPos
                )?.lineData;
              if (lineData) {
                lineData.spreadingType =
                  this.mapInstance?.currentSpreadingType!;
                this.mapInstance?.dispatchScenarioUpdate();
              }
            } else {
              //highlighting distance not long enough... activate whole line as likely clicked on it
              console.warn(
                'Highlighted distance too small but no longer over line'
              );
            }
          } else {
            this.highlightingEndCheck();
          }
          // this.removeHighlighterLiveIcons(true);
        }
        break;
      default:
        break;
    }
    this.removeHighlighterLiveIcons(true);
    this.highlightedPathIndices = [];
    // this.stopHighlighting();
    this.directionForward = undefined;
  }

  override onMouseDown(event: google.maps.MapMouseEvent): void {
    if (this.mapInstance === undefined) return;

    switch (this.currentHandlerMode) {
      case HandlerMode.OVER_LINE:
      case HandlerMode.CLOSE_TO_LINE:
        if (!this.highlightStartPos) {
          //first, set new possible StartPos
          this.highlightStartPos =
            this.highlighterCurrentPositionIcon!.getPosition()!;
        }
        break;
      default:
        return;
    }
  }

  handleMouseMoveNone(
    event: google.maps.MapMouseEvent,
    distanceToNextLine: HighlighterDistance
  ) {
    if (!isInDrawingDistance(distanceToNextLine)) {
      //to be on he save side remove highlighted polyline
      this.hoverPolyLine?.setOptions({
        strokeOpacity: 0,
      });
      return;
    }

    this.enableHighlightingOnMap(distanceToNextLine.lineGroup);
  }

  handleMouseMoveCloseToLine(
    event: google.maps.MapMouseEvent,
    distanceToNextLine: HighlighterDistance
  ) {
    const leftButtonPressed = leftMouseButtonDown(event.domEvent);

    //disable mode if not left mouse button pressed and out of range
    if (!leftButtonPressed && !isInDrawingDistance(distanceToNextLine)) {
      this.disableHighlightingOnMap();
      this.currentHandlerMode = HandlerMode.NONE;
      return;
    }

    if (leftButtonPressed) {
      if (!this.highlightStartPos && event.latLng) {
        //first, set new possible StartPos
        this.highlightStartPos =
          this.highlighterCurrentPositionIcon!.getPosition()!;
        //this.generateHighlighterLivePolyline(); //initialize polyline
      }
      this.drawHighlightedPathPolyline(this.createHighlightedPath());
    } else {
      this.enableHighlightingOnMap(distanceToNextLine.lineGroup);
      //left button not pressed but still at least close to line
      if (distanceToNextLine.distance <= distanceOnLine) {
        this.currentHandlerMode = HandlerMode.OVER_LINE;
      } else {
        this.currentHandlerMode = HandlerMode.CLOSE_TO_LINE;
      }
      this.handleLineHover(distanceToNextLine);
    }
  }

  override onMouseMove(event: google.maps.MapMouseEvent): void {
    if (!this.mapInstance || !event.latLng) return;

    const distanceToNextLine = this.calcDistanceToNextLine_(event.latLng);

    switch (this.currentHandlerMode) {
      case HandlerMode.NONE: {
        this.handleMouseMoveNone(event, distanceToNextLine);

        break;
      }
      case HandlerMode.OVER_LINE:
      case HandlerMode.CLOSE_TO_LINE:
        this.handleMouseMoveCloseToLine(event, distanceToNextLine);
        break;
      default:
    }
  }

  /////////////
  // Drawing
  ////////////////
  enableHighlightingOnMap(lineGroup: number) {
    this.currentHandlerMode = HandlerMode.CLOSE_TO_LINE;

    //set cursor to highlighter
    this.mapInstance!.map.setOptions({
      draggable: false,
      draggableCursor:
        'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAxCAMAAAD6FOlzAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAfVQTFRFAAAA/////////////////////////////////////////////v7+////////////+vr68PDw////////////6Ojo5+fn5ubm////////////z8/P+/v7u7u74uLi/////////////////v7+8vLy0NDQurq63Nzc////////9PT02tra2NjYxMTE1NTU////////////3d3d0dHR////////////zc3N/Pz8////////////xsbGwMDAvr6+ycnJ+/v7////////7u7u0tLS+vr6////////////1tbW+Pj4////////6enpwsLC9vb2////////7Ozsv7+/8/Pz////////8fHx////////+fn51dXVvLy8////////xcXF/Pz809PT6+vr////////////9/f3////////vb29+Pj4////////////39/f7+/v/////f39x8fH5eXl////4+PjyMjI7e3t////9fX1////////////////4eHh////////////////19fX////////////6urq/////////////////////////////////////////////////////////////////////f39////////////////////////////////////////////////////W4ZX2AAAAKd0Uk5TAIgW6O0f/+wlKy5V//E6f///9kOr////+k/W//////1bA/z+//////5p//////+PAYD//6QCqv/+sAXV//////68Cf///scOKv/+0RP////aGv///+Eh/+nU////7zP/////8yQp/8JT//9BY6n//3j///9i////Rf8oVGxg/1K0DNv/l3ky/1w39T89QvldSLP7DU5fWoQs/lAHsgsRxG0XznceQCdkcEq1AAACQklEQVR4nH3T6UMSQRgG8K3o6cJoK8CEtVJ5JUtLrewAyuiwrLCo7KAiLQpTCKnsvk8rssvu+/g7m9lZlnUZfD7wYX7sy7Mzg6LwTJmqTJZpjumTMTBjZmWdBeazK/IcwImquZXYhXlQMX9BBV4INzxeVC+Scw188Gu1WLxEpkvr6rUGBKgRwWXS4k20HB6iFYBLVtxNzWw6UUsQK1fZuRVtRCqbTtRej9VrbLwW7Xwwm07UsQ7rN0zkjQgRheGPcN+0GZ1b7MXZehRbOVNoG7bvsHAXK86yU0wnat6F7t3W4nv4atgpphPtjaFnn8kuXpxlvzGdKH4ABw+ViovlFkRDmvBILw53GXyEF2cJsFM/ekx4IorjJwQD+srJJHhOGd6H/tNcz4ji5NEVKeP3yY2znM+J4tQkGHGD0wM4byneYHC6+HgtBhkP8R1nyQjNFpXacMFSPDzANTdsch4XzR3nfinl7LtsKo3gSql4ea7imqJcN4qXJwd2sDeQkWscDtbsJm7JeRjdjG8jFZGyD3cY372HbELG9/FA3/MqjMg4g4f6iT3qx2OtnD14Ik706ajs5bJ4ZlyI54DXrukUzPv0ooD8BAu4kym8NK/jWKH0+glm7HBieFW6zWOvxe5pPq/fOPhg9ZuSv61Do9aRL5r++DvLn2Wc3VNY4let0xXlPfu+adFe9umw8gfkhCWz6kdgdOjTZysrPdyFFb6Mf1VsaUVMzfLHOwe/2U3/Q/B8//FTYjw1+PX7TwVj+fvPvvIfiIac6lOpRMUAAAAASUVORK5CYII="), auto',
    });

    this.updateHighlighterSnapRoute(lineGroup);

    this.highlighterCurrentPositionIcon?.setOpacity(1);
  }

  updateHighlighterSnapRoute(grp: number) {
    if (this.currentSnapGroup == grp) return;
    if (this.currentSnapGroup >= 0) {
      //reset old marker
      this.snapRoutes[this.currentSnapGroup].updateMarker(undefined);
    }
    //set new marker
    this.snapRoutes[grp].updateMarker(this.highlighterCurrentPositionIcon);
    this.currentSnapGroup = grp;
  }

  disableHighlightingOnMap() {
    this.mapInstance!.map.setOptions({
      draggable: true,
      draggableCursor: '',
    });
    this.highlighterCurrentPositionIcon?.setOpacity(0);
  }

  handleLineHover(distanceToNextLine: HighlighterDistance) {
    if (distanceToNextLine.distance <= distanceOnLine) {
      const path: google.maps.LatLng[] = [];

      const points = this.snapRoutes[distanceToNextLine.lineGroup].polyline_
        .getPath()
        .getArray();

      const startIdx = getStartIndexOfCoordinatesPointIsOnLine(
        points,
        this.highlighterCurrentPositionIcon!.getPosition()!
      );
      if (startIdx != undefined) {
        //startIdx can't be zero
        path.push(points[startIdx]);
        path.push(points[startIdx + 1]);
      }
      this.hoverPolyLine?.setPath(path);
      this.hoverPolyLine?.setOptions({
        strokeOpacity: hoverOpacity,
        strokeColor: this.mapInstance!.currentSpreadingType.color,
      });
    } else {
      this.hoverPolyLine?.setOptions({
        strokeOpacity: 0,
      });
      this.highlighterHighlightedPath?.setMap(null);
    }
  }

  setHighlightedPathIndices(startIdx: number, currentIdx: number) {
    const pathLength = this.highlightedPathIndices.length;
    //path was empty before, add first line
    if (pathLength == 0) {
      this.highlightedPathIndices.push(startIdx);
    } else {
      //if direction was changed and prior added line is removed
      if (
        pathLength > 1 &&
        this.highlightedPathIndices[pathLength - 2] == currentIdx
      ) {
        this.highlightedPathIndices.pop();
      } else if (this.highlightedPathIndices[pathLength - 1] != currentIdx) {
        //only add when new line included
        this.highlightedPathIndices.push(currentIdx);
      }
    }
  }

  setHighlightingDirection(startIndex: number, snapLinePoints) {
    const disStartToLineEnd = computeDistanceBetween(
      this.highlightStartPos!,
      snapLinePoints[startIndex! + 1]
    );
    const disCurToLineEnd = computeDistanceBetween(
      this.highlighterCurrentPositionIcon!.getPosition()!,
      snapLinePoints[startIndex! + 1]
    );

    if (this.highlightedPathIndices.length == 1) {
      this.directionForward = disStartToLineEnd > disCurToLineEnd;
    } else if (
      this.highlightedPathIndices.length >
      this.mapInstance!.scenarioData.lines.length / 2
    ) {
      //when start points is lapped, the direction calculation switches
      //then the indices shall be emptied and the lighlighter "starts again"
      if (this.directionForward != disStartToLineEnd < disCurToLineEnd) {
        this.highlightedPathIndices = [];
        this.highlightedPathIndices.push[startIndex];
        this.directionForward = disStartToLineEnd > disCurToLineEnd;
      }
    }
  }

  createHighlightedPath(): google.maps.LatLng[] {
    let pathToHighlight: google.maps.LatLng[] = [];
    if (!this.highlightStartPos) return pathToHighlight;

    pathToHighlight.push(this.highlightStartPos);

    //first get Line-Points from Snap-Line
    const snapLinePoints = (
      this.snapRoutes[
        this.currentSnapGroup
      ].polyline_.getPath() as google.maps.MVCArray
    )
      .getArray()
      .map((v) => new google.maps.LatLng(v.lat(), v.lng()));

    const startIndex = getStartIndexOfCoordinatesPointIsOnLine(
      snapLinePoints,
      this.highlightStartPos
    );

    let endLineIdx4Dir = getStartIndexOfCoordinatesPointIsOnLine(
      snapLinePoints,
      this.highlighterCurrentPositionIcon!.getPosition()!,
      startIndex
    );
    if (endLineIdx4Dir == undefined) {
      endLineIdx4Dir = getStartIndexOfCoordinatesPointIsOnLine(
        snapLinePoints,
        this.highlighterCurrentPositionIcon!.getPosition()!,
        0
      );
    }
    this.setHighlightedPathIndices(startIndex!, endLineIdx4Dir!);
    if (startIndex == endLineIdx4Dir)
      this.setHighlightingDirection(startIndex!, snapLinePoints);

    const bDirForward =
      this.directionForward !== undefined ? this.directionForward : true; // fallback to true if direction is not already certain
    console.log(bDirForward);
    let endLineIndex = getStartIndexOfCoordinatesPointIsOnLine(
      // need to do it again here to create pathToHighlight
      snapLinePoints,
      this.highlighterCurrentPositionIcon!.getPosition()!,
      startIndex,
      bDirForward,
      (index, startCoordinate, endCoordinate) => {
        pathToHighlight.push(bDirForward ? endCoordinate : startCoordinate);
      }
    );

    if (endLineIndex == undefined) {
      //not found yet.. start from the beginning
      endLineIndex = getStartIndexOfCoordinatesPointIsOnLine(
        snapLinePoints,
        this.highlighterCurrentPositionIcon!.getPosition()!,
        bDirForward ? 0 : snapLinePoints.length - 2,
        bDirForward,
        (index, startCoordinate, endCoordinate) => {
          pathToHighlight.push(bDirForward ? endCoordinate : startCoordinate);
        }
      );
    }
    if (endLineIndex == undefined) {
      // TODO: remove when not happening anymore
      endLineIndex = startIndex;
    }

    if (startIndex == endLineIndex && this.highlightedPathIndices.length > 1) {
      console.log(
        'highlighted path surroundes entire field and ends on same line again'
      );
      //reset highlightedPath to build it according to scenario lines
      pathToHighlight = [pathToHighlight[0]];
      let currIdx = startIndex!;
      let nextIdx = currIdx;
      const lineGroup =
        this.mapInstance!.scenarioData.lines[this.currentSnapGroup];

      while (pathToHighlight.length < lineGroup.length + 1) {
        if (bDirForward) {
          nextIdx = currIdx == lineGroup.length - 1 ? 0 : currIdx + 1;
          let coordinate = snapLinePoints[nextIdx];
          pathToHighlight.push(coordinate);
        } else {
          let coordinate = snapLinePoints[currIdx];
          pathToHighlight.push(coordinate);
          nextIdx = currIdx == 0 ? lineGroup.length - 1 : currIdx - 1;
        }
        currIdx = nextIdx;
      }
    }

    // moves start of path to corner if necesary
    let highlighterStart = this.mapInstance?.scenarioData.moveToCornerPoint(
      this.highlightStartPos,
      bDirForward
    )!;
    //.. or to group start
    let startDotsRestOfGroup = this.mapInstance?.scenarioData.moveToGroupEnd(
      highlighterStart,
      bDirForward,
      true
    )!;
    //overrides start
    pathToHighlight.splice(0, 1, ...startDotsRestOfGroup);

    //moves end of path to group end
    let endDotsRestOfGroup = this.mapInstance?.scenarioData.moveToGroupEnd(
      this.highlighterCurrentPositionIcon!.getPosition()!,
      bDirForward,
      false
    )!;
    //adds end
    pathToHighlight = pathToHighlight.concat(endDotsRestOfGroup);

    //in case of backward : reverse the path to enable usual handling of path in further functions
    return bDirForward ? pathToHighlight : pathToHighlight.reverse();
  }

  // draws a visualization for what is expected to be selected if the user lifts the mousecursor up right now
  drawHighlightedPathPolyline(pathToHighlight) {
    if (!this.mapInstance) return;

    //disable highlighted Border
    this.hoverPolyLine?.setOptions({
      strokeOpacity: 0,
    });

    //disable old one if existend
    this.highlighterHighlightedPath?.setMap(null);

    //check bigger then 1 as startPosition is always included
    if (pathToHighlight.length > 1) {
      this.highlighterHighlightedPath = new google.maps.Polyline({
        clickable: false,
        path: pathToHighlight,
        geodesic: true,
        strokeColor: this.mapInstance.currentSpreadingType.color,
        strokeOpacity: 1,
        strokeWeight: 7,
        map: this.mapInstance.map,
        zIndex: 1001,
      });
    }
  }

  removeHighlighterLiveIcons(bRemoveLivePolylineAfterTimeout = false) {
    // hide the highlighterLivePolyline, the startIcon and the liveIcon
    this.highlightStartPos = undefined;

    this.highlighterCurrentPositionIcon?.setOpacity(0); //not fully removing marker (only onExit)

    if (bRemoveLivePolylineAfterTimeout) {
      // remove if there should not be a delay before removing the green marker-line
      setTimeout(() => {
        this.highlighterHighlightedPath?.setMap(null);
        this.highlighterHighlightedPath = undefined;
      }, 200);
    } else {
      this.highlighterHighlightedPath?.setMap(null);
      this.highlighterHighlightedPath = undefined;
    }
  }

  highlightedDistance(): number {
    if (!this.highlighterHighlightedPath) return 0;

    const highlightedPath = this.highlighterHighlightedPath
      .getPath()
      .getArray();

    if (highlightedPath.length > 1) {
      const hPathDistance = computeDistanceBetween(
        highlightedPath[0],
        highlightedPath[highlightedPath.length - 1]
      );
      return hPathDistance;
    }
    return 0;
  }

  // evaluates the highlighted path, removes/creates new splitDots and sets the selected spreading mode to all selected segments
  highlightingEndCheck() {
    if (
      !this.mapInstance ||
      !this.highlightStartPos ||
      !this.highlighterHighlightedPath
    )
      return;

    //something is highlighted

    // cancel if the selection is too short
    const highlightedPath = this.highlighterHighlightedPath
      .getPath()
      .getArray();

    //TODO: do we need this? - removeAllSpreadingTypeIcons(this.mapInstance.scenarioData); // icons are removed before any alteration on the lines are going on

    let startMarkerRef: AdvancedMarker | undefined = undefined; // has a reference to the (new) splitDot marker at the start of the selection (if there was one created)
    let endMarkerRef: AdvancedMarker | undefined = undefined; // has a reference to the (new) splitDot marker at the end of the selection (if there was one created)

    //TODO: if highlighterStartLineWithEndInVicinity is not set, a split dot has to be created on the corresponding line (which has to be evaluated)
    if (!this.mapInstance.scenarioData.isDuplicate(highlightedPath[0])) {
      startMarkerRef = this.mapInstance.scenarioData.splitScenarioLine(
        highlightedPath[0] // use start of highlightedPath instead of this.highlightStartPos in case of backward drawing, as the path is provided in reversed order!
      );
    }

    // draw new end-dot as splitDot at end of highlighted path if not very close to existing dot
    // depending on the highlighterEndLineWithEndInVicinity existing, that line should be the end of the selected lines, not creating a split-dot for the start
    //TODO: if highlighterEndLineWithEndInVicinity is not set, a split dot has to be created on the corresponding line (which has to be evaluated)
    // highlighterEndLineWithEndInVicinity.dotEnd is the end of the highlighted line
    // create new splitDot at highlighterStartIcon
    const splittingPos = highlightedPath[highlightedPath.length - 1];
    if (!this.mapInstance.scenarioData.isDuplicate(splittingPos)) {
      endMarkerRef =
        this.mapInstance.scenarioData.splitScenarioLine(splittingPos);
    }

    this.mapInstance.scenarioData.setSpreadingTypeBetweenMarker(
      highlightedPath,
      this.mapInstance.currentSpreadingType,
      true
    );

    this.mapInstance.dispatchScenarioUpdate();
  }

  /******************************************************** Event handling   */

  /**
   *
   * @param pos
   * @returns object that constains the distance information
   */
  calcDistanceToNextLine_(pos?: google.maps.LatLng): HighlighterDistance {
    let distance = 1000;
    let myGroup = -1;
    if (!pos) return { distance: distance, lineGroup: myGroup, pos: pos };

    for (let grp = 0; grp < this.snapRoutes.length; grp++) {
      const snap = this.snapRoutes[grp];
      const curDistance = snap.getClosestDistance(pos);
      if (curDistance < distance) {
        myGroup = grp;
        distance = curDistance;
      }
    }

    return { distance: distance, lineGroup: myGroup, pos: pos };
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// internal helping functions

function isInDrawingDistance(distanceToNextLine: HighlighterDistance) {
  return distanceToNextLine.distance < distanceDrawingEnabled;
}

/**
 *
 * @param startPoint
 * @param endPoint
 * @param checkPoint
 * @returns true if checkPoint is on the line between startPoint and endPoint
 */
function pointIsOnLine(
  startPoint: google.maps.LatLng,
  endPoint: google.maps.LatLng,
  checkPoint: google.maps.LatLng
): boolean {
  return google.maps.geometry.poly.isLocationOnEdge(
    checkPoint,
    new google.maps.Polyline({
      clickable: false,
      path: [startPoint, endPoint],
    }),
    0.0000001
  );
}

/**
 *
 * @param snapLinePoints
 * @param searchPos
 * @returns index in provided array that is the startPoint of Line the provided point is on
 */
function getStartIndexOfCoordinatesPointIsOnLine(
  snapLinePoints: google.maps.LatLng[],
  searchPos: google.maps.LatLng,
  givenStartIndex: number = 0,
  dirForward: boolean = true,
  callbackSkippedIndex = (
    idx: number,
    startCoordinate: google.maps.LatLng,
    endCoordinate: google.maps.LatLng
  ) => {}
): number | undefined {
  // first find starting Segment
  let startIndex = givenStartIndex;
  while (
    ((dirForward && startIndex < snapLinePoints.length - 1) ||
      (!dirForward && startIndex >= 0)) && //safety in case point is on no segment
    !pointIsOnLine(
      snapLinePoints[startIndex],
      snapLinePoints[startIndex + 1],
      searchPos
    )
  ) {
    callbackSkippedIndex(
      startIndex,
      snapLinePoints[startIndex],
      snapLinePoints[startIndex + 1]
    );
    if (dirForward) {
      startIndex++;
    } else {
      startIndex--;
    }
  }
  if (
    (dirForward && startIndex > snapLinePoints.length - 2) ||
    (!dirForward && startIndex < 0)
  ) {
    return undefined; //not found
  }
  return startIndex;
}
