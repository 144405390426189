import {
  drawLinePossibleColor,
  drawLineTempClosingColor,
} from '../map/map-drawing-colors';
import {
  AdvancedMarker,
  AdvancedPolyline,
  FieldLine,
  drawLine,
  drawMarker,
  getSecondToLastFieldShapeLine,
  getStartPosOfLine,
  isClosedFieldShape,
  redrawLine,
  movePolyLine,
  changeLineColor,
} from '../map/map-drawing-logic';
import { DOTTYPE } from '../map/map-state-control';
import { MapComponent } from '../map/map.component';
import {
  SelectionMode,
  SelectionModeHandler,
  leftMouseButtonDown,
} from './selection-mode';
import { removeFillPoly } from '../map/field/map-drawing-logic-field';

type TempSplitDotDraggingData = {
  splitDot: AdvancedMarker;
  currentLine: AdvancedPolyline;
  nextLine: AdvancedPolyline;
};

export class SelectionModeFieldHandler extends SelectionModeHandler {
  possibleNewLine: google.maps.Polyline | undefined = undefined;
  clickTimer: Date | undefined = undefined;

  curDragging = false; //whether or not the user currently drags a dot/marker
  curDraggingStartDot = false;
  curDraggedLine: FieldLine | undefined = undefined;
  curDraggedLineNext: FieldLine | undefined = undefined;
  tempSplitDotDraggingData: TempSplitDotDraggingData | undefined;

  tempClosingLine: google.maps.Polyline | undefined = undefined;

  currentPosMarker: AdvancedMarker | undefined;

  constructor(map: MapComponent | undefined = undefined) {
    super(SelectionMode.Highlighter, map);
  }

  override initializeMap(map: MapComponent): SelectionModeHandler {
    return new SelectionModeFieldHandler(map);
  }

  override onStartSelectionMode(): void {
    if (this.mapInstance?.map == undefined) return;
    this.currentPosMarker = drawMarker(
      this.mapInstance.map,
      DOTTYPE.End,
      this.mapInstance.map.getCenter()!
    );
    this.currentPosMarker.setZIndex(1000);
    this.currentPosMarker.setOpacity(0);
  }

  override onExitSelectionMode(): void {
    //hide possible new Line
    this.possibleNewLine?.setMap(null);
    this.possibleNewLine = undefined;

    this.currentPosMarker?.setMap(null);
    this.currentPosMarker = undefined;
  }

  override onBeforeRedraw(): void {
    this.removeTempClosingLine();
  }

  override onAfterRedraw(): void {
    this.drawTempClosingLine();
  }

  //////////////////////////
  // Events
  /////////////////////////
  override onMouseDown(event: google.maps.MapMouseEvent): void {
    const leftButtonPressed = leftMouseButtonDown(event.domEvent);
    if (leftButtonPressed) this.clickTimer = new Date();
  }

  override onMouseMove(event: google.maps.MapMouseEvent): void {
    if (event.latLng) this.redrawPossibleNewLine(event.latLng);

    //handle move marker
    this.currentPosMarker?.setPosition(event.latLng);
    this.currentPosMarker?.setOpacity(
      isClosedFieldShape(this.mapInstance!.fieldShape) ? 0 : 1
    );
  }

  override onMouseUp(
    event: MouseEvent | google.maps.MapMouseEvent | undefined
  ): void {
    const newDate = new Date();
    if (
      typeof this.clickTimer != 'undefined' &&
      newDate.getTime() - this.clickTimer.getTime() < 200
    ) {
      if (event) {
        this.onClicked(event);
      } else {
        console.log('onMouseUp with unexpected event Type: ', event);
      }
    }
  }

  onClicked(event /*google.maps.MapMouseEvent*/): void {
    if (!this.mapInstance) return;
    const fieldShape = this.mapInstance.fieldShape;
    if (isClosedFieldShape(fieldShape)) return;
    if (!fieldShape.dotStart) {
      //we don't have a starting point
      fieldShape.dotStart = drawMarker(
        this.mapInstance.map,
        DOTTYPE.Start,
        event.latLng,
        this.mapInstance
      );
      this.mapInstance.dispatchFieldUpdate();
      return;
    }

    //if this is at least the 3rd point and it is close to starting point, close polygon
    if (
      this.closeToStartPoint(event) &&
      this.mapInstance.fieldShape.lineCount() > 2
    ) {
      this.removeTempClosingLine();
      this.closePolygon();
      return;
    }

    // point we add is now the last point
    // create the new marker
    const newMarker = drawMarker(
      this.mapInstance.map,
      DOTTYPE.End,
      event.latLng,
      this.mapInstance
    );

    this.mapInstance.fieldShape.lines.push({
      // add this line
      id: crypto.randomUUID(),
      dotSplit: undefined,
      dotEnd: newMarker,
      line: undefined,
    });

    this.redrawTempClosingLineIfApplicable();
    this.mapInstance.dispatchFieldUpdate();
  }

  override onMarkerDrag(marker: AdvancedMarker): void {
    if (!this.mapInstance) return;

    // debounce(() => {
    switch (marker.DOTTYPE) {
      case DOTTYPE.Start:
      case DOTTYPE.Split:
        this.redrawCurLine();
        this.redrawNextLine();
        break;
      case DOTTYPE.End:
        if (this.tempSplitDotDraggingData != undefined) break; // ignore dragging of dotEnd if there is a splitdot being dragged
        this.redrawTempClosingLineIfApplicable();
        this.redrawCurLine();
        const shapeClosed = isClosedFieldShape(this.mapInstance.fieldShape);
        //exclusion zones will only be (re)drawn after field shape is finished and therefor shapeClosed = true
        if (
          shapeClosed ||
          (!shapeClosed &&
            marker.uuid !=
              this.mapInstance.fieldShape.lines[
                this.mapInstance.fieldShape.lines.length - 1
              ].dotEnd?.uuid)
        ) {
          this.redrawNextLine();
        }
        break;
      default:
    }
  }

  override onMarkerMouseOut(marker: AdvancedMarker): void {
    // remove icon for possible closing field when moving cursor away
    if (
      marker.DOTTYPE == DOTTYPE.Start &&
      !isClosedFieldShape(this.mapInstance!.fieldShape)
    ) {
      this.currentPosMarker?.setVisible(true);
      this.mapInstance!.bIsTooltipFieldShapeCloseVisible = false;
    }
  }

  override onMarkerMouseOver(marker: AdvancedMarker): void {
    // show icon for possible closing field
    if (
      marker.DOTTYPE == DOTTYPE.Start &&
      !isClosedFieldShape(this.mapInstance!.fieldShape) &&
      this.mapInstance!.fieldShape.lines.length > 1
    ) {
      this.currentPosMarker?.setVisible(false);
      this.mapInstance!.bIsTooltipFieldShapeCloseVisible = true;
    }
  }

  override onMarkerDragStart(marker: AdvancedMarker): void {
    if (!this.mapInstance) return;

    removeFillPoly(this.mapInstance.fieldShape);
    this.removePossibleNewLine();
    this.redrawTempClosingLineIfApplicable();
    this.setDraggingVariables(marker);
  }

  override onMarkerClick(marker: AdvancedMarker): void {
    if (!this.mapInstance) return;

    switch (marker.DOTTYPE) {
      case DOTTYPE.Split:
        this.mapInstance.splitLine(marker);
        break;
      case DOTTYPE.Start:
        // remove icon for possible closing field
        this.mapInstance.bIsTooltipFieldShapeCloseVisible = false;
        this.closePolygon(); //DOTTYPE.Start only drawn if field is not closed!
        break;
      case DOTTYPE.End:
        this.mapInstance.removeMarkerFromShape(marker);
    }
  }

  override onMarkerDragEnd(marker: AdvancedMarker): void {
    // reset helper-lines
    if (this.tempSplitDotDraggingData) {
      this.tempSplitDotDraggingData.currentLine.setMap(null);
      this.tempSplitDotDraggingData.nextLine.setMap(null);
      this.tempSplitDotDraggingData = undefined;
    }
    //check if markerType is splitPoint.
    //In this split and add point in new location
    if (marker.DOTTYPE == DOTTYPE.Split) {
      this.mapInstance?.splitLine(marker);
    }

    this.mapInstance?.dispatchFieldUpdate();
  }

  //////////////////////////
  // Logic
  //////////////////////////
  // check if provided event is close to starting position
  closeToStartPoint(event: any): boolean {
    // check for possible last dot for snapping:
    if (!this.mapInstance?.fieldShape.dotStart) return false;

    const posStartDot = this.mapInstance.fieldShape.dotStart!.getPosition()!;
    const accOffsForSnapping = 0.00003;

    return (
      posStartDot.lat() <= event.latLng!.lat() + accOffsForSnapping &&
      posStartDot.lat() >= event.latLng!.lat() - accOffsForSnapping &&
      posStartDot.lng() <= event.latLng!.lng() + accOffsForSnapping &&
      posStartDot.lng() >= event.latLng!.lng() - accOffsForSnapping
    );
  }

  setDraggingVariables(marker: AdvancedMarker) {
    if (!this.mapInstance) return;

    // is the start-dot being dragged?
    if (
      this.mapInstance.fieldShape.dotStart !== undefined &&
      this.mapInstance.fieldShape.dotStart.uuid == marker.uuid
    ) {
      this.curDraggingStartDot = true;
      this.curDragging = true;
      if (isClosedFieldShape(this.mapInstance.fieldShape)) {
        this.curDraggedLine =
          this.mapInstance.fieldShape.lines[
            this.mapInstance.fieldShape.lines.length - 1
          ];
        this.curDraggedLineNext = this.mapInstance.fieldShape.lines[0];
      } else {
        this.curDraggedLine = this.mapInstance.fieldShape.lines[0];
      }
    } else if (marker.DOTTYPE === DOTTYPE.Split) {
      // create lines to be shown while dragging the split-dot
      this.createTempDraggingDataForSplitDot(marker);
    } else {
      // search for which lines are affected by this end-dot that is being dragged
      // checks for outter border first, then the inner borders. If corresponding marker is not found, nothing happens
      this.setDraggingVariablesForMatchingMarker(
        this.mapInstance.fieldShape.lines,
        marker
      );
      for (
        let exclusionIndex = 0;
        exclusionIndex < this.mapInstance.fieldShape.exclusions.length;
        exclusionIndex++
      ) {
        let exclusionLines =
          this.mapInstance.fieldShape.exclusions[exclusionIndex].lines;
        this.setDraggingVariablesForMatchingMarker(exclusionLines, marker);
      }
    }
  }

  setDraggingVariablesForMatchingMarker(
    lines: FieldLine[],
    marker: AdvancedMarker
  ) {
    lines.forEach((fieldLine, index) => {
      if (
        fieldLine.dotSplit != undefined &&
        fieldLine.dotEnd &&
        fieldLine.dotEnd.uuid == marker.uuid
      ) {
        this.curDraggingStartDot = false;
        this.curDragging = true;
        this.curDraggedLine = lines[index];
        const nextLineIfExists = lines[index + 1]
          ? lines[index + 1]
          : undefined;
        // next line is undefined if the user is still drawing
        //console.log('Dragstart ', index === (getCurLineCount(mapInstance.fieldShape) - 1), mapInstance.curStates.drawingState === DRAWINGSTATE.FINISHED, nextLineIfExists,  mapInstance.curDraggedLineNext)

        this.curDraggedLineNext =
          index === lines.length - 1 && this.mapInstance!.isDrawingFinished
            ? lines[0]
            : nextLineIfExists;
        this.redrawCurLine();
        if (
          !isClosedFieldShape(this.mapInstance!.fieldShape) ||
          (isClosedFieldShape(this.mapInstance!.fieldShape) &&
            marker.uuid != lines[lines.length - 1].dotEnd?.uuid)
        ) {
          //this.redrawNextLine(); is not called. the line is not supposed to be visible as the temp closing line (dotted line) is visible
        } else {
          this.redrawNextLine();
        }
        return;
      }
    });
  }

  closePolygon() {
    if (
      !this.mapInstance ||
      this.mapInstance.fieldShape.dotStart == undefined ||
      this.mapInstance.fieldShape.lineCount() < 2
    )
      return;

    //add first point once more to close polygon
    const fieldShape = this.mapInstance.fieldShape;
    fieldShape.lines.push({
      // add this line
      id: crypto.randomUUID(),
      dotSplit: undefined,
      dotEnd: fieldShape.dotStart,
      line: drawLine(this.mapInstance.map, [
        fieldShape.lines[fieldShape.lines.length - 1].dotEnd!.getPosition()!,
        fieldShape.dotStart!.getPosition()!,
      ]),
    });

    this.mapInstance.dispatchCloseField();

    //drawClosingLine(this.mapInstance);
    //this.updateFieldFillPoly(this.isEditing);

    //removeTempClosingLine(this);
    ////TODO: History
    //    if (!bNoHistoryStep) {
    //      undoRedo(this, { type: UndoRedoActionType.AddStep });
    //    }

    //this.bIsTooltipFieldShapeCloseVisible = false; // as there is no mouseout event called when closing the fieldShape (dot is replaced), the icon has to be hidden here
  }

  //////////////////////////
  // Drawing
  /////////////////////////

  /////////////////////
  // Temp Closing Line
  /////////////////////

  createTempDraggingDataForSplitDot(marker: AdvancedMarker) {
    if (!this.mapInstance) return;
    // search for which lines are affected by this split-dot that is being dragged
    // checks for outter border first, then the inner borders. If corresponding marker is not found, nothing happens
    this.createTempDraggingDataForMatchingMarker(
      this.mapInstance.fieldShape.lines,
      marker
    );
    for (
      let exclusionIndex = 0;
      exclusionIndex < this.mapInstance.fieldShape.exclusions.length;
      exclusionIndex++
    ) {
      let exclusionLines =
        this.mapInstance.fieldShape.exclusions[exclusionIndex].lines;
      this.createTempDraggingDataForMatchingMarker(exclusionLines, marker);
    }
  }

  createTempDraggingDataForMatchingMarker(lines: FieldLine[], marker) {
    lines.forEach((fieldLine, index) => {
      if (
        fieldLine.dotSplit != undefined &&
        fieldLine.dotSplit.uuid == marker.uuid
      ) {
        this.curDragging = true;
        this.curDraggedLine = index > 0 ? lines[index - 1] : lines[index - 1]; //todo: @Kai why same condition in else ??
        this.curDraggedLineNext = fieldLine; // this one is set to the temp-color in redrawNextLine and removed on dragend in this case
        this.tempSplitDotDraggingData = {
          splitDot: marker,
          currentLine: drawLine(this.mapInstance!.map, [
            new google.maps.LatLng(
              fieldLine.line?.getPath().getArray()[0].lat() as number,
              fieldLine.line?.getPath().getArray()[0].lng() as number
            ),
            marker.getPosition()!,
          ]),
          nextLine: drawLine(this.mapInstance!.map, [
            marker.getPosition()!,
            new google.maps.LatLng(
              fieldLine.line?.getPath().getArray()[1].lat() as number,
              fieldLine.line?.getPath().getArray()[1].lng() as number
            ),
          ]),
        };
        this.redrawCurLine();
        this.redrawNextLine();
      }
    });
  }

  drawTempClosingLine() {
    const fieldShape = this.mapInstance?.fieldShape;
    if (!fieldShape || !this.mapInstance) return;

    const lineSymbol = {
      path: 'M 0,-1 0,1',
      strokeOpacity: 1,
      scale: 3,
    };

    const lastLine = fieldShape.getLastFieldShapeLine();
    if (
      fieldShape.dotStart != undefined &&
      fieldShape.lineCount() >= 2 &&
      lastLine?.dotEnd != undefined
    ) {
      this.tempClosingLine = drawLine(
        this.mapInstance.map,
        [
          fieldShape.getFirstLineStartingPos()!,
          lastLine.dotEnd!.getPosition()!,
        ],
        drawLineTempClosingColor,
        {
          strokeOpacity: 0,
          icons: {
            icon: lineSymbol,
            offset: '0',
            repeat: '20px',
          },
        }
      );
    } else {
      this.tempClosingLine = undefined;
    }
  }

  removeTempClosingLine(): void {
    this.tempClosingLine?.setMap(null);
    this.tempClosingLine = undefined;
  }

  updatePossibleNewLine(curMouseLatLng: google.maps.LatLng) {
    if (!this.mapInstance) return;
    let startPosLine: google.maps.LatLng | undefined;
    if (
      this.mapInstance.fieldShape.dotStart != undefined &&
      this.mapInstance.fieldShape.lineCount() === 0
    ) {
      startPosLine = this.mapInstance.fieldShape.dotStart.getPosition()!;
    } else if (this.mapInstance.fieldShape.getLastFieldShapeLine()?.dotEnd) {
      startPosLine = this.mapInstance.fieldShape
        .getLastFieldShapeLine()
        .dotEnd!.getPosition()!;
    }
    if (startPosLine) {
      this.possibleNewLine?.setPath([startPosLine, curMouseLatLng!]);
      this.possibleNewLine?.setMap(this.mapInstance.map);
    }
    /* return drawLine(
        mapInstance, [
        startPosLine!,
        curMouseLatLng!
    ], drawLinePossibleColor 
    )*/
  }

  drawPossibleNewLine(curMouseLatLng: google.maps.LatLng) {
    const mapInstance: MapComponent = this.mapInstance!;
    let startPosLine: google.maps.LatLng | undefined;
    if (
      mapInstance.fieldShape.dotStart != undefined &&
      mapInstance.fieldShape.lineCount() === 0
    ) {
      startPosLine = mapInstance.fieldShape.dotStart.getPosition()!;
    } else if (mapInstance.fieldShape.getLastFieldShapeLine()?.dotEnd) {
      startPosLine = mapInstance.fieldShape
        .getLastFieldShapeLine()
        .dotEnd!.getPosition()!;
    }
    if (!startPosLine) {
      // fallback in case there is no valid startPos yet (would happen once when starting to draw a field)
      startPosLine = curMouseLatLng;
    }
    return drawLine(
      mapInstance.map,
      [startPosLine!, curMouseLatLng!],
      drawLinePossibleColor
    );
  }

  redrawPossibleNewLine(pos: google.maps.LatLng) {
    if (!this.mapInstance) return;

    if (
      isClosedFieldShape(this.mapInstance.fieldShape) ||
      this.curDragging ||
      !pos
    ) {
      this.removePossibleNewLine();
      return;
    }
    if (!this.possibleNewLine) {
      this.possibleNewLine = this.drawPossibleNewLine(pos);
    } else {
      this.updatePossibleNewLine(pos);
    }
  }

  removePossibleNewLine() {
    this.possibleNewLine?.setMap(null);
    this.possibleNewLine = undefined;
  }

  redrawTempClosingLineIfApplicable() {
    this.removeTempClosingLine();
    this.drawTempClosingLine();
  }

  redrawCurLine(bDrawSplitDot = false, overrColour = true) {
    if (!this.mapInstance) return;
    const mapInstance = this.mapInstance;
    if (this.tempSplitDotDraggingData != undefined) {
      movePolyLine(
        this.tempSplitDotDraggingData.currentLine,
        this.tempSplitDotDraggingData.currentLine
          .getPath()
          .getArray()[0] as google.maps.LatLng,
        this.tempSplitDotDraggingData.splitDot.getPosition()!
      );
    } else if (this.curDraggedLine != undefined) {
      // remove dotSplit and line of the line this marker belongs to
      let startPos: google.maps.LatLng | undefined | null = undefined;
      const endPos =
        this.curDraggingStartDot &&
        isClosedFieldShape(this.mapInstance.fieldShape)
          ? mapInstance.fieldShape.lines[
              mapInstance.fieldShape.lines.length - 2
            ].dotEnd?.getPosition()
          : this.curDraggedLine.dotEnd!.getPosition();
      // while dragging the startDot or dragging the first line while still drawing, the startPos = the position of the dotStart
      if (
        this.curDraggingStartDot ||
        (mapInstance.isFieldEditing &&
          !mapInstance.isDrawingFinished &&
          this.curDraggedLine.id == mapInstance.fieldShape.lines[0].id)
      ) {
        startPos = mapInstance.fieldShape.dotStart!.getPosition();
      } else if (
        !mapInstance.isFieldEditing &&
        this.curDraggedLine.id ==
          mapInstance.fieldShape.getLastFieldShapeLine().id
      ) {
        startPos = getSecondToLastFieldShapeLine(
          mapInstance.fieldShape
        ).dotEnd!.getPosition();
      } else {
        startPos = getStartPosOfLine(this.curDraggedLine.line!);
      }
      redrawLine(
        mapInstance,
        this.curDraggedLine,
        startPos as google.maps.LatLng,
        endPos!,
        bDrawSplitDot,
        overrColour
      );
    }
  }

  redrawNextLine(bDrawSplitDot = false, overrColour = true) {
    if (!this.mapInstance) return;
    const mapInstance = this.mapInstance;
    if (this.tempSplitDotDraggingData != undefined) {
      if (this.curDraggedLineNext != undefined) {
        changeLineColor(this.curDraggedLineNext, '#777');
      }
      movePolyLine(
        this.tempSplitDotDraggingData.nextLine,
        this.tempSplitDotDraggingData.splitDot.getPosition()!,
        this.tempSplitDotDraggingData.nextLine
          .getPath()
          .getArray()[1] as google.maps.LatLng
      );
    } else if (
      // if it exists: remove dotSplit and line of the line after the line this marker belongs to
      this.curDraggedLineNext != undefined &&
      this.curDraggedLineNext.dotEnd != undefined &&
      this.curDraggedLineNext.line != undefined
    ) {
      let startPos: google.maps.LatLng | undefined | null = undefined;
      const endPos = this.curDraggingStartDot
        ? mapInstance.fieldShape.lines[0].dotEnd!.getPosition()
        : this.curDraggedLineNext.dotEnd!.getPosition();
      if (this.curDraggingStartDot) {
        startPos = mapInstance.fieldShape.dotStart!.getPosition();
      } else {
        startPos = this.curDraggedLine?.dotEnd?.getPosition();
      }
      redrawLine(
        mapInstance,
        this.curDraggedLineNext,
        startPos!,
        endPos!,
        bDrawSplitDot,
        overrColour
      );
    }
  }
}
