import { Field } from '../fields/field.model';
import { getPositionBetweenTwoPositions } from './map-drawing-logic';
import { MapActions } from './map.actions';

class MapHelper {
  static fitBounds(map: google.maps.Map, field: Field) {
    // get bounds
    let bounds = new google.maps.LatLngBounds();
    for (let polygon of field.polygon.coordinates) {
      for (let coordinate of polygon) {
        bounds.extend(new google.maps.LatLng(coordinate[1], coordinate[0]));
      }
    }
    map.fitBounds(bounds);
  }

  static fitBoundsRadius(
    map: google.maps.Map,
    center: google.maps.LatLng,
    radius: number
  ) {
    let circle = new google.maps.Circle({ center, radius });
    let bounds = circle.getBounds();
    if (bounds) {
      map.fitBounds(bounds);
    }
  }

  static getMapCenter(fields: Field[], browserLocation) {
    const amazoneHeadquarterLocation = {
      lat: 52.2540982512599,
      lng: 7.939411175361887,
    }; //using this representation to avoid issues google not (yet) defined

    if (browserLocation != undefined && fields.length == 0) {
      return browserLocation;
    } else if (fields.length > 0) {
      let allFieldsPoly = this.getFieldsPolygon(fields);
      return getPositionBetweenTwoPositions(
        new google.maps.LatLng(allFieldsPoly.north, allFieldsPoly.west),
        new google.maps.LatLng(allFieldsPoly.south, allFieldsPoly.east)
      );
    } else {
      return amazoneHeadquarterLocation;
    }
  }

  static getZoomLevel(fields: Field[], browserLocation) {
    let defaultZoom = 17;

    if (fields.length > 0) {
      let allFieldsPoly = this.getFieldsPolygon(fields);

      let mapWidth = document.getElementById('map')!.offsetWidth;
      let mapHeight = document.getElementById('map')!.offsetHeight;
      const WORLD_DIM = { height: 256, width: 256 };
      const ZOOM_MAX = 21;

      function latRad(lat) {
        const sin = Math.sin((lat * Math.PI) / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
      }

      function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
      }

      const latFraction =
        (latRad(allFieldsPoly.south) - latRad(allFieldsPoly.north)) / Math.PI;
      const lngDiff = allFieldsPoly.west - allFieldsPoly.east;
      const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

      const latZoom = zoom(mapHeight, WORLD_DIM.height, latFraction);
      const lngZoom = zoom(mapWidth, WORLD_DIM.width, lngFraction);

      return Math.min(latZoom, lngZoom, ZOOM_MAX);
    }
    return defaultZoom;
  }

  //TODO: CHECK if north, south, east, and west set correctly!
  static getFieldsPolygon(fields: Field[]) {
    let latNorth = 0;
    let latSouth = 0;
    let lngEast = 0;
    let lngWest = 0;
    fields.forEach((field) => {
      let coordinates = getCoordinatesArrFromField(field);
      coordinates.forEach((pos) => {
        if (pos.lat() < latNorth || latNorth == 0) {
          latNorth = pos.lat();
        } else if (pos.lat() > latSouth) {
          latSouth = pos.lat();
        }
        if (pos.lng() < lngEast || lngEast == 0) {
          lngEast = pos.lng();
        } else if (pos.lng() > lngWest) {
          lngWest = pos.lng();
        }
      });
    });
    return { north: latNorth, south: latSouth, east: lngEast, west: lngWest };
  }

  static getBrowserLocation(store) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        store.dispatch(
          MapActions.setBrowserLocation({
            browserLocation: new google.maps.LatLng(latitude, longitude),
          })
        );
      });
    }
  }
}

function getCenterOfCoordinates(coordinates: google.maps.LatLng[]) {
  const PI = 22 / 7;
  let x = 0;
  let y = 0;
  let z = 0;
  coordinates.forEach((vertex, index) => {
    if (index < coordinates.length - 1) {
      let lat1 = vertex.lat();
      let lng1 = vertex.lng();
      lat1 = (lat1 * PI) / 180;
      lng1 = (lng1 * PI) / 180;
      x += Math.cos(lat1) * Math.cos(lng1);
      y += Math.cos(lat1) * Math.sin(lng1);
      z += Math.sin(lat1);
    }
  });
  let lngTotal = Math.atan2(y, x);
  const Hyp = Math.sqrt(x * x + y * y);
  let latTotal = Math.atan2(z, Hyp);
  latTotal = (latTotal * 180) / PI;
  lngTotal = (lngTotal * 180) / PI;
  return new google.maps.LatLng(latTotal + 0.000005, lngTotal + 0.00018); // offsets to show it a little higher and further right
}

function getCoordinatesArrFromField(field: Field) {
  return convertNumberArrayToLatLng(field.polygon.coordinates[0]);
}

function convertNumberArrayToLatLng(path: number[][]) {
  const coordinates: google.maps.LatLng[] = [];
  for (const coord of path) {
    coordinates.push(new google.maps.LatLng(coord[1], coord[0]));
  }
  return coordinates;
}

export { MapHelper, convertNumberArrayToLatLng, getCenterOfCoordinates };
