import { Profile } from "../services/profile";


export interface AuthState {
   isLoading: boolean,
   isAuthenticated: boolean,

   profile: Profile | undefined,
   parsedAccessToken: undefined | Object,
}


export const initialAuthState:AuthState = {
 isLoading: true,
 isAuthenticated: false,
 profile: undefined,
 parsedAccessToken: undefined,
}
