import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'AmaPlanFrontend';
  @HostBinding('style.cursor') private cursor = 'default';

  constructor(translate: TranslateService, private authService: AuthService) {
    translate.addLangs(['en', 'de']);
    translate.setDefaultLang('de'); // this language will be used as a fallback when a translation isn't found in the current language
    translate.use('de'); // the lang to use, if the lang isn't available, it will use the current loader to get them
  }

  ngOnInit() {
    this.authService.init();
  }

  // listen to empty unload/beforeunload events to prevent use of BFCache
  @HostListener('window:unload', ['$event'])
  unloadHandler() {}
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler() {}

  //prevent auto download outside of dropzone
  //dropzone defined in .field-import/dropzone.directive.ts
  @HostListener('dragover', ['$event']) public onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  @HostListener('drop', ['$event']) public ondrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
  }
}
