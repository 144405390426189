import {Injectable} from "@angular/core";
import { MapComponent } from "./map.component";

@Injectable({providedIn: 'root'})
export class MapReferenceService {
  private map?: google.maps.Map
  private mapInstance?: MapComponent

  get isMapAvailable() {
    return this.map != null;
  }
  setMap(map: google.maps.Map) {
    this.map = map;
  }
  setInstance(mapInstance: MapComponent) {
    this.mapInstance = mapInstance;
  }

  executeOnMap(action: (map: google.maps.Map)=>void) {
    if(this.map != null) {
      action(this.map);
    }

  }

  executeOnInstance(action: (mapInstance: MapComponent)=>void) {
    if (this.mapInstance != null) {
      action(this.mapInstance);
    }
  }

}
