import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavComponent } from './nav/nav.component';
import { FormsModule } from '@angular/forms';

import { FieldListComponent } from './field-list/field-list.component';
import { FieldDetailsComponent } from './field-details/field-details.component';
import { MapComponent } from './map/map.component';
import { MapTypeComponent } from './map-type/map-type.component';
import { ProcessProgressComponent } from './process-progress/process-progress.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { AuthService } from './auth/auth.service';
import { UserComponent } from './nav/user/user.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as authEffects from './auth/auth.effects';
import * as fieldsEffects from './fields/fields.effects';
import * as importEffects from './field-import/import.effects';
import * as exportEffects from './scenario-export/export.effects';
import * as mapEffects from './map/map.effects';
import * as processEffects from './process-progress/process-progress.effects';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { authReducer } from './auth/auth.reducer';
import { fieldsReducer } from './fields/fields.reducer';
import { FieldFilterPipe } from './field-list/field-filter.pipe';
import { historyMapReducer } from './map/map.reducer';
import { MapViewComponent } from './map-view/map-view.component';
import { SearchComponent } from './search/search.component';
import { SpreadingTypeSelectorComponent } from './spreading-type-selector/spreading-type-selector.component';
import { SpreadingTypeSelectorItemComponent } from './spreading-type-selector/spreading-type-selector-item/spreading-type-selector-item.component';
import { SelectionModesComponent } from './selection-modes/selection-modes.component';
import { SelectionModeComponent } from './selection-modes/selection-mode.component';
import { FieldImportComponent } from './field-import/field-import.component';
import { FieldsService } from './fields/fields.service';
import { importReducer } from './field-import/import.reducer';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { FieldSortPipe } from './field-list/field-sort.pipe';
import { FieldFilterComponent } from './field-filter/field-filter.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { UserInitials } from './nav/user/user-initials.pipe';
import { DropzoneDirective } from './field-import/dropzone.directive';
import { ImportIsoxml } from './field-import/importISOXML.service';
import { ImportShapefile } from './field-import/importSHAPEFILE.service';
import { exportReducer } from './scenario-export/export.reducer';
import { AuthConfigModule } from './auth-config.module';
import { AuthInterceptor } from 'angular-auth-oidc-client';
import { ScenarioExportComponent } from './scenario-export/scenario-export.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { processReducer } from './process-progress/process-progress.reducer';
import { MapHistoryComponent } from './map/map-history/map-history.component';
import { MatDialogModule } from '@angular/material/dialog';
import { dialogReducer } from './modal-dialog/modal-dialog.reducer';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { AssignNameDialogComponent } from './modal-dialog/assign-name-dialog/assign-name-dialog.component';

ScenarioExportComponent;
// We need a factory, since localStorage is not available during AOT build time.

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    MapComponent,
    UserComponent,
    UserInitials,
    FieldListComponent,
    FieldDetailsComponent,
    FieldFilterPipe,
    FieldSortPipe,
    MapTypeComponent,
    ProcessProgressComponent,
    MapViewComponent,
    MapHistoryComponent,
    SearchComponent,
    SpreadingTypeSelectorComponent,
    SpreadingTypeSelectorItemComponent,
    SelectionModesComponent,
    SelectionModeComponent,
    FieldImportComponent,
    WelcomePageComponent,
    ErrorPageComponent,
    FieldFilterComponent,
    DropzoneDirective,
    ScenarioExportComponent,
    ModalDialogComponent,
    AssignNameDialogComponent,
  ],
  providers: [
    //httpInterceptorProviders,
    AuthService,
    FieldsService,
    ImportIsoxml,
    ImportShapefile,
    // set accessToken via interceptor for secured routes defined in auth-config
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    GoogleMapsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatInputModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    StoreModule.forRoot(
      {
        auth: authReducer,
        fields: fieldsReducer,
        mapdata: historyMapReducer,
        imports: importReducer,
        exports: exportReducer,
        process: processReducer,
        dialog: dialogReducer,
      },
      {}
    ),
    EffectsModule.forRoot([
      authEffects,
      fieldsEffects,
      importEffects,
      exportEffects,
      mapEffects,
      processEffects,
    ]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    //TODO: Check if/when to remove especially for PROD
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: false,
      autoPause: true,
      features: {
        pause: false,
        lock: true,
        persist: true,
      },
    }),
    MatSliderModule,
    MatSelectModule,
    AuthConfigModule,
  ],
})
export class AppModule {}
