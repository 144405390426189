import { DIALOGTYPE } from './modal-dialog.component';

export interface ModalDialogState {
  modalType: DIALOGTYPE;
  modalProps: object;
}

export const initialModalDialogState: ModalDialogState = {
  modalType: DIALOGTYPE.DEFAULT,
  modalProps: {},
};
