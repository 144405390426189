import { Field } from 'src/app/fields/field.model';
import {
  drawFieldPolygonDefaultColor,
  drawFieldPolygonDefaultFillColor,
  drawFieldPolygonLowVisibilityColor,
  drawFieldPolygonSelectedColor,
} from '../map-drawing-colors';
import { AdvancedPolygon, FieldLine } from '../map-drawing-logic';
import { MAPMODE } from '../map-state-control';
import { TempFieldData } from './temp-field-data';
import { getCenterOfCoordinates } from '../map-helper';

/////////////////////
// Polygons
/////////////////////

export function removeFillPoly(fieldShape?: TempFieldData) {
  fieldShape?.fillPoly?.setMap(null);
}

export function removeAllFieldPolygons(polygons: AdvancedPolygon[]) {
  for (const fieldPoly of polygons) {
    fieldPoly?.mapOverlays?.forEach((overlay) => {
      overlay.setMap(null);
    });
    fieldPoly.setMap(null);
  }
}

export function drawFillPoly(
  fieldShape: TempFieldData,
  map: google.maps.Map,
  fillWithPrimaryColor = false
): void {
  // get coordinates from fieldShape.lines
  const paths: google.maps.LatLng[][] = []; // arr of google.maps.LatLng[] unlike for the polyline where only 1 arr exists
  const path: google.maps.LatLng[] = [];
  const firstLineStartingPos = fieldShape.getFirstLineStartingPos();

  //found no starting position or not enough lines to draw polygon
  if (!firstLineStartingPos || fieldShape.lines.length < 3) return;

  path.push(firstLineStartingPos);
  for (const line of fieldShape.lines) {
    if (line.dotEnd) {
      path.push(line.dotEnd.getPosition()!);
    }
  }
  path.push(firstLineStartingPos);
  if (path.length > 0) {
    // create poly from the line-data
    paths.push(path);

    //check if we have exclusion areas and add them if required as well
    for (const exlusion of fieldShape.exclusions) {
      const exclPath: google.maps.LatLng[] = [];
      for (const coord of exlusion.lines) {
        if (coord.dotEnd) {
          exclPath.push(coord.dotEnd.getPosition()!);
        }
      }
      if (exclPath.length > 0) {
        paths.push(exclPath);
      }
    }

    fieldShape.fillPoly = new google.maps.Polygon({
      paths,
      clickable: false,
      draggable: false,
      geodesic: true,
      strokeOpacity: 0,
      strokeWeight: 0,
      fillColor: fillWithPrimaryColor
        ? drawFieldPolygonSelectedColor
        : drawFieldPolygonDefaultFillColor,
      fillOpacity: 0.6,
      map: map,
    });
  }
}

/**
 * Create Field Polygon
 *
 * @param mapInstance
 * @param field
 * @param lowVisibilityBGDrawing
 * @returns
 */
export function drawFieldPolygon(
  mapMode: MAPMODE,
  map: google.maps.Map,
  field: Field,
  isSel = false,
  lowVisibilityBGDrawing = false
): AdvancedPolygon {
  // lowVisibilityBGDrawing will draw the lines as pure BG elements
  //  const isSel = field.isSelected;// (mapInstance.selectedFields.find(el => el.id == field.id) != undefined);
  const coordinates = field.getPaths4Polygon();
  const fieldCenter = getCenterOfCoordinates(coordinates[0]);

  let strokeColor = isSel
    ? drawFieldPolygonSelectedColor
    : drawFieldPolygonDefaultColor;
  let strokeOpacity = 0.75;
  let fillOpacity = isSel ? 0.65 : 0.3;
  if (mapMode === MAPMODE.FIELDCREATION || mapMode === MAPMODE.FIELDEDITING) {
    strokeOpacity = 0.3;
  } else if (mapMode === MAPMODE.SCENARIOCREATION) {
    strokeColor = drawFieldPolygonDefaultColor;
    strokeOpacity = isSel ? 0.75 : 0;
    fillOpacity = 0;
  }

  // styles for drawing over this polygon later
  if (lowVisibilityBGDrawing === true) {
    strokeColor = drawFieldPolygonLowVisibilityColor;
    strokeOpacity = 0.75;
    fillOpacity = 0;
  }

  // if this poly is drawn in the background or the user is currently creating a scenario and this is NOT the field for which this should happen, the poly should not be clickable
  const polyIsClickable =
    !lowVisibilityBGDrawing &&
    (mapMode !== MAPMODE.SCENARIOCREATION ||
      (mapMode === MAPMODE.SCENARIOCREATION && isSel));

  const singleFieldPoly: AdvancedPolygon = new google.maps.Polygon({
    paths: coordinates,
    clickable: polyIsClickable,
    draggable: false,
    geodesic: true,
    strokeWeight: 2,
    strokeColor: strokeColor,
    strokeOpacity: strokeOpacity,
    fillColor: drawFieldPolygonDefaultFillColor,
    fillOpacity: fillOpacity,
    map: map,
  });
  singleFieldPoly.id = field.id;
  singleFieldPoly.center = fieldCenter;
  return singleFieldPoly;
}

export function removeAllLineElements(fLine: FieldLine) {
  if (fLine.dotSplit) {
    fLine.dotSplit.setMap(null);
    fLine.dotSplit = undefined;
  }
  if (fLine.dotEnd) {
    fLine.dotEnd.setMap(null);
    fLine.dotEnd = undefined;
  }
  if (fLine.line) {
    fLine.line.setMap(null);
    fLine.line = undefined;
  }
}
