import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { MapLoaderService, OverlayFnObj } from 'src/utilities/map.loader';
import { isImportDialogOpenSelector } from '../field-import/import.selector';
import { ImportState } from '../field-import/import.states';
import { Field, Scenario } from '../fields/field.model';
import { FieldActions } from '../fields/fields.actions';
import {
  selectCurSelectedFields,
  selectFieldListOpen,
  selectFields,
} from '../fields/fields.selector';
import { FieldsService } from '../fields/fields.service';
import { FieldState } from '../fields/fields.states';
import { MapActions } from '../map/map.actions';
import { isExportDialogOpenSelector } from '../scenario-export/export.selector';
import { ExportState } from '../scenario-export/export.state';
import { SearchItem } from '../search/search-item.model';
import {
  SelectionModeHandler,
  SelectionModeNoneHandler,
} from '../selection-modes/selection-mode';
import {
  SelectedSpreadingType,
  SpreadingType,
} from '../spreading-type-selector/spreading-type';
import {
  AdvancedMarker,
  AdvancedPolygon,
  drawFieldAndFillFieldShape as drawField,
  isClosedFieldShape,
  resetFieldShape as removeFieldShape,
  resetMapOptions,
} from './map-drawing-logic';
import { MapHelper } from './map-helper';
import { MapReferenceService } from './map-reference.service';
import {
  DOTTYPE,
  DRAWINGSTATE,
  MAPMODE,
  MAPTYPE,
  getNewFieldObj,
  setMapTypeIdForMode,
} from './map-state-control';
import {
  selectCurrentSpreadingType,
  selectMapCenter,
  selectMapType,
  selectSelectionMode,
  selectZoomLevel,
} from './map.selector';
import { MapState } from './map.states';

import { Helper } from '../Helper';
import { generateNewFieldName } from './map-objects-management';
import {
  drawScenarioPolygon,
  removeAllScenarioDrawingElements,
} from './scenario/map-drawing-logic-scenario';
import { TempScenarioData } from './scenario/temp-scenario-data';
import {
  drawFieldPolygon,
  drawFillPoly,
  removeAllFieldPolygons,
  removeFillPoly,
} from './field/map-drawing-logic-field';
import { TempFieldData } from './field/temp-field-data';
import { MarkerEventHandler } from './map-events';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  //changeDetection: ChangeDetectionStrategy.Default
})
export class MapComponent
  implements MarkerEventHandler, AfterViewInit, OnDestroy
{
  curPopup: any | undefined; // " "

  searchInput = '';

  // mapDiv: HTMLElement | undefined = undefined;
  @ViewChild('mapWrapper', { static: false })
  mapElement!: ElementRef;

  map!: google.maps.Map; //add ! to get rid of compiler errors. Variable is getting initialized in AfterViewInit
  mapTypeSub: Subscription;
  mapCenterSub: Subscription;
  mapZoomSub: Subscription;

  overlayFnObj: OverlayFnObj | undefined; // consists of all functions to create overlays for the google map

  isDeleteFieldOrScenarioModalDisplayed = false;
  isDeleteModeField = true;

  // fields and polygons:
  fieldListStatusSub: Subscription;
  isFieldListOpen = false;
  fieldsSub: Subscription;
  // fieldsSelectedSub: Subscription;

  allFields: Field[] = [];
  allFieldsInitialized = false;
  selectedField: Field | undefined = undefined;

  allFieldPolys: AdvancedPolygon[] = [];
  activelyClickedLatLngInPoly: google.maps.LatLng | undefined | null =
    undefined; // temporarily holds the information, at what coordinates the polygon was clicked
  //curMouseLatLng: google.maps.LatLng | undefined | null; // can't use LatLng as type as google is not defined yet

  currentScenario: Scenario | undefined = undefined;

  // elements necessary for the drawing process itself:
  drawingManager: any; // drawing manager instance of the google map itself

  fieldShape: TempFieldData = new TempFieldData();
  tooltipFieldShapeClose: undefined | HTMLElement;
  bIsTooltipFieldShapeCloseVisible = false; //todo: change cursor not via variable

  // Current Selection handler
  selectionHandler: SelectionModeHandler = SelectionModeNoneHandler;

  scenarioData: TempScenarioData = new TempScenarioData(this);

  // observables, subscriptions and local versions of enums for checks in the logic/template
  forceReload: boolean = false; // simplifies unnecessary complex checks if it's known that a reload has to happen
  public fields$: Observable<Field[]>;
  fieldsCurSelectedSub: Subscription;
  mapData$: Observable<MapState>;

  isImportModalOpen$: Observable<boolean>;
  isExportModalOpen$: Observable<boolean>;

  stateSub: Subscription;
  //curStates: statesHandler;

  // local mapping to access the types in the html for better readability of the checks
  lMAPMODES = MAPMODE;
  // lFIELDCREATIONSTATES = FIELDCREATIONSTATE;
  // lSCENARIOCREATIONSTATES = SCENARIOCREATIONSTATE;
  lDRAWINGSTATES = DRAWINGSTATE;

  currentSpreadingType: SelectedSpreadingType = new SelectedSpreadingType(
    SpreadingType.NormalSpreading,
    SpreadingType.NormalSpreading
  ); //TODO: move default to central constant definition

  currentSpreadingTypeSub: Subscription;

  translateServiceRef: TranslateService;

  //  history: DrawingHistory = {
  //    past: [],
  //    present: emptyHistoryStep,
  //    future: [],
  //  };

  //event-Handling parameter
  fieldModificationOngoing = false;
  isFieldEditing = false;
  isScenarioEditing = false;
  isEditing = false;
  isDrawingFinished = false;
  mapMode = MAPMODE.DEFAULT;

  constructor(
    public translate: TranslateService,
    public store: Store<{
      fields: FieldState;
      mapdata: MapState;
      imports: ImportState;
      exports: ExportState;
    }>,
    public ngZone: NgZone,
    private mapReferenceService: MapReferenceService,
    private fieldsService: FieldsService
  ) {
    this.mapData$ = this.store.select('mapdata');
    this.isImportModalOpen$ = this.store
      .select('imports')
      .pipe(select(isImportDialogOpenSelector));
    this.isExportModalOpen$ = this.store
      .select('exports')
      .pipe(select(isExportDialogOpenSelector));

    this.currentSpreadingTypeSub = store
      .select('mapdata')
      .pipe(select(selectCurrentSpreadingType))
      .subscribe((spreadingType) => {
        this.currentSpreadingType = spreadingType;
      });

    this.isDeleteModeField = false;

    this.translateServiceRef = translate;

    this.stateSub = this.store.select('mapdata').subscribe((newData) => {
      let requireRedraw = false;

      if (this.mapMode != newData.mapMode) requireRedraw = true;
      this.mapMode = newData.mapMode;

      this.isFieldEditing =
        newData.mapMode === MAPMODE.FIELDCREATION ||
        newData.mapMode === MAPMODE.FIELDEDITING;
      this.isScenarioEditing = newData.mapMode === MAPMODE.SCENARIOCREATION;

      this.fieldModificationOngoing =
        (newData.mapMode === MAPMODE.FIELDCREATION || //either create or edit field, but not finished yet
          newData.mapMode === MAPMODE.FIELDEDITING) &&
        newData.drawingState !== DRAWINGSTATE.FINALIZED;

      this.isEditing = this.isFieldEditing || this.isScenarioEditing;
      const oldIsDrawingFinished = this.isDrawingFinished;

      this.isDrawingFinished = newData.drawingState === DRAWINGSTATE.FINISHED;
      if (this.isDrawingFinished != oldIsDrawingFinished) requireRedraw = true;

      if (!this.selectedField?.equals(newData.field)) requireRedraw = true; //todo: this is even true if data did not actually change

      this.selectedField = newData.field;
      if (
        newData.field?.name != undefined &&
        newData.mapMode === MAPMODE.FIELDEDITING
      ) {
        // this.assignedName = newData.field.name;
      }
      if (!this.currentScenario && newData.scenario) requireRedraw = true;
      else if (
        typeof this.currentScenario?.equals != 'function' ||
        (this.currentScenario && !this.currentScenario.equals(newData.scenario))
      )
        requireRedraw = true;

      this.currentScenario = newData.scenario;

      //set focus if we should assign a name:
      if (newData.drawingState === DRAWINGSTATE.FINALIZED) {
        setTimeout(() => {
          const nameInputRef = document.getElementById('nameInput');
          if (nameInputRef) {
            nameInputRef.focus();
          }
        }, 50);
      }

      //Trigger redraw
      if (requireRedraw) this.redraw();
    });

    //Change mapTypes
    this.mapTypeSub = this.store
      .select('mapdata')
      .pipe(select(selectMapType))
      .subscribe((newMapType: MAPTYPE) => {
        setMapTypeIdForMode(this.map, newMapType);
      });

    //Change SelectionMode
    this.mapTypeSub = this.store
      .select('mapdata')
      .pipe(select(selectSelectionMode))
      .subscribe((newSelectionHandler: SelectionModeHandler) => {
        this.selectionHandler.onExitSelectionMode();
        this.selectionHandler = newSelectionHandler.initializeMap(this);
        this.selectionHandler.onStartSelectionMode();
      });

    //update Center of Map
    this.mapCenterSub = this.store
      .select('mapdata')
      .pipe(select(selectMapCenter))
      .subscribe((newCenter: object) => {
        this.map?.setCenter(newCenter as google.maps.LatLng);
      });

    //update Zoom level
    this.mapZoomSub = this.store
      .select('mapdata')
      .pipe(select(selectZoomLevel))
      .subscribe((newZoomLevel: number) => {
        this.map?.setZoom(newZoomLevel);
      });

    this.fields$ = this.store.select('fields').pipe(select(selectFields));

    this.fieldListStatusSub = this.store
      .select('fields')
      .pipe(select(selectFieldListOpen))
      .subscribe((nVal: boolean) => {
        this.isFieldListOpen = nVal;
      });

    // when the fields (generally) change:
    this.fieldsSub = this.fields$.subscribe((newFields) => {
      let requireRedraw = newFields.length != this.allFields.length;

      this.allFields = newFields;
      console.log('allFields filled', this.allFields);
      console.log('replaced field list with', newFields);

      this.forceReload = false;

      if (requireRedraw) this.redraw();
    });

    this.fieldsCurSelectedSub = this.store
      .select('fields')
      .pipe(select(selectCurSelectedFields))
      .subscribe((newSelectedFields: Field[]) => {
        let requireRedraw;

        newSelectedFields.forEach((selectedField) => {
          if (this.selectedField && selectedField.id == this.selectedField.id) {
            requireRedraw =
              selectedField.scenarios?.length !=
              this.selectedField.scenarios?.length;
          }
        });
        if (newSelectedFields.length == 0) {
          this.selectedField = undefined;
          requireRedraw = true;
        }
        if (requireRedraw) this.redraw();
      });
  }

  redrawLock = false;
  async redraw() {
    while (this.redrawLock) {
      await new Promise((resolve) => setTimeout(resolve, 10));
    }
    this.redrawLock = true;
    try {
      this.redrawSynchronized();
    } catch (e) {
      console.log(e);
    } finally {
      this.redrawLock = false;
    }
  }

  redrawSynchronized() {
    console.log('redrawSynchronized');

    this.selectionHandler.onBeforeRedraw();

    //general behaviour, remove old Data (based on cache variable), draw new and buildup new cache variable

    //FIELDS (all)
    //remove all drawn fields and create new ones
    removeFillPoly(this.fieldShape);
    removeAllFieldPolygons(this.allFieldPolys);
    this.drawFieldPolygons(this.allFields); //will draw field polygons if necessary

    this.removeCurPopup();

    //ONE FIELD
    this.fieldShape = removeFieldShape(this.fieldShape);
    if (this.isFieldEditing && this.selectedField != undefined)
      this.preallocateDrawingDataFromField(this.selectedField); //only draw field if we are editing it

    //SCENARIO
    removeAllScenarioDrawingElements(this.scenarioData);
    if (this.isScenarioEditing && this.currentScenario != undefined)
      drawScenarioPolygon(this, this.currentScenario);
    // iterate through field shape and add lines/dots
    //Trigger redraw
    // removeFillPoly(this);
    //else this.updateFieldFillPoly(this.isEditing);

    //Focus if need to be
    if (this.selectedField && !this.isEditing) {
      this.focusOnGivenField(this.selectedField, true);
    }

    this.selectionHandler.onAfterRedraw();
  }

  async ngAfterViewInit(): Promise<void> {
    await MapLoaderService.load();
    // get a reference to the createPopupOverlay-functions via an object. this happens asynchronously so that google.map is initialized before (as the classes extend gmap classes)
    this.overlayFnObj = await MapLoaderService.getOverlayFnObj();
    await this.initMapFunctionality();

    //TODO: check if required this.store.dispatch(MapActions.changeDrawingStateTo({ newDrawingState: DRAWINGSTATE.UNFINISHED }));

    const ttSpanFieldShapeClose = document.getElementById(
      'tooltip-fieldshape-close'
    );
    if (ttSpanFieldShapeClose !== null) {
      this.tooltipFieldShapeClose = ttSpanFieldShapeClose;
    }
  }

  ngOnDestroy(): void {
    this.stateSub.unsubscribe();
    this.mapTypeSub.unsubscribe();
    this.mapCenterSub.unsubscribe();
    this.mapZoomSub.unsubscribe();
    this.fieldsSub.unsubscribe();
    this.fieldListStatusSub.unsubscribe();
    this.currentSpreadingTypeSub.unsubscribe();
  }

  drawFieldPolygons(fieldList: Field[]): void {
    this.allFieldPolys = [];

    if (this.isEditing) return; //don't draw fields if editing

    for (const field of fieldList) {
      const newPoly = drawFieldPolygon(
        this.mapMode,
        this.map,
        field,
        field.id === this.selectedField?.id
      );
      if (newPoly !== undefined) {
        newPoly.addListener('click', (event: google.maps.MapMouseEvent) => {
          //should not be required
          //        if (this.isEditing) {
          //          return;
          //        }
          this.activelyClickedLatLngInPoly = event.latLng;
          // this.selectedField = undefined; Should not deselect here already... but wait for processing of event
          this.store.dispatch(
            FieldActions.selectField({ field: field!, select: true })
          );

          // moving the active overlays to the clicked position:
          // move info-overlay to newly clicked position
          if (newPoly.mapOverlays?.[0] && event.latLng) {
            newPoly.mapOverlays[0].move(event.latLng);
          }
          // move curPopup (if there is one)
          if (this.curPopup && event.latLng) {
            this.curPopup.move(event.latLng);
          }

          setTimeout(() => {
            this.activelyClickedLatLngInPoly = undefined;
          }, 50);
        });

        // add name and scenario-info for the field as an overlay:
        //new google.maps.LatLng(points[0].lat() + ratio * (points[1].lat() - points[0].lat()), points[0].lng() + ratio * (points[1].lng() - points[0].lng()));
        newPoly.mapOverlays = [];

        if (field.id == this.selectedField?.id) {
          if (
            typeof this.overlayFnObj?.createFieldInfoOverlayFn === 'function'
          ) {
            let latLngToSetItTo = newPoly.center;
            if (this.activelyClickedLatLngInPoly) {
              // calculate a new offset because of the offset of the arrow
              latLngToSetItTo = new google.maps.LatLng(
                this.activelyClickedLatLngInPoly.lat(),
                this.activelyClickedLatLngInPoly.lng()
              );
            }
            newPoly.mapOverlays.push(
              this.overlayFnObj!.createFieldInfoOverlayFn(
                this.map,
                latLngToSetItTo,
                field
              )
            );
          } else {
            setTimeout(() => {
              if (newPoly.mapOverlays) {
                let latLngToSetItTo = newPoly.center;
                if (this.activelyClickedLatLngInPoly) {
                  // calculate a new offset because of the offset of the arrow
                  latLngToSetItTo = new google.maps.LatLng(
                    this.activelyClickedLatLngInPoly.lat(),
                    this.activelyClickedLatLngInPoly.lng()
                  );
                }
                newPoly.mapOverlays.push(
                  this.overlayFnObj!.createFieldInfoOverlayFn(
                    this.map,
                    latLngToSetItTo,
                    field
                  )
                );
              }
            }, 200);
          }
        } else {
          if (
            typeof this.overlayFnObj!.createFieldInfoOverlayFn === 'function'
          ) {
            newPoly.mapOverlays.push(
              this.overlayFnObj!.createFieldInfoOverlayFn(
                this.map,
                newPoly.center,
                field
              )
            );
          } else {
            setTimeout(() => {
              if (newPoly.mapOverlays) {
                newPoly.mapOverlays.push(
                  this.overlayFnObj!.createFieldInfoOverlayFn(
                    this.map,
                    newPoly.center,
                    field
                  )
                );
              }
            }, 200);
          }
        }

        this.allFieldPolys.push(newPoly);
      }
    }
  }

  toggleMenu() {
    this.store.dispatch(FieldActions.toggleFieldListMenu());
  }

  changeScenarioSpreadingType(newSpreadingType: SelectedSpreadingType) {
    this.currentSpreadingType = newSpreadingType;
    console.log('new (scenario) spreading type is', newSpreadingType);
  }

  // creates the events to edit and delete fields and to add scenarios for fields to the popup-overlay
  createPopupClickEvents(field: Field) {
    // add events to the popup (as there is no way to do this per template/angular-functionality right here)
    const elPopupX = document.getElementById('popup-x');
    if (elPopupX) {
      elPopupX.addEventListener('click', (event) => {
        event.stopPropagation();
        this.removeCurPopup();
      });
    }
    const elPopupEdit = document.getElementById('popup-edit');
    if (elPopupEdit) {
      elPopupEdit.addEventListener('click', (event) => {
        console.log('edit clicked for', field.id);
        event.stopPropagation();
        this.store.dispatch(MapActions.startEditField({ field: field }));
      });
    }
    const elPopupDelete = document.getElementById('popup-delete');
    if (elPopupDelete) {
      elPopupDelete.addEventListener('click', (event) => {
        event.stopPropagation();
        this.isDeleteModeField = true;
        this.isDeleteFieldOrScenarioModalDisplayed = true;
      });
    }
    const elPopupCreateScenario = document.getElementById(
      'popup-create-scenario'
    );
    if (elPopupCreateScenario) {
      elPopupCreateScenario.addEventListener('click', (event) => {
        event.stopPropagation();

        this.store.dispatch(MapActions.startCreateScenario({ field: field }));
        console.log('create scenario clicked for', field.id);
      });
      console.log('eventlistener create scenario created');
    }
  }

  async focusOnGivenField(field: Field, createPopup: boolean) {
    for (const poly of this.allFieldPolys) {
      if (poly.id == field.id) {
        this.selectedField = field;
        console.log('setfocus center', poly.center);

        MapHelper.fitBounds(this.map, field);

        if (createPopup) {
          let latLngToSetItTo = new google.maps.LatLng(
            poly.center!.lat(),
            poly.center!.lng()
          );
          if (this.activelyClickedLatLngInPoly) {
            // calculate a new offset because of the offset of the arrow
            latLngToSetItTo = new google.maps.LatLng(
              this.activelyClickedLatLngInPoly.lat(),
              this.activelyClickedLatLngInPoly.lng()
            );
          }
          this.curPopup = this.overlayFnObj!.createPopupOverlayFn(
            this.map,
            latLngToSetItTo,
            {
              translation1: this.translate.instant('map.popup.edit'),
              translation2: this.translate.instant('map.popup.createScenario'),
              translation3: this.translate.instant('map.popup.delete'),
            }
          );
          await Helper.waitForCondition(
            () => document.getElementById('popup-x') != null
          );
          this.createPopupClickEvents(field);
        }
      }
    }
  }

  removeCurPopup() {
    if (this.curPopup) {
      this.curPopup.setMap(null);
    }
    this.curPopup = undefined;
  }

  preallocateDrawingDataFromField(field: Field) {
    drawField(this.map, this, this.fieldShape, field);
    if (this.fieldShape.lines.length > 2) {
      // this.updateFieldFillPoly(isClosedFieldShape(this.fieldShape));
      this.updateFieldFillPoly(); //coloring closed field not wanted as of right now
    }
  }

  clickShowAllFields() {
    this.store.dispatch(MapActions.showFields({ fields: this.allFields }));
  }

  dispatchScenarioUpdate() {
    if (!this.currentScenario) return;
    const scenario = this.scenarioData.getAsScenarioObj(
      this.currentScenario.id,
      this.currentScenario.name
    );

    this.store.dispatch(
      MapActions.scenarioEdited({
        scenario: scenario,
      })
    );
  }

  dispatchCloseField() {
    const field = this.fieldShape.getAsFieldObj(
      this.selectedField?.name
        ? this.selectedField?.name
        : generateNewFieldName(),
      this.selectedField?.id,
      this.selectedField?.type
    );
    this.store.dispatch(MapActions.fieldPolygonClosed({ field }));
  }

  dispatchFieldUpdate() {
    //TODO: add reopened scenario here
    ////check if we need to fire this
    //mapInstance.store.dispatch(MapActions.fieldPolygonReopened({ field }));

    const field = this.fieldShape.getAsFieldObj(
      this.selectedField?.name
        ? this.selectedField?.name
        : generateNewFieldName(),
      this.selectedField?.id,
      this.selectedField?.type
    );
    this.store.dispatch(
      MapActions.fieldEdited({
        field: field,
      })
    );
  }

  deleteField() {
    if (this.selectedField) {
      this.store.dispatch(
        FieldActions.deleteField({ field: this.selectedField })
      );
      this.isDeleteFieldOrScenarioModalDisplayed = false;
    }
  }
  deleteScenario() {
    if (this.selectedField === undefined) {
      console.error(
        'error when deleting scenario',
        this.currentScenario?.id,
        ': field not found'
      );
      return;
    }

    if (this.selectedField?.scenarios === undefined) {
      console.error(
        'error when deleting scenario',
        this.currentScenario?.id,
        ': scenario not found for selected field',
        this.selectedField.id
      );
      return;
    }
    console.log(
      'deleting scenario with id...',
      this.currentScenario?.id,
      'of this field:',
      this.selectedField
    );

    // const scenarioObj = this.selectedField.scenarios.find(scenario => scenario.id === this.selectedScenarioID);
    if (this.currentScenario !== undefined) {
      console.log(
        'scenario found in list. deleting scenario...',
        this.currentScenario,
        'of field',
        this.selectedField
      );
      this.store.dispatch(
        FieldActions.deleteScenario({
          field: this.selectedField,
          scenario: this.currentScenario,
        })
      );
      this.isDeleteFieldOrScenarioModalDisplayed = false;
    }
  }
  cancelDeleteFieldOrScenario() {
    this.isDeleteFieldOrScenarioModalDisplayed = false;
  }

  async initMapFunctionality() {
    this.map = new google.maps.Map(this.mapElement.nativeElement, {
      center: { lat: 52.2540982512599, lng: 7.939411175361887 }, //TODO: move to config-constants
      zoom: 17,
      //maxZoom: 15,
      mapTypeId: 'hybrid',
      disableDefaultUI: true,
      //disableDoubleClickZoom: true,
      //draggableCursor: null
    });
    MapHelper.getBrowserLocation(this.store);

    resetMapOptions(this);

    this.mapReferenceService.setMap(this.map);
    this.mapReferenceService.setInstance(this);

    if (this.mapElement) {
      console.log('init map addEventListener mouseover for', this.mapElement);
      //this.mapElement.nativeElement.addEventListener(
      google.maps.event.addListener(
        this.map,
        'mousemove',
        this.onMouseMove
        // false
      );

      //known issue (https://stackoverflow.com/questions/75409120/google-maps-losses-mousemove-event-listener-after-simultaneous-dragstart-and-dra)
      //trigger mouse move after simultanious triggering dragstart and dragend
      var dragStartCenter: google.maps.LatLng | undefined = undefined;
      if (this.map) {
        const myMap = this.map;
        google.maps.event.addListener(myMap, 'dragstart', function () {
          dragStartCenter = myMap.getCenter();
        });

        google.maps.event.addListener(myMap, 'dragend', function () {
          if (dragStartCenter && dragStartCenter.equals(myMap.getCenter()!)) {
            // execute panBy() if the map has not been moved during dragging.
            myMap.panBy(0, 0);
          }
        });
      }

      google.maps.event.addListener(this.map, 'mousedown', this.onMouseDown);
      //this.mapElement.nativeElement.addEventListener('mouseup', this.onMouseUp);
      google.maps.event.addListener(this.map, 'mouseup', this.onMouseUp);
      this.mapElement.nativeElement.addEventListener(
        'mouseleave',
        this.onMouseLeave
      );
    }
  }

  /////////////////////////////////////////////////////////////////////////
  // Event handling
  /////////////////////////////////////////////////////////////////////////

  onMouseMove = ((event: any /*google.maps.MapMouseEvent */) => {
    const evt = event.domEvent;
    if (this.selectionHandler) this.selectionHandler.onMouseMove(event);

    // for the fieldshape-close icon visible when hovering the start-dot
    if (this.tooltipFieldShapeClose !== undefined) {
      const x = evt.clientX,
        y = evt.clientY;
      this.tooltipFieldShapeClose.style.top = y - 80 + 'px'; // TODO: change to top bar height-var (+20) if possible
      this.tooltipFieldShapeClose.style.left = x + 10 + 'px';
    }
  }).bind(this); //late binding is required to ensure correct references!

  onMouseDown = ((evt: google.maps.MapMouseEvent) => {
    this.selectionHandler.onMouseDown(evt);
  }).bind(this);
  onMouseUp = (evt: google.maps.MapMouseEvent) => {
    this.selectionHandler.onMouseUp(evt);
  };
  onMouseLeave = (evt: MouseEvent) => {
    this.selectionHandler.onMouseLeave(evt);
  };

  //Marker Eventhandler
  onMarkerClick(marker: AdvancedMarker) {
    this.selectionHandler.onMarkerClick(marker);
  }
  onMarkerDragStart(marker: AdvancedMarker) {
    this.selectionHandler.onMarkerDragStart(marker);
  }
  onMarkerDragEnd(marker: AdvancedMarker) {
    this.selectionHandler.onMarkerDragEnd(marker);
  }
  onMarkerDrag(marker: AdvancedMarker) {
    this.selectionHandler.onMarkerDrag(marker);
  }

  splitLine(marker: AdvancedMarker) {
    //TODO: check event handling
    this.fieldShape.splitLineAtMarker(marker);
    this.dispatchFieldUpdate();
  }

  removeMarkerFromShape(marker: AdvancedMarker) {
    //TODO: check event handling
    this.fieldShape.removeMarker(marker);
    this.dispatchFieldUpdate();
  }

  onMarkerMouseOver = (marker: AdvancedMarker) => {
    // change icon (url) to remove-dot
    if (
      marker.DOTTYPE == DOTTYPE.End ||
      (marker.DOTTYPE == DOTTYPE.Start && isClosedFieldShape(this.fieldShape))
    ) {
      marker.setOptions({
        icon: {
          url: 'assets/images/map/dotRemove.svg',
          size: new google.maps.Size(17, 17),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(9, 9),
          scaledSize: new google.maps.Size(17, 17),
        },
      });
    }
    this.selectionHandler.onMarkerMouseOver(marker);
  };
  onMarkerMouseOut = (marker: AdvancedMarker) => {
    // change icon (url) back to the standard end-dot image
    marker.setOptions({
      icon: {
        url: 'assets/images/map/dot-inactive.svg',
        size: new google.maps.Size(17, 17),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(9, 9),
        scaledSize: new google.maps.Size(17, 17),
      },
    });
    this.selectionHandler.onMarkerMouseOut(marker);
  };

  mouseOverStartDot = () => {
    if (
      this.fieldShape.lines.length >= 2 &&
      this.tooltipFieldShapeClose !== undefined
    ) {
      this.bIsTooltipFieldShapeCloseVisible = true;
    }
  };
  mouseOutStartDot = () => {
    this.bIsTooltipFieldShapeCloseVisible = false;
  };

  updateFieldFillPoly(fillWithPrimaryColor = false) {
    removeFillPoly(this.fieldShape);
    console.log(
      'updateFieldFillPoly with color: ',
      fillWithPrimaryColor,
      this.isEditing
    );
    if (!this.isDrawingFinished || this.fieldShape.lineCount() < 3) return;

    drawFillPoly(this.fieldShape, this.map, fillWithPrimaryColor);
  }

  selectSearchItem(searchItem: SearchItem, forceLocation: boolean) {
    if (searchItem.fieldId != null && !forceLocation) {
      //unsubscribe necessary?
      this.fieldsService.getField(searchItem.fieldId).subscribe((field) => {
        this.store.dispatch(
          FieldActions.selectField({ field: field, select: true })
        );
      });
    } else {
      this.store.dispatch(
        MapActions.changeCenterTo({ newCenter: searchItem.location })
      ); //will set zoom-Level as well
    }
  }
}
