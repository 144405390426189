import {
  createActionGroup,
  emptyProps,
  /*emptyProps,*/ props,
} from '@ngrx/store';
import { MAPTYPE } from './map-state-control';
import { SelectedSpreadingType } from '../spreading-type-selector/spreading-type';
import { Field, Scenario } from '../fields/field.model';
import { SelectionModeHandler } from '../selection-modes/selection-mode';

export const MapActions = createActionGroup({
  source: 'Map',
  events: {
    // 'reset map history': emptyProps(),
    // 'create history step': emptyProps(),
    'reset drawing': emptyProps(),
    'change type to': props<{ newMapType: MAPTYPE }>(), //sattelite, hybrid ...

    'change selected spreading type': props<{
      newSpreadingType: SelectedSpreadingType;
    }>(),

    'change selection mode': props<{
      newSelectionHandler: SelectionModeHandler;
    }>(),

    // 'change states': props<{ newStates: stateObject }>(),
    // 'Xchange mode to': props<{ newMapMode: MAPMODE }>(),
    'enable default map mode': emptyProps(),
    'show fields': props<{ fields: Field[] }>(),
    'change center to': props<{ newCenter: object }>(),
    // 'change fieldcreation state to': props<{ newFieldCreationState: FIELDCREATIONSTATE }>(),
    // 'change scenariocreation state to': props<{ newScenarioCreationState: SCENARIOCREATIONSTATE }>(),
    // 'change drawing state to': props<{ newDrawingState: DRAWINGSTATE }>(),

    //Field Actions
    'start create field': emptyProps(),
    'start edit field': props<{ field: Field }>(),
    'field edited': props<{ field: Field }>(),
    'field polygon closed': props<{ field: Field }>(),
    'field polygon reopened': props<{ field: Field }>(),

    'start create scenario': props<{ field: Field }>(),
    'start edit scenario': props<{ field: Field; scenario: Scenario }>(),
    'scenario edited': props<{ scenario: Scenario }>(),
    'finish scenario edit': emptyProps(),
    'request delete scenario': props<{ field: Field; scenario: Scenario }>(),

    'enable drawing': emptyProps(),
    'finish field edit': emptyProps(),

    'save current scenario': props<{ assignedName: string }>(),
    'save current field': props<{ assignedName: string }>(),

    'set browser location': props<{ browserLocation: object }>(),
    undo: emptyProps(),
    redo: emptyProps,
  },
});
