import { computeDistanceBetween } from 'spherical-geometry-js';
import {
  Scenario,
  ScenarioBorder,
  ScenarioBorderRing,
  ScenarioBorderSegment,
} from 'src/app/fields/field.model';
import {
  DefaultSpreadingType,
  SelectedSpreadingType,
  SpreadingType,
} from 'src/app/spreading-type-selector/spreading-type';
import { splitScenarioDefaultColor } from '../map-drawing-colors';
import {
  AdvancedMarker,
  AdvancedPolygon,
  drawLine,
  drawMarker,
  getEndPosOfLine,
  getStartPosOfLine,
} from '../map-drawing-logic';
import {
  ScenarioLine,
  getSpreadingLineGroups,
} from './map-drawing-logic-scenario';
import { DOTTYPE } from '../map-state-control';
import { MapComponent } from '../map.component';
import { getLatLngArrFromLine } from './map-drawing-logic-scenario';

const autoSelectedArea: number = 5;
const autoSelectGroupArea: number = 5;

export class TempScenarioData {
  scenarioId: string | undefined;
  // isMouseDown: boolean; //TODO: remove
  //eventListener: google.maps.MapsEventListener[];

  // curSpreadingType: SelectedSpreadingType,
  bgPoly: AdvancedPolygon | undefined;
  bgMarker: AdvancedMarker[];
  lines: ScenarioLine[][]; // in this case, there is no polygon but a set of lines that is changed when selecting/drawing in the scenario-data
  //insideControlLines: Map<string, FieldLine>,
  marker: AdvancedMarker[];
  //draggingLine: google.maps.Polyline | undefined;
  // snapToRoute: SnapToRoute | undefined;
  //popup: CustomScenarioPopupOverlay | undefined;
  //popupLine: FieldLine | undefined;

  //TODO: Check if we can get rid of this
  private mapInstance: MapComponent;

  constructor(mapInstance: MapComponent) {
    this.scenarioId = undefined;
    this.bgMarker = [];
    this.lines = [];
    this.marker = [];

    //TODO: Check if we can get rid of this
    this.mapInstance = mapInstance;
  }

  /////////////////////////////////////
  // Get Information
  /////////////////////////////////////
  getLineRefForPosition(
    position: google.maps.LatLng
  ): ScenarioLine | undefined {
    if (position === undefined) return undefined;
    // create new splitDot at position
    for (const lines of this.lines)
      for (const curScenarioLine of lines) {
        const polyLineForCheck = new google.maps.Polyline({
          clickable: false,
          path: curScenarioLine.line?.getPath(),
        });

        if (
          curScenarioLine.line !== undefined &&
          google.maps.geometry.poly.isLocationOnEdge(
            new google.maps.LatLng(position.lat(), position.lng()),
            polyLineForCheck,
            0.0000001
          )
        ) {
          return curScenarioLine;
        }
      }
    return undefined;
  }

  getAsScenarioObj(id?: string, scenarioName?: string): Scenario {
    const scenario = new Scenario();
    if (id) {
      scenario.id = id;
    }
    scenario.border = new ScenarioBorder();
    scenario.border.outer = new ScenarioBorderRing();
    scenario.border.outer.segments = [];
    // create segments for all fieldLines in the scenarioData.lines

    for (const fieldLine of this.lines[0]) {
      const fLineStart =
        fieldLine.line !== undefined
          ? getStartPosOfLine(fieldLine.line)
          : undefined;
      const fLineEnd =
        fieldLine.line !== undefined
          ? getEndPosOfLine(fieldLine.line)
          : undefined;
      if (fLineStart !== undefined && fLineEnd !== undefined) {
        const sbs = new ScenarioBorderSegment();
        sbs.coordinates = [
          [fLineStart.lng(), fLineStart.lat()],
          [fLineEnd.lng(), fLineEnd.lat()],
        ];
        // get the outfacing spreading type (name)
        if (fieldLine?.lineData !== undefined) {
          //TODO: check if lineData can really be undefined
          sbs.setSpreadingType(fieldLine?.lineData?.spreadingType);
        } else {
          sbs.setSpreadingType(DefaultSpreadingType);
        }
        scenario.border.outer.segments.push(sbs);
      }
    }

    scenario.border.inners = [];
    for (let i = 1; i < this.lines.length; i++) {
      const exclusionRing = new ScenarioBorderRing();
      for (const fieldLine of this.lines[i]) {
        const fLineStart =
          fieldLine.line !== undefined
            ? getStartPosOfLine(fieldLine.line)
            : undefined;
        const fLineEnd =
          fieldLine.line !== undefined
            ? getEndPosOfLine(fieldLine.line)
            : undefined;
        if (fLineStart !== undefined && fLineEnd !== undefined) {
          const sbs = new ScenarioBorderSegment();
          sbs.coordinates = [
            [fLineStart.lng(), fLineStart.lat()],
            [fLineEnd.lng(), fLineEnd.lat()],
          ];
          // get the outfacing spreading type (name)
          if (fieldLine?.lineData !== undefined) {
            //TODO: check if lineData can really be undefined
            sbs.setSpreadingType(fieldLine?.lineData?.spreadingType);
          } else {
            sbs.setSpreadingType(DefaultSpreadingType);
          }

          exclusionRing.segments.push(sbs);
        }
      }
      scenario.border.inners.push(exclusionRing);
    }
    return scenario;
  }

  getScenarioPathForGroup(group: number): google.maps.LatLng[] {
    const path: google.maps.LatLng[] = [];
    if (group >= this.lines.length) return path;

    for (const fLine of this.lines[group]) {
      //TODO: fix exclusions
      const lineStart =
        fLine.line !== undefined ? getStartPosOfLine(fLine.line) : undefined;
      if (lineStart !== undefined) {
        path.push(lineStart);
      }
    }
    if (
      path.length > 2 &&
      this.mapInstance.scenarioData.lines[group][0].line !== undefined
    ) {
      //TODO: fix exclusions
      path.push(
        getStartPosOfLine(this.mapInstance.scenarioData.lines[group][0].line!)
      ); //TODO: fix exclusions
    }
    return path;
  }

  // lets the previous line take up the space the previous- and the current line take up until now
  // the prevLine is elongated to start at its old start but end at the end of curLine
  // old dottype of dotEnd of the curLine is now the type for the dotEnd of prevLine as it takes its place
  // curLine and its other visual items (line, dotEnd, spreadingTypeOverlay) are then taken from the map
  // only the items given as parameters are changed
  expandPreviousLineToCurrentLinesPath(
    curLine: ScenarioLine,
    prevLine: ScenarioLine
  ) {
    prevLine.dotEnd?.setPosition(curLine.dotEnd!.getPosition()!);
    prevLine.dotEnd!.DOTTYPE = curLine.dotEnd!.DOTTYPE;

    prevLine.line?.setPath([
      prevLine.line!.getPath().getArray()[0],
      curLine.dotEnd!.getPosition()!,
    ]);
    // remove current line/dotEnd/inside line/overlay(s) from map
    curLine.line?.setMap(null);
    curLine.dotEnd?.setMap(null);
    curLine.line?.insideControlLine?.setMap(null);
    curLine.lineData?.spreadingTypeOverlay?.setMap(null);
  }

  /////////////////////////////////////////////
  // Change Model
  /////////////////////////////////////////////

  /**
   * Will set spreading type for all segments on provided path
   * Assumption is, that path-point equal marker/split points
   *
   * @param path
   * @param type
   * @param removeSplittingPointsOnPath if true additional split-points will be removed from model
   */
  setSpreadingTypeBetweenMarker(
    path: google.maps.LatLng[],
    newSpreadingType: SelectedSpreadingType,
    removeSplittingPointsOnPath = false
  ) {
    if (path.length < 2) {
      console.warn('could not set spreading type for one point only');
      return;
    }
    let pathIdx = 1; //skip first point as we match against dotEnd
    for (let groupIdx = 0; groupIdx < this.lines.length; groupIdx++) {
      // TODO: vllt eher eine while-schleife weil die schleife wenn sie ab punkt 5 was findet, bei zb 8 vorbei ist und nicht bei 0 weitermacht:
      // wenn removeSplittingPointsOnPath = true >> direkt removen in der inneren schleife wenn das geht
      let prevLine: ScenarioLine | undefined = undefined;
      let bWasReset = false; // iteration is reset exactly one time to check for changes if the path goes over the end of the lines-length ("start-dot")
      let idx = 0; // index in group (just one group should be used per call of this function) >> whole shape. path = (whole) selected path incl. new split-dots
      let runThroughCounter = 0; // counter how often after increment index was 0
      let pathEndCheck = path.length;

      while (pathIdx < pathEndCheck && runThroughCounter < 2) {
        const curLine = this.lines[groupIdx][idx];
        if (!curLine.dotEnd) continue; //should not happen, but for code readability

        if (
          curLine.line &&
          computeDistanceBetween(getEndPosOfLine(curLine.line), path[pathIdx]) <
            0.02
        ) {
          if (curLine.lineData) {
            curLine.lineData.spreadingType = newSpreadingType;

            // removing obsolete splitDots (if applicable):
            if (removeSplittingPointsOnPath && path.length > 2 && pathIdx > 1) {
              // only in this case there could be dots to be removed at all
              prevLine =
                this.lines[groupIdx][
                  getPrevIndex(idx, this.lines[groupIdx].length)
                ];
              if (prevLine != undefined) {
                // DOTTYPE of the previous has to be ScenarioSplit, as this one is "movable" at all times.
                // lines with "End" as the type are going to a cornerpoint of the original fieldShape
                // the type of the current one does not matter, but has to be set as the new type of the previous one (done in expandPreviousLineToCurrentLinesPath)
                if (prevLine.dotEnd!.DOTTYPE == DOTTYPE.ScenarioSplit) {
                  this.expandPreviousLineToCurrentLinesPath(curLine, prevLine);
                  this.lines[groupIdx].splice(idx, 1); // removing curLine as prevLine took its place
                  idx = getPrevIndex(idx, this.lines[groupIdx].length);
                }
              }
            }

            // reset / "restart" loop to find remaining split-dots "on the way"
            if (!bWasReset && idx === this.lines[groupIdx].length - 1) {
              bWasReset = true;
              idx = 0;
              pathIdx++;
              continue; // don't iterate idx & don't change pathIdx further
            }
          }
          pathIdx++;
        }

        idx = getNextIndex(idx, this.lines[groupIdx].length);
        if (idx == 0) runThroughCounter++;
      }
    }
  }

  splitScenarioLine(
    splitPosition: google.maps.LatLng
  ): AdvancedMarker | undefined {
    let newSplitDotMarkerRef: AdvancedMarker | undefined = undefined;

    const relevantLine = this.getLineRefForPosition(splitPosition);
    if (!relevantLine) {
      console.warn(
        'No relevant line found to split Scenario at ',
        splitPosition
      );
      return;
    }

    let groupIdx = -1;
    let idxToReplace = -1;
    if (relevantLine.id !== undefined) {
      for (let j = 0; j < this.lines.length; j++) {
        for (let i = 0; i < this.lines[j].length; i++) {
          if (this.lines[j][i].id === relevantLine.id) {
            groupIdx = j;
            idxToReplace = i;
          }
        }
      }
    }

    if (
      groupIdx != -1 &&
      idxToReplace != -1 &&
      this.lines[groupIdx][idxToReplace].line !== undefined
    ) {
      const prevLine =
        this.lines[groupIdx][
          idxToReplace > 0 ? idxToReplace - 1 : this.lines[groupIdx].length - 1
        ];
      const lineToReplace = this.lines[groupIdx][idxToReplace];

      const map = lineToReplace.line?.getMap();
      if (!map) {
        console.warn('SplitLine called without a map set on ', lineToReplace);
        return;
      }

      prevLine.dotEnd?.setMap(null);
      const refOldDotEnd = lineToReplace.dotEnd; // old movable marker after this line
      const newDotTypeLine2 = refOldDotEnd?.DOTTYPE;

      // remove the old spreading type icon as there have to be 2 new ones now
      if (lineToReplace.lineData?.spreadingTypeOverlay) {
        console.log('removing icon of old line');

        lineToReplace.lineData.spreadingTypeOverlay.setMap(null);
        lineToReplace.lineData.spreadingTypeOverlay = undefined;
      }

      // get old in- and outfacing values for the spreadingType to add those to the new lines later and get the colors
      let colorNewLines = splitScenarioDefaultColor;
      if (lineToReplace?.lineData?.spreadingType !== undefined) {
        colorNewLines = lineToReplace?.lineData?.spreadingType?.color;
      }

      // split line, create new (draggable) marker at pos
      const latLngArr = getLatLngArrFromLine(
        this.lines[groupIdx][idxToReplace].line as google.maps.Polyline
      );
      const startFirst = latLngArr[0];
      const endSecond = latLngArr[1];
      const newLine1: ScenarioLine = {
        id: crypto.randomUUID(),
        dotEnd: refOldDotEnd,
        line: drawLine(
          map,
          [startFirst!, splitPosition!],
          colorNewLines, // use correct colors per spreading type the old line had
          {
            clickable: false,
            strokeWeight: 6,
          }
        ),
      };
      if (refOldDotEnd) {
        refOldDotEnd.setMap(null);
        refOldDotEnd.DOTTYPE = DOTTYPE.ScenarioSplit;
      }
      const newLine2: ScenarioLine = {
        id: crypto.randomUUID(),
        dotEnd: drawMarker(
          map,
          newDotTypeLine2 ? newDotTypeLine2 : DOTTYPE.ScenarioSplit,
          endSecond
        ),
        line: drawLine(
          map,
          [splitPosition!, endSecond!],
          colorNewLines, // use correct colors per spreading type the old line had
          {
            clickable: false,
            strokeWeight: 6,
          }
        ),
      };

      // set new lineData for both new lines with the same data the old line had
      // separate objects as to not get reference issues
      // draw new inside control line if necessary
      if (lineToReplace.lineData) {
        //TODO: check if lineData is always set
        newLine1.lineData = {
          spreadingType: lineToReplace.lineData.spreadingType,
        };
        newLine2.lineData = {
          spreadingType: lineToReplace.lineData.spreadingType,
        };
      }

      //remove old Line
      lineToReplace!.line!.setMap(null);
      lineToReplace.line?.insideControlLine?.setMap(null); //remove insideControl Line if existend

      const newMarker = drawMarker(
        this.mapInstance.map,
        DOTTYPE.ScenarioSplit,
        splitPosition,
        this.mapInstance,
        'dotScenarioSplit.svg'
      );

      newLine1.dotEnd = newMarker;

      newSplitDotMarkerRef = newMarker;

      this.lines[groupIdx] = [
        ...this.lines[groupIdx].slice(0, idxToReplace),
        newLine1,
        newLine2,
        ...this.lines[groupIdx].slice(idxToReplace + 1),
      ];
    }

    return newSplitDotMarkerRef;
  }

  moveToGroupEnd(
    highlighterPos: google.maps.LatLng,
    forward = true,
    start = true
  ): google.maps.LatLng[] {
    let newScenarioLinesEnd: google.maps.LatLng[] = [];
    const relevantLine = this.getLineRefForPosition(highlighterPos)!;

    let borderIdx = -1;
    if (relevantLine.id !== undefined) {
      for (let j = 0; j < this.lines.length; j++) {
        if (this.lines[j].includes(relevantLine)) borderIdx = j;
      }
    }

    const stGroups = getSpreadingLineGroups(this.lines[borderIdx]);
    for (let groupIdx = 0; groupIdx < stGroups.length; groupIdx++) {
      let group = stGroups[groupIdx];
      const lineInGroupIdx = group.indexOf(relevantLine); //simply -1 if not in group
      let nextGroup =
        groupIdx == stGroups.length - 1 ? stGroups[0] : stGroups[groupIdx + 1];
      let prevGroup =
        groupIdx == 0 ? stGroups[stGroups.length - 1] : stGroups[groupIdx - 1];

      let groupSpreadingMode =
        start == forward
          ? prevGroup[0].lineData?.spreadingType.outfacing! !=
            SpreadingType.NormalSpreading
          : nextGroup[0].lineData?.spreadingType.outfacing! !=
            SpreadingType.NormalSpreading;

      let distanceToGroupEnd =
        start == forward
          ? computeDistanceBetween(
              highlighterPos,
              getStartPosOfLine(group[0].line!)
            )
          : computeDistanceBetween(
              highlighterPos,
              group[group.length - 1].dotEnd?.getPosition()!
            );
      if (distanceToGroupEnd > autoSelectGroupArea) continue;

      if (lineInGroupIdx != -1 && groupSpreadingMode) {
        if (start && forward) {
          for (let idx = 0; idx <= lineInGroupIdx; idx++) {
            newScenarioLinesEnd.push(getStartPosOfLine(group[idx].line!));
          }
        } else if (!start && !forward) {
          for (let idx = 0; idx <= lineInGroupIdx; idx++) {
            newScenarioLinesEnd.push(getStartPosOfLine(group[idx].line!));
          }
          //reverse for matching direction
          newScenarioLinesEnd = newScenarioLinesEnd.reverse();
        } else if (!start && forward) {
          for (let idx = lineInGroupIdx; idx < group.length; idx++) {
            newScenarioLinesEnd.push(group[idx].dotEnd?.getPosition()!);
          }
        } else if (start && !forward) {
          for (let idx = lineInGroupIdx; idx < group.length; idx++) {
            newScenarioLinesEnd.push(group[idx].dotEnd?.getPosition()!);
          }
          //reverse for matching direction
          newScenarioLinesEnd = newScenarioLinesEnd.reverse();
        }
      }
    }
    return newScenarioLinesEnd.length == 0
      ? [highlighterPos]
      : newScenarioLinesEnd;
  }

  moveToCornerPoint(
    highlighterPos: google.maps.LatLng,
    comparePrev
  ): google.maps.LatLng {
    const relevantLine = this.getLineRefForPosition(highlighterPos)!;

    let groupIdx = -1;
    let idx = -1;
    if (relevantLine.id !== undefined) {
      for (let j = 0; j < this.lines.length; j++) {
        for (let i = 0; i < this.lines[j].length; i++) {
          if (this.lines[j][i].id === relevantLine.id) {
            groupIdx = j;
            idx = i;
          }
        }
      }
    }

    const prevLine =
      this.lines[groupIdx][getPrevIndex(idx, this.lines[groupIdx].length)];
    const nextLine =
      this.lines[groupIdx][getNextIndex(idx, this.lines[groupIdx].length)];

    const lineSpreadingType = comparePrev
      ? prevLine.lineData?.spreadingType!.outfacing
      : nextLine.lineData?.spreadingType!.outfacing;

    const distanceToLineEnd = computeDistanceBetween(
      highlighterPos,
      comparePrev
        ? prevLine.dotEnd?.getPosition()!
        : relevantLine.dotEnd?.getPosition()!
    );

    if (
      distanceToLineEnd < autoSelectedArea &&
      distanceToLineEnd > 0 &&
      lineSpreadingType != SpreadingType.NormalSpreading
    ) {
      let newPos = comparePrev
        ? prevLine.dotEnd!.getPosition()
        : relevantLine.dotEnd!.getPosition();

      if (!newPos) return highlighterPos;
      return newPos;
    }
    return highlighterPos;
  }

  isDuplicate(newPos: google.maps.LatLng) {
    let isDuplicate = false;
    this.lines.forEach((group) => {
      for (let idx = 0; idx < group.length; idx++) {
        if (getEndPosOfLine(group[idx].line!) == newPos) {
          isDuplicate = true;
          break;
        }
        //in moveToGroupEnd usage of startPoint therefore check here
        if (getStartPosOfLine(group[idx].line!) == newPos) {
          isDuplicate = true;
          break;
        }
      }
    });
    return isDuplicate;
  }
}

function getPrevIndex(idx: number, length: number, isReversed = false): number {
  let newIdx = idx + (isReversed ? 1 : -1);
  if (newIdx < 0) newIdx = length - 1;
  if (newIdx >= length) newIdx = 0;
  return newIdx;
}

function getNextIndex(idx: number, length: number, isReversed = false): number {
  let newIdx = idx + (isReversed ? -1 : 1);
  if (newIdx < 0) newIdx = length - 1;
  if (newIdx >= length) newIdx = 0;
  return newIdx;
}
