enum SearchItemType {
  Place,
  Field,
  Scenario
}
class SearchItem {
  type: SearchItemType;
  value: string;
  location: google.maps.LatLng;
  fieldId?: string;

  constructor(type: SearchItemType, value: string, location: google.maps.LatLng, fieldId?: string) {
    this.type = type;
    this.value = value;
    this.location = location;
    this.fieldId = fieldId;
  }

  toString(): string {
    return this.value;
  }
}
class SearchItemGroup {
  type: string;
  items: SearchItem[];

  constructor(type: string, items: SearchItem[]) {
    this.type = type;
    this.items = items;
  }
}

export {
  SearchItemType,
  SearchItem,
  SearchItemGroup
};
