import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from "rxjs";
import { ImportActions } from "./import.actions";
import { Field } from "../fields/field.model";
import { checkForDuplicates, simplifyFields } from "src/utilities/field-import-helper";


export const requestLoadFile$ = createEffect(() => {
  return inject(Actions).pipe(
    ofType(ImportActions.loadFiles),
    switchMap((action) => { //usage of switchMap necessary to make sure effect is executed again after reset of loaded fields
      return action.importHandler.loadFields(action.files)
      .pipe(
            map((loadedFields: Field[]) => {
              if (action.simplify) {
                loadedFields = simplifyFields(loadedFields)
              }
              const renamedFields: Field[] = checkForDuplicates(loadedFields, action.existingFields);
              return ImportActions.fieldsReceived( {importedFields: renamedFields.map(f => ({field: f, isImported:false, hasError: false }) )} )
            } 
            ),
            catchError(error => of(ImportActions.errorLoadingFields({error: `Error: ${error}`})))
      )})
    )},
    { functional: true, dispatch: true }
);

