import { Field } from '../fields/field.model';

// the oldName - if present - is ignored, so that editing fields allows for the old names to be used every time
export function renameFieldDuplicates(
  name: string,
  fields: Field[],
  oldName?: string
) {
  let names: string[] = [];

  fields.forEach((field) => {
    if (oldName === undefined || oldName != field.name) {
      names.push(field.name);
    }
  });
  let fieldName = name;
  while (names.indexOf(fieldName) != -1) {
    fieldName = replaceOldCounter(fieldName);
  }
  name = fieldName;
  return name;
}

export function generateNewFieldName(): string {
  //todo: code
  return '';
}

function replaceOldCounter(existingName: string): string {
  const counterStart = existingName.indexOf('(') + 1;
  const counterEnd = existingName.indexOf(')');
  const nameCounter =
    Number(existingName.substring(counterStart, counterEnd)) + 1;
  if (counterStart != 0) {
    return (
      existingName.substring(0, counterStart - 2) + ' (' + nameCounter + ')'
    );
  }
  return existingName + ' (' + nameCounter + ')';
}
