import { createReducer, on } from '@ngrx/store';
import { FieldActions } from '../fields/fields.actions';
import { ImportActions } from './import.actions';
import { initialImportState } from './import.states';

export const importReducer = createReducer(
  initialImportState,

  on(ImportActions.openImportDialog, (state) => ({
    ...state,
    isImportDialogOpen: true,
  })),
  on(ImportActions.closeImportDialog, (state) => ({
    ...state,
    isLoaded: false,
    isImportDialogOpen: false,
    importedFields: [],
  })),

  on(ImportActions.selectFileType, (state, { importHandler }) => ({
    ...state,
    importHandler: importHandler,
  })),

  on(ImportActions.resetFile, (state) => ({
    ...state,
    isLoaded: false,
    importedFields: [],
    importFile: undefined,
  })),

  on(FieldActions.fieldCreated, (state, { field }) => ({
    ...state,
    importedFields: state.importedFields.map((f) => ({
      field: f.field,
      isImported: field.name == f.field.name ? true : f.isImported,
      hasError: f.hasError,
    })),
  })),

  on(ImportActions.fieldsReceived, (state, { importedFields }) => ({
    ...state,
    isLoaded: true,
    importedFields: [...state.importedFields, ...importedFields],
  })),

  on(ImportActions.errorLoadingFields, (state) => ({
    ...state,
    isLoaded: false,
    importedFields: [],
  })),

  on(ImportActions.fieldsImported, (state, { importedFields }) => ({
    ...state,
    isLoaded: false,
    importedFields: [...state.importedFields, ...importedFields],
  }))
);
