import { Field } from '../fields/field.model';

// what kind of map is shown
export enum MAPTYPE {
  ROADMAP = 1,
  SATELLITE = 2,
  HYBRID = 3,
  TERRAIN = 4,
}

// different kinds of dots drawn while drawing a field
export enum DOTTYPE {
  Start,
  Split,
  End,
  Remove,
  ScenarioSplit,
}

export function setMapTypeIdForMode(map: google.maps.Map, newMapType: MAPTYPE) {
  if (!map) return;
  let nMapTypeID = google.maps.MapTypeId.ROADMAP;
  switch (newMapType) {
    case MAPTYPE.ROADMAP:
      break;
    case MAPTYPE.SATELLITE:
      nMapTypeID = google.maps.MapTypeId.SATELLITE;
      break;
    case MAPTYPE.HYBRID:
      nMapTypeID = google.maps.MapTypeId.HYBRID;
      break;
    case MAPTYPE.TERRAIN:
      nMapTypeID = google.maps.MapTypeId.TERRAIN;
      break;
    default:
      break;
  }
  map.setOptions({
    mapTypeId: nMapTypeID,
  });
}

export function evaluateChangesInFields(
  oFieldList: Field[],
  nFieldList: Field[]
): boolean {
  if (nFieldList.length !== oFieldList.length) return true;
  // if the length did not change, compare the IDs. changes in values of the fields are irrelevant for the mapcomponent atm
  for (const nField of nFieldList) {
    const fieldInOriginalList = oFieldList.find((el) => el.id == nField.id);
    if (fieldInOriginalList == undefined) {
      // using find to ignore changes in the order
      return true;
    } else if (
      fieldInOriginalList !== undefined &&
      fieldInOriginalList.scenarios?.length !== nField.scenarios?.length
    ) {
      // scenarios might have changed (just checking the count here)
      return true;
    }
  }
  return false;
}

// returns the new field (if there is one)
export function getNewFieldObj(oFieldList: Field[], nFieldList: Field[]) {
  let newFieldObj: Field | undefined = undefined;
  const idsOldFields = oFieldList.map((field) => field.id);
  const idsNewFields = nFieldList.map((field) => field.id);
  if (idsNewFields.length === idsOldFields.length + 1) {
    for (const id of idsNewFields) {
      if (idsOldFields.indexOf(id) === -1) {
        newFieldObj = nFieldList.find((field) => field.id === id);
      }
    }
  }
  return newFieldObj;
}

// return all fields OR added, updated, or remaining fields
export function getNewFieldObjects(oFieldList: Field[], nFieldList: Field[]) {
  const idsOldFields = oFieldList.map((field) => field.id);
  const idsNewFields = nFieldList.map((field) => field.id);

  //fields added
  if (idsNewFields.length > idsOldFields.length) {
    let newFieldObjects: Field[] = [];
    for (const id of idsNewFields) {
      if (idsOldFields.indexOf(id) === -1) {
        let newFieldObj = nFieldList.find((field) => field.id === id);
        newFieldObjects.push(newFieldObj!);
      }
    }
    return newFieldObjects;
  }
  //fields updated
  if (idsNewFields.length == idsOldFields.length) {
    let updatedFieldObjects: Field[] = [];
    nFieldList.forEach((newField) => {
      oFieldList.forEach((oldField) => {
        if (
          newField.id == oldField.id &&
          newField.modifiedAt != oldField.modifiedAt
        )
          updatedFieldObjects.push(newField);
      });
    });
    if (updatedFieldObjects.length != 0) nFieldList = updatedFieldObjects;
  }
  return [];
}

// States controlled via changeStates in the map-component and the used object and for that function:

// current states are saved in an object of this type:
export type statesHandler = {
  mapMode: MAPMODE;
  //  fieldCreationState: FIELDCREATIONSTATE,
  //  scenarioCreationState: SCENARIOCREATIONSTATE | undefined,
  drawingState?: DRAWINGSTATE;
  nameValid?: boolean;
};
// when changing the existing states, an object of this type is used:
export type stateObject = {
  newMapMode?: MAPMODE;
  //   newFieldCreationState?: FIELDCREATIONSTATE | undefined,
  //   newScenarioCreationState?: SCENARIOCREATIONSTATE | undefined,
  newDrawingState?: DRAWINGSTATE;
  newNameValid?: boolean;
};

// is the user in the default overview or creating a field/scenario?
export enum MAPMODE {
  DEFAULT = 'Default', // "Normal map overview"
  FIELDCREATION = 'create Field', // "Drawing (new) field boundaries"
  FIELDEDITING = 'edit Field', // "Editing (existing) field boundaries"
  SCENARIOCREATION = 'create Scenario', // "Creating a (new) scenario"
}

// is the field that is about to be drawn finished or not (yet)
export enum DRAWINGSTATE {
  UNFINISHED = 'Field boundaries are not finished/valid',
  FINISHED = 'Field boundaries are finished and valid',
  FINALIZED = 'Drawing finished',
}
