import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ServicesConfig } from '../services/services.config';
import {
  Field,
  Polygon,
  Scenario,
  ScenarioBorder,
  ScenarioBorderRing,
  ScenarioBorderSegment,
} from './field.model';

@Injectable({
  providedIn: 'root',
})
export class FieldsService {
  constructor(private http: HttpClient) {}

  getFields(): Observable<Field[]> {
    return this.http.get<Field[]>(ServicesConfig.fieldsServiceUrl).pipe(
      map((fields) => {
        return fields.map((f) => this.normalizeField(f));
      })
    );
  }

  normalizeField(f: Field): Field {
    const newField = new Field();
    newField.id = f.id;
    newField.name = f.name;
    newField.area = f.area;
    newField.type = f.type;
    newField.polygon = f.polygon;
    newField.isSelected = f.isSelected;
    newField.customerId = f.customerId;
    newField.scenarios = f.scenarios;

    newField.createdAt = f.createdAt;
    newField.createdBy = f.createdBy;
    newField.modifiedAt = f.modifiedAt;
    newField.modifiedBy = f.modifiedBy;
    newField.deletedAt = f.deletedAt;
    newField.deletedBy = f.deletedBy;

    newField.newField = f.newField;

    if (newField.scenarios !== undefined) {
      newField.scenarios = newField.scenarios.map((s) =>
        this.normalizeScenario(s)
      );
    }
    if (newField.polygon) {
      newField.polygon = new Polygon(
        newField.polygon.coordinates,
        newField.polygon.type
      );
    }
    return newField;
  }

  normalizeScenario(s: Scenario): Scenario {
    const newScenario = new Scenario();
    newScenario.id = s.id;
    newScenario.createdAt = s.createdAt;
    newScenario.createdBy = s.createdBy;
    newScenario.deletedAt = s.deletedAt;
    newScenario.deletedBy = s.deletedBy;
    newScenario.modifiedAt = s.modifiedAt;
    newScenario.modifiedBy = s.modifiedBy;
    newScenario.name = s.name;
    newScenario.state = s.state;
    newScenario.border = this.normalizeScenarioBorder(s.border);

    return newScenario;
  }

  normalizeScenarioBorder(sb: ScenarioBorder): ScenarioBorder {
    const newSB = new ScenarioBorder();
    newSB.inners = sb.inners?.map((ring) =>
      this.normalizeScenarioBorderRing(ring)
    );
    newSB.outer = this.normalizeScenarioBorderRing(sb.outer);
    return newSB;
  }

  normalizeScenarioBorderRing(sbr: ScenarioBorderRing): ScenarioBorderRing {
    const newSBR = new ScenarioBorderRing();
    if (sbr != undefined) {
      newSBR.segments = sbr.segments.map((segment) =>
        this.normalizeScenarioBorderSegment(segment)
      );
    }
    return newSBR;
  }

  normalizeScenarioBorderSegment(
    sbs: ScenarioBorderSegment
  ): ScenarioBorderSegment {
    const newSBS = new ScenarioBorderSegment();
    if (sbs != undefined) {
      newSBS.coordinates = sbs.coordinates;
      newSBS.infacing = sbs.infacing;
      newSBS.outfacing = sbs.outfacing;
    }
    return newSBS;
  }

  getField(fieldId: string): Observable<Field> {
    return this.http.get<Field>(
      ServicesConfig.fieldsServiceUrl + '/' + fieldId
    );
  }

  createField(field: Field): Observable<Field> {
    return this.http.post<Field>(
      ServicesConfig.fieldsServiceUrl,
      JSON.stringify(field)
    );
  }
  updateField(id: string, field: Field): Observable<Field> {
    const newField = { ...field };
    newField.id = undefined; //unset ID as this is not allowed to get patched
    return this.http.patch<Field>(
      ServicesConfig.fieldsServiceUrl + '/' + id,
      JSON.stringify(newField)
    );
  }
  deleteField(field: Field): Observable<void> {
    return this.http.delete<void>(
      ServicesConfig.fieldsServiceUrl + '/' + field.id
    );
  }

  createScenario(field: Field, scenario: Scenario): Observable<Field> {
    return this.http.post<Field>(
      ServicesConfig.fieldsServiceUrl + '/' + field.id + '/scenarios',
      JSON.stringify(scenario)
    );
  }
  updateScenario(field: Field, scenario: Scenario): Observable<Field> {
    const newScenarioObj = JSON.parse(JSON.stringify(scenario)); // create a copy to let the id remain a mandatory field in the original object
    delete newScenarioObj.id;
    return this.http.patch<Field>(
      ServicesConfig.fieldsServiceUrl +
        '/' +
        field.id +
        '/scenarios/' +
        scenario.id,
      JSON.stringify(newScenarioObj)
    );
  }
  deleteScenario(field: Field, scenario: Scenario): Observable<void> {
    return this.http.delete<void>(
      ServicesConfig.fieldsServiceUrl +
        '/' +
        field.id +
        '/scenarios/' +
        scenario.id
    );
  }

  exportAllScenarios(): Observable<Blob> {
    return this.http.get<Blob>(
      ServicesConfig.fieldsServiceUrl + '/scenarios/export',
      { responseType: 'blob' as 'json' }
    );
  }

  exportScenarios(scenarios: Scenario[]): Observable<Blob> {
    let scenarioIds: string[] = [];
    scenarios.forEach((scenario) => scenarioIds.push(scenario!.id!));

    return this.http.post<Blob>(
      ServicesConfig.fieldsServiceUrl + '/scenarios/export',
      JSON.stringify(scenarioIds),
      { responseType: 'blob' as 'json' }
    );
  }
}
