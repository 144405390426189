import { Subscription } from 'rxjs';
import {
  AfterContentInit,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  SelectedSpreadingType,
  SpreadingFacing,
  SpreadingType,
  SpreadingTypeInfo,
} from './spreading-type';
import { Helper } from '../Helper';
import { Store, select } from '@ngrx/store';
import { MapState } from '../map/map.states';
import { MapActions } from '../map/map.actions';
import { selectCurrentSpreadingType } from '../map/map.selector';

@Component({
  selector: 'app-spreading-type-selector',
  templateUrl: './spreading-type-selector.component.html',
  styleUrls: ['./spreading-type-selector.component.scss'],
})
export class SpreadingTypeSelectorComponent {
  currSpreadingTypeInMapDataSub: Subscription;

  outfacingSpreadingTypes = SpreadingTypeInfo.outfacingTypes(); // available outfacing spreading types
  infacingSpreadingTypes = SpreadingTypeInfo.infacingTypes(); // available infacing spreading types
  selectedSpreadingType = new SelectedSpreadingType(
    SpreadingType.NormalSpreading,
    SpreadingType.NormalSpreading
  ); // currently selected spreading type

  constructor(public store: Store<{ mapdata: MapState }>) {
    this.currSpreadingTypeInMapDataSub = store
      .select('mapdata')
      .pipe(select(selectCurrentSpreadingType))
      .subscribe((spreadingType) => {
        this.selectedSpreadingType = spreadingType;
        MapActions.changeSelectedSpreadingType({
          newSpreadingType: structuredClone(this.selectedSpreadingType),
        });
      });
  }

  // event to emit selected spreading type change
  // @Output() spreadingTypeChange = new EventEmitter<SelectedSpreadingType>();

  // checks if the provided spreading type is currently selected
  isActive(type: SpreadingType): boolean {
    switch (SpreadingTypeInfo.getFacing(type)) {
      case SpreadingFacing.Infacing:
        return this.selectedSpreadingType.infacing == type;
      case SpreadingFacing.Outfacing:
        return this.selectedSpreadingType.outfacing == type;
    }
  }

  // sets provided spreading type to current selection
  // additionally performs sanity checks and resets if invalid
  toggleSpreadingType(type: SpreadingType) {
    const newSpreadingTypeInfo = SpreadingTypeInfo.getInfo(type);
    let outfacing = this.selectedSpreadingType.outfacing;
    let infacing = this.selectedSpreadingType.infacing;

    switch (newSpreadingTypeInfo.facing) {
      case SpreadingFacing.Infacing: {
        // infacing is a special case, if spreading type is deactivated use NormalSpreading
        if (this.selectedSpreadingType.infacing == type) {
          //TODO: could this not change the setting to something different?
          infacing = SpreadingType.NormalSpreading;
        } else if (this.isValid(type)) {
          infacing = type;
        }
        break;
      }
      case SpreadingFacing.Outfacing: {
        outfacing = type;
        if (!this.isValid(type)) {
          infacing = SpreadingType.NormalSpreading;
        }
        break;
      }
    }
    this.selectedSpreadingType = new SelectedSpreadingType(outfacing, infacing);

    this.store.dispatch(
      MapActions.changeSelectedSpreadingType({
        newSpreadingType: structuredClone(this.selectedSpreadingType),
      })
    );
  }

  // checks if provided spreading type is valid with other facing spreading type
  isValid(type: SpreadingType) {
    const spreadingTypeInfo = SpreadingTypeInfo.getInfo(type);
    switch (spreadingTypeInfo.facing) {
      case SpreadingFacing.Infacing:
        return spreadingTypeInfo.combinations.includes(
          this.selectedSpreadingType.outfacing
        );
      case SpreadingFacing.Outfacing:
        return spreadingTypeInfo.combinations.includes(
          this.selectedSpreadingType.infacing
        );
    }
  }

  // generates alt name for spreading type image by provided spreading type
  altName(type: SpreadingType) {
    return SpreadingType[type];
  }
  // generates asset name for spreading type image by provided spreading type
  assetName(type: SpreadingType) {
    return Helper.toSnakeCase(SpreadingType[type]);
  }
}
