import {
  doLineSegmentsIntersect,
  extendLine,
  intersection,
} from 'src/utilities/intersection-check';
import {
  ScenarioBorderRing,
  ScenarioBorderSegment,
} from '../../fields/field.model';
import { InsideControlColor } from '../map-drawing-colors';
import { drawLine } from '../map-drawing-logic';
import { ScenarioLine } from './map-drawing-logic-scenario';
import { MapComponent } from '../map.component';

///////
// functions to draw an inside-control Line
///////

const insideControlLineOffset = 0.00005;
const extensionValueHorizontal = 0.00003;
const extensionValueVertical = 0.0000008;

let slope = 0;
let isHorizontalLine = true;

function getInsideControlLinePosition(
  mapInstance: MapComponent,
  fLine: ScenarioLine,
  borderRing: ScenarioBorderRing
) {
  let startPos = new google.maps.LatLng(
    fLine.line?.getPath().getArray()[0].lat() as number,
    fLine.line?.getPath().getArray()[0].lng() as number
  );
  let endPos = new google.maps.LatLng(
    fLine.line?.getPath().getArray()[1].lat() as number,
    fLine.line?.getPath().getArray()[1].lng() as number
  );

  //offset of inside control line to be within field borders
  let offsets = calculateOffset(startPos, endPos);
  let latitudeOffset = offsets.latitudeOffset;
  let longitudeOffset = offsets.longitudeOffset;

  //this is only based on outer segment lines which are only clockwise!
  let startPosICL = new google.maps.LatLng(
    (startPos.lat() as number) + latitudeOffset * insideControlLineOffset,
    (startPos.lng() as number) + longitudeOffset * insideControlLineOffset
  );
  let endPosICL = new google.maps.LatLng(
    (endPos.lat() as number) + latitudeOffset * insideControlLineOffset,
    (endPos.lng() as number) + longitudeOffset * insideControlLineOffset
  );

  //cut down inside control line to only reach to intersection with next line
  return cutInsideControlLine(
    mapInstance,
    fLine,
    startPosICL,
    endPosICL,
    borderRing
  );
}

export function calculateOffset(
  startPos: google.maps.LatLng,
  endPos: google.maps.LatLng
): { latitudeOffset; longitudeOffset } {
  let latitudeOffset = 0;
  let longitudeOffset = 0;

  slope = (endPos.lat() - startPos.lat()) / (endPos.lng() - startPos.lng());
  isHorizontalLine = Math.abs(Math.atan(slope)) < 1;

  let startTopLeftToEnd =
    startPos.lng() < endPos.lng() && startPos.lat() >= endPos.lat();
  let startBottomLeftToEnd =
    startPos.lng() <= endPos.lng() && startPos.lat() <= endPos.lat();
  let startTopRightToEnd =
    startPos.lng() >= endPos.lng() && startPos.lat() > endPos.lat();
  let startBottomRightToEnd =
    startPos.lng() >= endPos.lng() && startPos.lat() < endPos.lat();

  if (isHorizontalLine) {
    if (startTopRightToEnd || startBottomRightToEnd) {
      latitudeOffset = 1;
    }
    if (startBottomLeftToEnd || startTopLeftToEnd) {
      latitudeOffset = -1;
    }
  } else {
    if (startBottomLeftToEnd || startBottomRightToEnd) {
      longitudeOffset = 1;
    }
    if (startTopLeftToEnd || startTopRightToEnd) {
      longitudeOffset = -1;
    }
  }

  return { latitudeOffset: latitudeOffset, longitudeOffset: longitudeOffset };
}

function distanceIntersectionToPoint(intersectionPoint, pointICL) {
  return Math.sqrt(
    Math.pow(intersectionPoint.lng() - pointICL.lng(), 2) +
      Math.pow(intersectionPoint.lat() - pointICL.lat(), 2)
  );
}

function cutInsideControlLine(
  mapInstance: MapComponent,
  fLine,
  startPosICL: google.maps.LatLng,
  endPosICL: google.maps.LatLng,
  borderRing: ScenarioBorderRing
) {
  //extend inside control line to make sure it reaches closest line
  let extensionValue = 0;
  if (isHorizontalLine) {
    extensionValue = extensionValueHorizontal;
  } else {
    extensionValue = extensionValueVertical;
  }
  let extendedStartPos = extendLine(startPosICL, slope, extensionValue);
  let extendedEndPos = extendLine(endPosICL, slope, -extensionValue);

  let startAsPoint = { x: extendedStartPos.lng(), y: extendedStartPos.lat() };
  let endAsPoint = { x: extendedEndPos.lng(), y: extendedEndPos.lat() };

  //TODO: check exclusions

  console.log(borderRing.segments);

  //TODO: enable again to check borders
  // let scenarioLines: FieldLine[] = mapInstance.scenarioData.lines[0];
  let borderSegments: ScenarioBorderSegment[] = borderRing.segments;
  for (let segment of borderSegments) {
    // borderSegments.forEach((scenarioLine) => {
    // if (segment.id == fLine.id) {
    //   continue;
    // }

    const startCoordinate = segment.coordinates[0];
    const endCoordinate = segment.coordinates[1];

    let lineStartPoint = {
      x: startCoordinate[0],
      y: startCoordinate[1],
    };
    let lineEndPoint = {
      x: endCoordinate[0],
      y: endCoordinate[1],
    };
    let intersectionExists = doLineSegmentsIntersect(
      startAsPoint,
      endAsPoint,
      lineStartPoint,
      lineEndPoint
    );

    if (intersectionExists) {
      let intersectionPoint = intersection(
        extendedStartPos,
        extendedEndPos,
        new google.maps.LatLng(startCoordinate[1], startCoordinate[0]),
        new google.maps.LatLng(endCoordinate[1], endCoordinate[0])
      );

      let distanceToStart = distanceIntersectionToPoint(
        intersectionPoint,
        startPosICL
      );
      let distanceToEnd = distanceIntersectionToPoint(
        intersectionPoint,
        endPosICL
      );

      if (distanceToStart < distanceToEnd) {
        startPosICL = intersectionPoint;
      } else {
        endPosICL = intersectionPoint;
      }
    }
  }

  return [startPosICL, endPosICL];
}

export function createInsideControlLine(
  mapInstance: MapComponent,
  scenarioLine: ScenarioLine,
  borderRing: ScenarioBorderRing
): google.maps.Polyline {
  const path = getInsideControlLinePosition(
    mapInstance,
    scenarioLine,
    borderRing
  );

  //dotted line
  const lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    scale: 2,
  };
  const insideControlLine = drawLine(
    mapInstance.map,
    path,
    InsideControlColor,
    {
      strokeOpacity: 0,
      icons: {
        icon: lineSymbol,
        offset: '0',
        repeat: '15px',
      },
    }
  );
  return insideControlLine;
}
