import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { MapLoaderService } from 'src/utilities/map.loader';
import { AuthGuard } from './auth/auth-guard.guard';
import {MapViewComponent} from "./map-view/map-view.component";
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';


const googleResolver: ResolveFn<any> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      return MapLoaderService.load();
    };

const routes: Routes = [
  { path: '', component: MapViewComponent, canActivate: [AuthGuard], resolve: {data: googleResolver} },
  { path: 'welcome', component: WelcomePageComponent},
  { path: 'error', component: ErrorPageComponent},
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
