<div class="map-view">
  <app-field-list *ngIf="isInitialized" [class]="((isFieldListOpen$ | async)?'field-list-expanded':'field-list-collapsed')"></app-field-list>
  <div class="field-list-opener">
     <div role="button" (click)="toggleMenu()" (keyup)="toggleMenu()" aria-hidden="true">
       <img style="padding-top: 30px;" [src]="((isFieldListOpen$ | async)?'/assets/images/main/icon_arrow_left.svg':'/assets/images/main/icon_arrow_right.svg')"
          alt="expand" role="button" aria-hidden="true" class="toolbar-element btn-toolbar btn-sidebar-toggle no-drag" />
    </div>
  </div>
  <app-map class="map"></app-map>
</div>
