import { computeArea } from 'spherical-geometry-js';
import { Field, Polygon } from 'src/app/fields/field.model';
import {
  AdvancedMarker,
  FieldExclusion,
  FieldLine,
  drawLine,
  drawMarker,
  getPositionBetweenTwoPositions,
  getStartPosOfLine,
  isClosedFieldShape,
} from '../map-drawing-logic';
import { DOTTYPE } from '../map-state-control';
import {
  removeAllLineElements,
  removeFillPoly,
} from './map-drawing-logic-field';

export class TempFieldData {
  id: string;
  dotStart: AdvancedMarker | undefined;
  lines: FieldLine[];
  exclusions: FieldExclusion[];
  fillPoly: google.maps.Polygon | undefined = undefined; // filling polygon for the finished shape

  constructor() {
    this.id = '';
    this.dotStart = undefined;
    this.fillPoly = undefined;
    this.lines = [];
    this.exclusions = [];
  }

  /////////////////////////////////////
  // Get Information
  /////////////////////////////////////

  getFirstLineStartingPos() {
    return this.dotStart?.getPosition();

    // getLastFieldShapeLine(mapInstance.fieldShape).dotEnd!.getPosition()!;
  }

  /**
   *
   * @returns LastFieldShapeLine
   */
  getLastFieldShapeLine() {
    return this.lines[this.lineCount() - 1];
  }

  /**
   *
   * @returns current number of lines
   */
  lineCount() {
    return this.lines.length;
  }

  getAsFieldObj(assignedName: string, id?: string, type = 'greenArea'): Field {
    const nField = new Field();

    nField.id = id;
    nField.name = assignedName; //TODO: prevent it is the same name

    nField.type = type;

    let coordinates: number[][] = [];
    let geoCoordinates: google.maps.LatLng[] = [];

    //first add starting coordinate
    if (this.dotStart) {
      const pos = this.dotStart.getPosition();
      if (pos) coordinates.push([pos.lng(), pos.lat()]);
    }

    for (const line of this.lines) {
      if (line.dotEnd && line.dotEnd.getPosition()) {
        const pos = line.dotEnd.getPosition();
        if (pos) {
          coordinates.push([pos.lng(), pos.lat()]);
          geoCoordinates.push(pos);
        }
      }
    }
    // coordinates.push(coordinates[0]); // close the loop?
    let area = computeArea(geoCoordinates) / 10000;

    const fieldCoordinates = [coordinates];

    //add exclusion areas
    for (const exclusion of this.exclusions) {
      geoCoordinates = [];
      coordinates = [];

      //add start of exclusion area
      if (exclusion.lines[0]) {
        const pos = getStartPosOfLine(exclusion.lines[0].line!);
        if (pos) coordinates.push([pos.lng(), pos.lat()]);
      }

      for (const line of exclusion.lines) {
        if (line.dotEnd && line.dotEnd.getPosition()) {
          const pos = line.dotEnd.getPosition();
          if (pos) {
            coordinates.push([pos.lng(), pos.lat()]);
            geoCoordinates.push(pos);
          }
        }
      }
      area -= computeArea(geoCoordinates) / 10000; //remove exclusion area from field-Size
      fieldCoordinates.push(coordinates);
    }

    nField.area = area; // check if we want to have result in ha or in m²
    nField.polygon = new Polygon(fieldCoordinates); // info: coordinates in path, in pathS >> 3 arrays deep

    return nField;
  }

  /////////////////////////////////////////////
  // Change Model
  /////////////////////////////////////////////

  removeMarker(marker: AdvancedMarker) {
    // search for which lines are affected by removing this dot
    // checks for outter border first, then the inner borders. If corresponding marker is not found, nothing happens

    this.lines = this.removeMatchingMarker(this.lines, marker);
    this.exclusions.forEach((exclucion) => {
      exclucion.lines = this.removeMatchingMarker(exclucion.lines, marker);
    });
  }

  removeMatchingMarker(lines: FieldLine[], marker): FieldLine[] {
    lines.forEach((fLine, index) => {
      if (fLine.dotEnd?.uuid && fLine.dotEnd.uuid == marker.uuid) {
        const isClosed = isClosedFieldShape(this);
        // remove old data
        //done here as otherwise no longer access
        removeFillPoly(this);
        removeAllLineElements(fLine);

        // remove fieldLine of the selected marker (we now find the following line (if there is one) at the same index afterwards)
        lines = [...lines.slice(0, index), ...lines.slice(index + 1)];

        //when first border point is being deleted
        if (index == lines.length && isClosed) {
          this.dotStart?.setPosition(lines[index - 1].dotEnd?.getPosition()); //otherwise old dotStart will be set
          lines[0].line!.getPath().getArray()[0] = lines[index - 1].line
            ?.getPath()
            .getArray()[0]!;
        }
        return;
      }
    });
    return lines;
  }

  splitLineAtMarker(marker: AdvancedMarker) {
    if (marker.DOTTYPE != DOTTYPE.Split) {
      console.warn('splitLineAtMarker called for a non-Split Marker', marker);
      return;
    }
    // search for which line is affected by splitting with this dot
    // checks for outter border first, then the inner borders. If corresponding marker is not found, nothing happens
    this.splitLineAtMatchingMarker(this.lines, marker);
    for (
      let exclusionIndex = 0;
      exclusionIndex < this.exclusions.length;
      exclusionIndex++
    ) {
      let exclusionLines = this.exclusions[exclusionIndex].lines;
      this.splitLineAtMatchingMarker(
        exclusionLines,
        marker,
        true,
        exclusionIndex
      );
    }
  }

  splitLineAtMatchingMarker(
    lines: FieldLine[],
    marker,
    isExlusion = false,
    exclusionIndex = -1
  ) {
    const newPosition = marker.getPosition();

    lines.forEach((fieldLine, index) => {
      if (
        fieldLine.dotSplit != undefined &&
        fieldLine.dotSplit.uuid == marker.uuid &&
        fieldLine.dotEnd
      ) {
        //this check is always be false in case of exclusion zone
        const isSplittingLastLine =
          this.dotStart?.uuid == fieldLine.dotEnd.uuid;
        const map = fieldLine.line?.getMap();
        if (!map) {
          console.warn('line not visible. Skipping');
          return;
        }

        //use eventHandler from current endpoint
        const eventHandler = fieldLine.dotEnd.eventHandler;

        // move end-marker and remove old line
        const helpEndPos = fieldLine.dotEnd.getPosition();

        // check to replace remove-dot with regular end-dot if necessary - otherwise just move it
        if (index == lines.length - 2) {
          console.warn('what to change');
          fieldLine.dotEnd.setMap(null);
          fieldLine.dotEnd = drawMarker(
            map,
            DOTTYPE.End,
            fieldLine.dotSplit.getPosition()!,
            eventHandler //use old Handler
          );
        } else if (isSplittingLastLine) {
          // if the last line is being split, a new dotEnd has to be drawn, as the last dotEnd is ignored when drawing a finished field
          fieldLine.dotEnd = drawMarker(
            map,
            DOTTYPE.End,
            newPosition!,
            lines[index - 1].dotEnd?.eventHandler // eventHandler are taken from the dotEnd from the line before the last one in this case
          );
        } else {
          fieldLine.dotEnd.setPosition(newPosition); // move end first to calc new dotSplit position
        }

        const newEndPos = isSplittingLastLine
          ? this.dotStart?.getPosition()
          : fieldLine.dotEnd.getPosition();
        fieldLine.dotSplit.setPosition(
          getPositionBetweenTwoPositions(
            getStartPosOfLine(fieldLine.line!),
            newEndPos!
          )
        );

        fieldLine.line!.setMap(null);

        // calculate the starting position of the first half of the splitted line
        let startPosNewLine: google.maps.LatLng | undefined | null = undefined;
        if (index === 0) {
          startPosNewLine = isExlusion
            ? getStartPosOfLine(lines[0].line!)
            : this.getFirstLineStartingPos();
        } else {
          startPosNewLine = lines[index - 1].dotEnd!.getPosition();
        }
        // draw new line, representating the first half of the splitted line
        const newLine = drawLine(map, [
          startPosNewLine!,
          fieldLine.dotEnd.getPosition()!,
        ]);
        fieldLine.line = newLine;

        // draw next line, representing the second half of the splitted line with the end of the previous as the start and the old end of the previous as the end
        const nextDotEnd = drawMarker(
          map,
          DOTTYPE.End,
          helpEndPos!,
          eventHandler
        );
        const newLineObj: FieldLine = {
          id: crypto.randomUUID(),
          dotSplit: drawMarker(
            map,
            DOTTYPE.Split,
            getPositionBetweenTwoPositions(
              fieldLine.dotEnd.getPosition()!,
              nextDotEnd.getPosition()!
            ),
            eventHandler
          ),
          dotEnd: nextDotEnd,
          line: drawLine(map, [
            fieldLine.dotEnd.getPosition()!,
            nextDotEnd.getPosition()!,
          ]),
        };

        // completely update the actual lines-array:
        this.replaceFieldShapeLineAtIndex(
          index,
          newLineObj,
          isExlusion,
          exclusionIndex
        );
      }
    });
  }

  replaceFieldShapeLineAtIndex(
    index: number,
    replacingLine: FieldLine,
    isExlusion = false,
    exclusionIndex = -1
  ) {
    //necessary do differtiate between outer border (this.lines) and possible exclusion area (within this.exclusions)
    isExlusion
      ? (this.exclusions[exclusionIndex].lines = [
          ...this.exclusions[exclusionIndex].lines.slice(0, index + 1),
          replacingLine,
          ...this.exclusions[exclusionIndex].lines.slice(index + 1),
        ])
      : (this.lines = [
          ...this.lines.slice(0, index + 1),
          replacingLine,
          ...this.lines.slice(index + 1),
        ]);
  }
}
