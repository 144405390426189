class Debounce {
  timeoutId = -1;
  run(func: () => void, timeout: number) {
    clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(() => {
      func();
    }, timeout);
  }
}

const debounce = function debounce<F extends Function>(func:F, wait:number):F {
  let timeoutID:number;

  if (!Number.isInteger(wait)) {
    console.warn("Called debounce without a valid number")
    wait = 300;
  }

  // conversion through any necessary as it wont satisfy criteria otherwise
  return <any>function(this:any, ...args: any[]) {
    clearTimeout(timeoutID);
    const context = this;
    timeoutID = window.setTimeout(function() {
      func.apply(context, args);
    }, wait);
  };
};


export {
  Debounce,
  debounce
}
