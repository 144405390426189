
// basis for default configuration
//const baseUrl = 'https://apim-amaplan-dev.azure-api.net/api'; //
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;

export const ServicesConfig = {
    // base URL for all services
    baseUrl: baseUrl,

    profileServiceUrl: baseUrl+'/profile/profile',
    fieldsServiceUrl: baseUrl+'/fields/fields',

}