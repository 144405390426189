import {  inject } from "@angular/core";
import { AuthService } from "./auth.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AuthActions } from "./auth.actions";
import { catchError, exhaustMap, map, of, tap } from "rxjs";
import { ProfileService } from "../services/profile.service";

export const logout$ = createEffect((authService = inject(AuthService)) => {
            return inject(Actions).pipe(
                            ofType(AuthActions.logout),
                            tap(() => { authService.logout();})
                            );
        },
        { functional: true, dispatch: false }
            );




//we request loading profile
export const requestLoadProfile$ = createEffect((profileService = inject(ProfileService)) => {
    return inject(Actions).pipe(
                    ofType(AuthActions.tokenReceived),
                    exhaustMap(() => profileService.getProfile()
                    .pipe(
                        map(data => AuthActions.newProfile({profile: data})),
                        catchError((error) => of(AuthActions.loadingError( {error: JSON.stringify(error)})))
                      ))
                  );
    }
,
{ functional: true, dispatch: true }
    );
