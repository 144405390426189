import {
  BorderSpreadingColor,
  BorderSpreadingWithSpreadingScreenColor,
  DigSpreadingColor,
  EdgeSpreadingColor,
  InsideControlColor,
  NormalSpreadingColor,
  PatchSpreadingWithSpreadingScreenColor,
  SpreadingTypeDefaultColor,
} from '../map/map-drawing-colors';
//Default spreading type defined at the bottom!

// available spreading types
enum SpreadingType {
  NormalSpreading,
  EdgeSpreading,
  BorderSpreading,
  DigSpreading,
  InsideControl,
  BorderSpreadingWithSpreadingScreen,
  PatchSpreadingWithSpreadingScreen,
}

// possible facing direction of spreading types
enum SpreadingFacing {
  Outfacing,
  Infacing,
}

// additional spreading type info/properties
// provides static functions to access spreading type info
class SpreadingTypeInfo {
  type: SpreadingType;
  uiName: string = ''; // technical name component for e.g. icons or labels based on type
  facing: SpreadingFacing;
  combinations: SpreadingType[]; // allowed combinations with current spreading type

  private static types = [
    SpreadingTypeInfo.normalSpreading(),
    SpreadingTypeInfo.edgeSpreading(),
    SpreadingTypeInfo.borderSpreading(),
    SpreadingTypeInfo.digSpreading(),
    SpreadingTypeInfo.insideControl(),
    SpreadingTypeInfo.patchSpreadingWithSpreadingScreen(),
    SpreadingTypeInfo.borderSpreadingWithSpreadingScreen(),
  ];

  static outfacingTypes(): SpreadingTypeInfo[] {
    return SpreadingTypeInfo.typesByFacing(SpreadingFacing.Outfacing);
  }
  static infacingTypes(): SpreadingTypeInfo[] {
    return SpreadingTypeInfo.typesByFacing(SpreadingFacing.Infacing);
  }
  static typesByFacing(facing: SpreadingFacing): SpreadingTypeInfo[] {
    return SpreadingTypeInfo.types.filter((info) => info.facing === facing);
  }

  static getFacing(type: SpreadingType) {
    return SpreadingTypeInfo.getInfo(type).facing;
  }

  static getInfo(type: SpreadingType) {
    const info = SpreadingTypeInfo.types.find((info) => info.type === type);
    if (info == null) {
      throw new Error(`spreading type ${type} not registered.`);
    }
    return info;
  }
  static normalSpreading() {
    return new SpreadingTypeInfo(
      SpreadingType.NormalSpreading,
      SpreadingFacing.Outfacing,
      []
    );
  }
  static edgeSpreading() {
    return new SpreadingTypeInfo(
      SpreadingType.EdgeSpreading,
      SpreadingFacing.Outfacing,
      [SpreadingType.InsideControl]
    );
  }
  static borderSpreading() {
    return new SpreadingTypeInfo(
      SpreadingType.BorderSpreading,
      SpreadingFacing.Outfacing,
      [SpreadingType.InsideControl]
    );
  }
  static digSpreading() {
    return new SpreadingTypeInfo(
      SpreadingType.DigSpreading,
      SpreadingFacing.Outfacing,
      [SpreadingType.InsideControl]
    );
  }
  static insideControl() {
    return new SpreadingTypeInfo(
      SpreadingType.InsideControl,
      SpreadingFacing.Infacing,
      [
        SpreadingType.EdgeSpreading,
        SpreadingType.BorderSpreading,
        SpreadingType.DigSpreading,
      ]
    );
  }
  static borderSpreadingWithSpreadingScreen() {
    return new SpreadingTypeInfo(
      SpreadingType.BorderSpreadingWithSpreadingScreen,
      SpreadingFacing.Outfacing,
      []
    );
  }
  static patchSpreadingWithSpreadingScreen() {
    return new SpreadingTypeInfo(
      SpreadingType.PatchSpreadingWithSpreadingScreen,
      SpreadingFacing.Outfacing,
      []
    );
  }
  private constructor(
    type: SpreadingType,
    facing: SpreadingFacing,
    combinations: SpreadingType[]
  ) {
    this.type = type;
    this.facing = facing;
    this.combinations = combinations.slice();
    switch (this.type) {
      case SpreadingType.NormalSpreading:
        this.uiName = 'normal_spreading';
        break;
      case SpreadingType.EdgeSpreading:
        this.uiName = 'edge_spreading';
        break;
      case SpreadingType.BorderSpreading:
        this.uiName = 'border_spreading';
        break;
      case SpreadingType.DigSpreading:
        this.uiName = 'dig_spreading';
        break;
      case SpreadingType.InsideControl:
        this.uiName = 'inside_control';
        break;
      case SpreadingType.BorderSpreadingWithSpreadingScreen:
        this.uiName = 'border_spreading_with_spreading_screen';
        break;
      case SpreadingType.PatchSpreadingWithSpreadingScreen:
        this.uiName = 'patch_spreading_with_spreading_screen';
        break;
      default:
        break;
    }
  }
}

class SelectedSpreadingType {
  outfacing: SpreadingType;
  infacing: SpreadingType;
  color: string = SpreadingTypeDefaultColor;
  uiName: string = 'normal_spreading'; // technical name component for e.g. icons or labels

  constructor(outfacing: SpreadingType, infacing: SpreadingType) {
    this.infacing = infacing;
    this.outfacing = outfacing;

    //set color to correct value
    switch (outfacing) {
      case SpreadingType.NormalSpreading:
        this.color = NormalSpreadingColor;
        this.uiName = 'normal_spreading';
        break;
      case SpreadingType.EdgeSpreading:
        this.color = EdgeSpreadingColor;
        this.uiName = 'edge_spreading';
        break;
      case SpreadingType.BorderSpreading:
        this.color = BorderSpreadingColor;
        this.uiName = 'border_spreading';
        break;
      case SpreadingType.DigSpreading:
        this.color = DigSpreadingColor;
        this.uiName = 'dig_spreading';
        break;
      case SpreadingType.InsideControl:
        this.color = InsideControlColor;
        this.uiName = 'inside_control';
        break;
      case SpreadingType.BorderSpreadingWithSpreadingScreen:
        this.color = BorderSpreadingWithSpreadingScreenColor;
        this.uiName = 'border_spreading_with_spreading_screen';
        break;
      case SpreadingType.PatchSpreadingWithSpreadingScreen:
        this.color = PatchSpreadingWithSpreadingScreenColor;
        this.uiName = 'patch_spreading_with_spreading_screen';
        break;
      default:
        console.warn(
          'unknown spreading type infacing:',
          infacing,
          'outfacing',
          outfacing
        );
        break;
    }
  }

  hasInsideControl(): boolean {
    return this.infacing != SpreadingType.NormalSpreading; //TODO: check if correct
  }

  equals(obj: SelectedSpreadingType | undefined): boolean {
    if (obj === undefined) return false;

    return this.outfacing === obj.outfacing && this.infacing === obj.infacing;
  }
}

function getSpreadingTypeStringForEnum(spreadingType: SpreadingType) {
  let strType = 'normalSpreading';
  switch (spreadingType) {
    case SpreadingType.NormalSpreading:
      break;
    case SpreadingType.EdgeSpreading:
      strType = 'edgeSpreading';
      break;
    case SpreadingType.BorderSpreading:
      strType = 'borderSpreading';
      break;
    case SpreadingType.DigSpreading:
      strType = 'digSpreading';
      break;
    case SpreadingType.BorderSpreadingWithSpreadingScreen:
      strType = 'borderSpreadingWithSpreadingScreen';
      break;
    case SpreadingType.PatchSpreadingWithSpreadingScreen:
      strType = 'patchSpreadingWithSpreadingScreen';
      break;
    case SpreadingType.InsideControl:
      strType = 'insideControl';
      break;
    default:
      console.warn(
        'Could not find SpreadingTypeEnum for spreadingType: ',
        spreadingType
      );
      break;
  }
  return strType;
}

function getSpreadingTypeEnumForString(strSpreadingType: string) {
  let type = SpreadingType.NormalSpreading;
  switch (strSpreadingType) {
    case 'normalSpreading':
      break;
    case 'edgeSpreading':
      type = SpreadingType.EdgeSpreading;
      break;
    case 'borderSpreading':
      type = SpreadingType.BorderSpreading;
      break;
    case 'digSpreading':
      type = SpreadingType.DigSpreading;
      break;
    case 'borderSpreadingWithSpreadingScreen':
      type = SpreadingType.BorderSpreadingWithSpreadingScreen;
      break;
    case 'patchSpreadingWithSpreadingScreen':
      type = SpreadingType.PatchSpreadingWithSpreadingScreen;
      break;
    case 'insideControl':
      type = SpreadingType.InsideControl;
      break;
    default:
      break;
  }
  return type;
}

/**
 * Default Spreading Type
 */
const DefaultSpreadingType = new SelectedSpreadingType(
  SpreadingType.NormalSpreading,
  SpreadingType.NormalSpreading
);

export {
  DefaultSpreadingType,
  SpreadingType,
  SpreadingFacing,
  SpreadingTypeInfo,
  SelectedSpreadingType,
  getSpreadingTypeEnumForString,
  getSpreadingTypeStringForEnum,
};
