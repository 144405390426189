import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Field, Scenario } from 'src/app/fields/field.model';
import { selectFields } from 'src/app/fields/fields.selector';
import { FieldState } from 'src/app/fields/fields.states';
import { renameFieldDuplicates } from 'src/app/map/map-objects-management';
import { MapActions } from 'src/app/map/map.actions';
import {
  selectCurrentField,
  selectCurrentScenario,
} from 'src/app/map/map.selector';
import { MapState } from 'src/app/map/map.states';
import { ProcessProgressActions } from 'src/app/process-progress/process-progress.actions';
import { ProcessProgressState } from 'src/app/process-progress/process-progress.states';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-assign-name-dialog',
  standalone: false,
  templateUrl: './assign-name-dialog.component.html',
  styleUrl: './assign-name-dialog.component.scss',
})
export class AssignNameDialogComponent {
  assignedName = '';
  displayNameError: boolean = false;
  nameTooShortError: boolean = false;
  nameAlreadyExistsError: boolean = false;

  allFields: Field[] = [];
  selectedField: Field | undefined;
  selectedScenario: Scenario | undefined;

  translatedScenarioKeyword = '';

  constructor(
    public translate: TranslateService,
    private store: Store<{
      mapdata: MapState;
      fields: FieldState;
      process: ProcessProgressState;
    }>,
    public dialogRef: MatDialogRef<AssignNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.translate
      .get('field.details.scenarios.keyword')
      .subscribe((data: any) => {
        this.translatedScenarioKeyword = data;
      });

    this.store
      .select('mapdata')
      .pipe(select(selectCurrentField))
      .subscribe((field) => {
        this.selectedField = field;
        if (field?.name) this.assignedName = field?.name;
      });
    this.store
      .select('mapdata')
      .pipe(select(selectCurrentScenario))
      .subscribe((scenario) => {
        this.selectedScenario = scenario;
        if (scenario) {
          this.assignedName = scenario?.name
            ? scenario?.name
            : this.generateScenarioName(this.selectedField!);
        }
      });
    this.store
      .select('fields')
      .pipe(select(selectFields))
      .subscribe((fieldsList) => {
        this.allFields = fieldsList;
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onBack() {
    this.store.dispatch(MapActions.undo());
    this.store.dispatch(ProcessProgressActions.takeStepBack());

    this.dialogRef.close();
  }

  onSave() {
    if (this.checkName(this.assignedName)) {
      this.store.dispatch(
        ProcessProgressActions.finishProcess({
          assignedName: this.assignedName,
        })
      );
      this.dialogRef.close();
    }
  }

  generateScenarioName(field: Field): string {
    let newIndex = field.scenarios?.length! + 1;
    let newName = field.name + this.translatedScenarioKeyword + newIndex;
    while (!this.checkIsScenarioNameUnique(field, newName)) {
      newIndex++;
      newName = field.name + this.translatedScenarioKeyword + newIndex;
      console.log('name was not unique.. updating index');
    }
    return newName;
  }

  checkName(name: string) {
    if (!this.checkIsNameLengthValid(name)) {
      this.displayNameError = true;
      this.nameTooShortError = true;
      return false;
    }
    if (!this.checkIsFieldNameUnique(name)) {
      this.displayNameError = true;
      this.nameAlreadyExistsError = true;
      return false;
    }
    return true;
  }
  checkIsNameLengthValid(name: string) {
    return name.trim().length > 3;
  }
  checkIsFieldNameUnique(name: string) {
    const nameVersionPossiblyRenamed = renameFieldDuplicates(
      name,
      this.allFields,
      this.selectedField?.name
    );
    return nameVersionPossiblyRenamed === name;
  }
  checkIsScenarioNameUnique(field: Field, name: string) {
    let isUnique = true;
    field.scenarios?.forEach((scenario) => {
      if (scenario.name == name) {
        isUnique = false;
        return;
      }
    });

    return isUnique;
  }
  // reacts on name changes and updates the nameValid state
  onChangeFieldName(newVal: string) {
    this.assignedName = newVal;
    if (this.nameTooShortError && this.checkIsNameLengthValid(newVal)) {
      this.displayNameError = !this.nameAlreadyExistsError
        ? false
        : this.displayNameError;
      this.nameTooShortError = false;
    }
    if (this.nameAlreadyExistsError && this.checkIsFieldNameUnique(newVal)) {
      this.displayNameError = !this.nameTooShortError
        ? false
        : this.displayNameError;
      this.nameAlreadyExistsError = false;
    }
    //TODO: handling of nameValidation
  }
}
