import { Pipe, PipeTransform } from "@angular/core";
import { Profile } from "src/app/services/profile";


@Pipe({name: 'userinitials'})
export class UserInitials implements PipeTransform {
    transform(value: Profile|unknown ): any {
        if (!value) {
            return value;
        }
        const p = value as Profile;
        return p.firstName.charAt(0).toUpperCase()+p.lastName.charAt(0).toUpperCase();
    }
}