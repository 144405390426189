import {NgModule} from '@angular/core';
import {AuthModule, LogLevel} from 'angular-auth-oidc-client';
import {environment} from 'src/environments/environment';


@NgModule({
    imports: [AuthModule.forRoot({
        config: {
              authority: environment.issuer,
              redirectUrl: window.location.origin,
              clientId: environment.clientId,
              scope: environment.scope, // 'openid profile offline_access ' + your scopes
              responseType: 'code',
              silentRenew: true,
              useRefreshToken: true,
              renewTimeBeforeTokenExpiresInSeconds: 30,
              logLevel: LogLevel.Error,
              ignoreNonceAfterRefresh: true,
              allowUnsafeReuseRefreshToken: true,
              customParamsCodeRequest: {
                  'client_secret': environment.clientSecret
              },
              customParamsRefreshTokenRequest: {
                'client_secret': environment.clientSecret
              },
              secureRoutes: [ environment.baseUrl ]
          }
      })],
    exports: [AuthModule],
})
export class AuthConfigModule {}
