import { Injectable } from '@angular/core';
import { ISOXMLManager, LineString, Point, Polygon } from 'isoxml';
import { Observable, Subscriber } from 'rxjs';
import { computeArea } from 'spherical-geometry-js';
import { Field, Polygon as FieldPolygon } from '../fields/field.model';
import { ImportBase } from './import.service';

@Injectable({ providedIn: 'root' })
export class ImportIsoxml extends ImportBase {
  override loadFields(files: File[]): Observable<Field[]> {
    return new Observable<Field[]>((subscriber) => {
      files.forEach((file) => {
        if (file.type == 'application/x-zip-compressed') {
          this.loadFieldsFromZip(file, subscriber);
        } else {
          this.loadFieldsFromXML(file, subscriber);
        }
      });
    });
  }

  private loadFieldsFromZip(file: File, subscriber: Subscriber<Field[]>) {
    const isoxmlManager = new ISOXMLManager();
    file.arrayBuffer().then((buff) => {
      const fileArray = new Uint8Array(buff);
      isoxmlManager
        .parseISOXMLFile(fileArray, 'application/zip')
        .then(() => this.processIsoXML(isoxmlManager, subscriber));
    });
  }

  private loadFieldsFromXML(file: File, subscriber: Subscriber<Field[]>) {
    const isoxmlManager = new ISOXMLManager();
    file.text().then((content) => {
      isoxmlManager
        .parseISOXMLFile(content, 'text/xml')
        .then(() => this.processIsoXML(isoxmlManager, subscriber));
    });
  }

  private processIsoXML(
    isoxmlManager: ISOXMLManager,
    subscriber: Subscriber<Field[]>
  ) {
    // getWarnings() method returns all the warnings from the last parsing
    // console.log(isoxmlManager.getWarnings())

    const fields: Field[] = [];

    isoxmlManager.rootElement.attributes.Partfield?.forEach((partfield) => {
      //select coordinates and area from partfield
      let polygon: Polygon[] | undefined =
        partfield.attributes.PolygonnonTreatmentZoneonly;
      if (polygon == undefined) {
        return;
      }
      let lineStrings: LineString[] =
        partfield.attributes.PolygonnonTreatmentZoneonly![0].attributes
          .LineString || [];
      let geoInfo = this.getGeoInformation(lineStrings);

      //create new Field based off partfield
      const newField = new Field();
      newField.name = partfield.attributes.PartfieldDesignator || 'unknown';
      newField.area = geoInfo.area || 0;

      newField.polygon = new FieldPolygon(geoInfo.coordinates);
      newField.isSelected = false;
      fields.push(newField);
    });
    console.log('fields included in isoXML file: ' + JSON.stringify(fields));
    subscriber.next(fields);
  }

  getGeoInformation(lineStrings: LineString[]) {
    let fieldCoordinates: any[] = [];
    let area: number = 0;

    for (let lineIndex = 0; lineIndex < lineStrings.length; lineIndex++) {
      let areaCoordinates: any[] = [];
      let geoCoordinates: any = [];
      let points: Point[] = lineStrings[lineIndex].attributes.Point || [];
      points.forEach((point) => {
        let lat: number = point.attributes.PointNorth;
        let lng: number = point.attributes.PointEast;
        areaCoordinates.push([lng, lat]);
        geoCoordinates.push({ lat: lat, lng: lng });
      });
      fieldCoordinates.push(areaCoordinates);

      // check if we want to have result in ha or in m²
      if (lineIndex == 0) {
        area += computeArea(geoCoordinates) / 10000;
      } else {
        area -= computeArea(geoCoordinates) / 10000; //TODO: exclusions could overlapp!!!
      }
    }

    return { coordinates: fieldCoordinates, area: area };
  }
}
