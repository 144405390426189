<div class="searchcontainer">
  <mat-form-field class="searchfield">
    <img class="search-icon" matPrefix src="../../assets/images/common/icon_search.svg" alt="search"/>
    <mat-label class="search-label">{{ placeholder | translate }}</mat-label>
    <input id="searchbox" md-input-name="autocompleteField" matInput [matAutocomplete]="auto" [(ngModel)]="searchInput"
      (keydown)="onSearchInput($event)">
    <mat-autocomplete class="searchfield-mat-input" #auto="matAutocomplete" (optionSelected)="onSelectItem($event)">
      <mat-optgroup *ngFor="let group of source" [label]="group.type | translate">
        <mat-option *ngFor="let item of group.items" [value]="item">
          <img [src]="'/assets/images/marker/icon_marker_' + (item.type == 1 ? 'field' : item.type == 2 ? 'scenario' : 'place') +'.svg'" alt="field" /> {{ item.value }}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
</div>