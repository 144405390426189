import {AfterContentInit, Component} from '@angular/core';
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectFieldListOpen} from "../fields/fields.selector";
import {FieldState} from "../fields/fields.states";
import { FieldActions } from '../fields/fields.actions';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements AfterContentInit {
  isInitialized = false;

  fieldToFocus = '';

  isFieldListOpen$:Observable<boolean>;

  constructor(private store: Store<{fields:FieldState}>) {
    this.isFieldListOpen$ = this.store.select('fields').pipe(select(selectFieldListOpen));
  }

  ngAfterContentInit() {
    setTimeout(() => { // removes flicker of input element in sidebar while content is loading
      this.isInitialized = true;
    }, 200);
  }
  triggerChangeFieldToFocus(fieldID: string) {
    this.fieldToFocus = fieldID;
    setTimeout(() => {
      this.fieldToFocus = '';
    }, 10);
  }

  toggleMenu() {
    this.store.dispatch(FieldActions.toggleFieldListMenu());
 }

}
