import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
  isLoggedIn: boolean = false; // TODO: this.authService.isAuthenticated();

  constructor(
    public translate: TranslateService,
    private authService: AuthService
  ) {}

  onToLogout() {
    this.authService.logout();
  }
}
