import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogState } from './modal-dialog.states';
import { selectDialogType } from './modal-dialog.selector';
import { MatDialog } from '@angular/material/dialog';
import { AssignNameDialogComponent } from './assign-name-dialog/assign-name-dialog.component';
import { ModalDialogActions } from './modal-dialog.actions';

export enum DIALOGTYPE {
  DEFAULT = '',
  ASSIGNNAME = 'assign name',
}

@Component({
  selector: 'app-modal-dialog',
  standalone: false,
  templateUrl: './modal-dialog.component.html',
  styleUrl: './modal-dialog.component.scss',
})
export class ModalDialogComponent {
  inputString = '';
  displayError: boolean = false;
  inputTooShortError: boolean = false;
  inputAlreadyExistsError: boolean = false;

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    private store: Store<{
      dialog: ModalDialogState;
    }>
  ) {
    this.store
      .select('dialog')
      .pipe(select(selectDialogType))
      .subscribe((dialogType) => {
        let dialogRef;

        switch (dialogType) {
          case DIALOGTYPE.DEFAULT:
            return;
          case DIALOGTYPE.ASSIGNNAME:
            dialogRef = this.dialog.open(AssignNameDialogComponent, {
              data: { name: 'AssignName' },
              panelClass: 'assign-name-css',
              backdropClass: 'dialog-background',
              disableClose: true,
            });
        }

        dialogRef.afterClosed().subscribe((result) => {
          console.log('dialog was closed.', result);
          this.store.dispatch(ModalDialogActions.closeDialog());
        });
      });
  }

  onConfirmInput(newVal: string) {}

  onChangeInput(newVal: string) {}

  onCancel() {}
}
