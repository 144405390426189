import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Field, Scenario } from './field.model';

export const FieldActions = createActionGroup({
  source: 'Fields',
  events: {
    // defining an event without payload using the `emptyProps` function
    'request Load': emptyProps(),
    reset: emptyProps(),
    'error loading fields': emptyProps(),

    'Fields Received': props<{ allFields: Field[]; newFields: Field[] }>(),
    'create or update Field': props<{ field: Field; reload: boolean }>(),
    'create Field': props<{ field: Field; reload: boolean }>(),

    'Field created': props<{ field: Field; reload: boolean }>(),

    'update Field': props<{ id: string; field: Field }>(),
    'delete Field': props<{ field: Field }>(),

    'create or update Scenario': props<{
      field: Field;
      scenario: Scenario;
    }>(),
    'add Scenario': props<{ field: Field; scenario: Scenario }>(),
    'update Scenario': props<{ field: Field; scenario: Scenario }>(),
    'delete Scenario': props<{ field: Field; scenario: Scenario }>(),

    'toggle Field List Menu': emptyProps(),
    'open Field List Menu': emptyProps(),
    'close Field List Menu': emptyProps(),

    'select Field': props<{ field: Field; select: boolean }>(),
  },
});
