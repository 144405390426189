import { AdvancedMarker, FieldLine } from '../map/map-drawing-logic';
import { MarkerEventHandler } from '../map/map-events';
import { MapComponent } from '../map/map.component';

// available spreading types
export enum SelectionMode {
  None,
  MagicWand,
  Highlighter,
  Rectangle,
  Field,
  Combination,
}

export class SelectionModeHandler implements MarkerEventHandler {
  mode: SelectionMode;
  mapInstance: MapComponent | undefined = undefined;

  constructor(mode: SelectionMode, map: MapComponent | undefined = undefined) {
    this.mode = mode;
    this.mapInstance = map;
  }

  /**
   * currently returning itself to support setting this prior to dispatching to handle freezing
   * would be better as immutable constant during construction
   * @param map
   * @returns
   */
  initializeMap(map: MapComponent): SelectionModeHandler {
    return new SelectionModeHandler(this.mode, map);
  }

  /**
   * function will be called when Selection Mode will change to finish off and clean up
   */
  onExitSelectionMode() {}

  /**
   * function will be called when Selection Mode will started and set up
   */
  onStartSelectionMode() {
    if (!this.mapInstance || !this.mapInstance.map) return;

    this.mapInstance.map.setOptions({
      draggable: true,
      draggableCursor: 'default',
    });
  }

  /**
   * function called on mouse down event
   */
  onMouseDown(event: google.maps.MapMouseEvent) {}

  onMouseUp(event: google.maps.MapMouseEvent | undefined) {}

  onMouseLeave(event: MouseEvent) {
    // not mouseout, as this would stop selections on hovering the spreading mode icons (and possibly other elements in the map)
  }

  onMouseMove(event: google.maps.MapMouseEvent) {}

  onMouseDownLine(fieldLine: FieldLine, event: MouseEvent) {}

  //Marker Events
  onMarkerClick(marker: AdvancedMarker) {}
  onMarkerDragStart(marker: AdvancedMarker) {}
  onMarkerDragEnd(marker: AdvancedMarker) {}
  onMarkerDrag(marker: AdvancedMarker) {}
  onMarkerMouseOver(marker: AdvancedMarker) {}
  onMarkerMouseOut(marker: AdvancedMarker) {}

  // redrawing handler/function
  onBeforeRedraw() {}

  onAfterRedraw() {}
}

export const SelectionModeNoneHandler = new SelectionModeHandler(
  SelectionMode.None
);

/**
 *
 * @param event
 * @returns true if primary button (normally left) is pressed during provided mousevent
 */
export function leftMouseButtonDown(event: Event): boolean {
  return event instanceof MouseEvent && (event.buttons & 1) == 1;
}
