import { ImportBase } from "./import.service";
import { Field } from "../fields/field.model";
import { ImportIsoxml } from "./importISOXML.service";

export interface ImportState {
    importHandler: ImportBase;
    importedFields: ImportField[],
    isLoaded: boolean,

    isImportDialogOpen: boolean,
}

export const initialImportState: ImportState = {
    importHandler: new ImportIsoxml(),
    importedFields: [],
    isLoaded: false,

    isImportDialogOpen: false
}


export interface ImportField {
    field: Field,
    isImported: boolean,
    hasError: boolean
}

