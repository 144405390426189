import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SpreadingType} from "../spreading-type";

@Component({
  selector: 'app-spreading-type-selector-item',
  templateUrl: './spreading-type-selector-item.component.html',
  styleUrls: ['./spreading-type-selector-item.component.scss']
})
export class SpreadingTypeSelectorItemComponent {
  @Input() id: SpreadingType = SpreadingType.NormalSpreading;
  @Input() active = false;
  @Input() asset = '';
  @Input() alt = '';
  @Input() invalid = false;

  @Output() clickItem = new EventEmitter<SpreadingType>();
}
