<div class="import-field-overlay-bg">
  <div class="import-field-overlay-container">
    <div class="import-header">
      <div class="import-headline">
        {{ "import.headline" | translate }}
      </div>
      <div class="close-btn">
        <img
          [src]="'../../assets/images/common/icon_close.svg'"
          alt="icon close"
          role="button"
          (click)="onCancel()"
          class="cursor-pointer"
        />
      </div>
    </div>
    <div class="separator"></div>
    <div class="import-body">
      <!-- page 1 -->
      <div
        class="page1"
        *ngIf="!(fieldsLoaded$ | async) && (fieldOptions$ | async)?.length == 0"
      >
        <div class="btn-import-toggle">
          <mat-button-toggle-group
            name="importHandler"
            [value]="defaultHandler"
            (change)="onToggleImportHandler($event)"
          >
            <mat-button-toggle value="IsoXML">{{
              "import.type.isoxml.label" | translate
            }}</mat-button-toggle>
            <mat-button-toggle value="ShapeFile">{{
              "import.type.shapefile.label" | translate
            }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="field-simplification">
          <div class="simplify-text">
            <label>
              {{ "import.message.simplification.label" | translate }}
            </label>
          </div>

          <label for="simplify"></label>
          <input
            type="checkbox"
            id="simplify"
            name="simplify_borders"
            value="simplify_borders"
            [checked]="simplifyBorders"
            [disabled]="files.length != 0"
            (change)="onSimplification()"
            *ngIf="!hasImportStarted"
          />

          <br />
        </div>

        <div class="import-field-drag-and-drop">
          <div
            id="dropzone"
            class="dropzone"
            appDropzone
            (onFileDropped)="droppedFiles($event)"
            (click)="fileInput.click()"
          >
            {{ "import.btn.file.drop" | translate }}
            <input
              #fileInput
              type="file"
              multiple
              (change)="onFileSelect($event)"
              style="display: none"
            />
          </div>
          <div class="files-list" *ngIf="files?.length != 0">
            <div class="selected-files" *ngFor="let selectedFile of files">
              <button mat-button (click)="removeFile(selectedFile)">
                <img
                  [src]="'../../assets/images/common/icon_close.svg'"
                  alt="icon close"
                  class="cursor-pointer"
                />
              </button>
              <div class="file-names">
                {{ selectedFile.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- page 2 -->
      <div class="page2" *ngIf="fieldsLoaded$ | async">
        <!-- content -->
        <div
          class="import-field-list"
          *ngIf="(fieldOptions$ | async)?.length != 0"
        >
          <!-- field list header-->
          <div class="field-list-header-entry">
            <div class="field-info">
              <label class="field-selection">
                {{ "import.message.selection.label" | translate }}
              </label>
            </div>
            <label style="margin: auto">
              <input
                type="checkbox"
                id="i"
                name="select_all"
                value="select_all"
                [checked]="selectAll"
                (change)="onUnselectAll()"
                *ngIf="!hasImportStarted"
              />
            </label>
            <br />
          </div>
          <!-- field list -->
          <div
            class="field-list-entry"
            *ngFor="let option of fieldOptions$ | async; index as i"
          >
            <img
              class="field-marker"
              src="/assets/images/marker/icon_marker_field.svg"
              width="22px"
              height="22px"
              alt="field"
            />
            <div class="field-info">
              <label class="field-name" for="{{ option.field.id }}">
                {{ option.field.name }}</label
              >
              <label class="field-size" for="{{ option.field.id }}">
                {{ option.field.area.toFixed(2) }} ha</label
              >
            </div>
            <label class="form-control">
              <input
                type="checkbox"
                id="i"
                name="{{ option.field.id }}"
                value="{{ option.field.id }}"
                [checked]="isFieldSupposedToBeImported(option)"
                (change)="onFieldSelect(option)"
                *ngIf="!hasImportStarted"
              />
            </label>
            <br />
            <label *ngIf="option.isImported" for="{{ option.field.id }}">
              <img
                src="\assets\images\common\icon_success_import.svg"
                width="24px"
                height="24px"
                alt="field_success_import"
            /></label>
          </div>
        </div>
        <!-- no content -->
        <div *ngIf="(fieldOptions$ | async)?.length == 0">
          <div class="import-field-text">
            <span class="import-error-text"
              >{{ "import.message.loading.error" | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="import-footer">
      <!-- page 1 footer -->
      <div class="import-btns">
        <div
          *ngIf="
            !(fieldsLoaded$ | async) && (fieldOptions$ | async)?.length == 0
          "
        >
          <button class="btn-large btn-blue-primary" (click)="onUpload()">
            <span>{{ "import.btn.confirm.label" | translate }}</span>
          </button>
        </div>
      </div>

      <!-- page 2 footer -->
      <div
        class="import-btns"
        *ngIf="
          (fieldsLoaded$ | async) &&
          (fieldOptions$ | async)?.length != 0 &&
          !hasImportStarted
        "
      >
        <button class="btn-small btn-blue-secondary" (click)="onReset()">
          {{ "import.btn.reset.label" | translate }}
        </button>
        <button class="btn-small btn-blue-primary" (click)="onImport()">
          {{ "import.btn.confirm.label" | translate }}
        </button>
      </div>

      <!-- page 2.1 import empty footer -->
      <div
        class="import-btns"
        *ngIf="(fieldsLoaded$ | async) && (fieldOptions$ | async)?.length == 0"
      >
        <button class="btn-small btn-blue-secondary" (click)="onCancel()">
          {{ "import.btn.cancel.label" | translate }}
        </button>
        <button class="btn-small btn-blue-primary" (click)="onReset()">
          {{ "import.btn.reset.label" | translate }}
        </button>
      </div>

      <!-- page 2.2 import finished footer -->
      <div *ngIf="hasImportStarted">
        <div class="import-success-text">
          <label
            >{{ "import.message.importing.success" | translate }}
            {{ succesfulImported }} / {{ toBeImportedFields.length }}
          </label>
          <mat-progress-bar
            style="width: 343px"
            mode="determinate"
            [value]="currentProgress"
          ></mat-progress-bar>
        </div>
        <button
          *ngIf="isImportFinished && hasError"
          class="btn-large btn-blue-primary"
          (click)="onClose()"
        >
          {{ "import.btn.finished.label" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
