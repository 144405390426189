import { computeDistanceBetween } from 'spherical-geometry-js';
import { Field } from '../fields/field.model';
import { SelectedSpreadingType } from '../spreading-type-selector/spreading-type';
import { CustomFieldInfoOverlay } from './custom-overlay-field-info';
import { CustomSpreadingTypeOverlay } from './custom-overlay-spreading-type';
import { removeAllLineElements } from './field/map-drawing-logic-field';
import { TempFieldData } from './field/temp-field-data';
import {
  drawLineDefaultColor,
  drawLineRedrawColor,
} from './map-drawing-colors';
import { MarkerEventHandler } from './map-events';
import { DOTTYPE } from './map-state-control';
import { MapComponent } from './map.component';

export type AdvancedMarker = google.maps.Marker & {
  uuid?: string;
  DOTTYPE?: DOTTYPE;
  eventHandler?: MarkerEventHandler;
};

export type AdvancedPolyline = google.maps.Polyline & {
  id?: string;
  selected?: boolean;
  insideControlLine?: google.maps.Polyline;
};

export type AdvancedPolygon = google.maps.Polygon & {
  id?: string;
  center?: google.maps.LatLng;
  mapOverlays?: CustomFieldInfoOverlay[];
};

export type FieldLineData = {
  spreadingType: SelectedSpreadingType;
  spreadingTypeOverlay?: CustomSpreadingTypeOverlay;
};

// type for the lines of the field shape
export type FieldLine = {
  id: string;
  line?: AdvancedPolyline; // single polyline-element, drawn between dotStart & dotEnd
  dotSplit?: AdvancedMarker; // line-splitting dot for this line, automatically drawn in the middle of the line
  dotEnd?: AdvancedMarker;
  lineData?: FieldLineData;
};

export type FieldExclusion = {
  lines: FieldLine[];
};

export function isClosedField(field: Field): boolean {
  const coords = field.polygon.coordinates[0]; // only check outer border if field is already closed
  return isClosedFieldShapeCheckByCoords(coords);
}

export function isClosedFieldShape(fieldShape?: TempFieldData): boolean {
  if (!fieldShape?.dotStart) return false;

  if (fieldShape.lines.length < 3) return false;

  const startPos = fieldShape.dotStart.getPosition();
  const endPos =
    fieldShape.lines[fieldShape.lines.length - 1].dotEnd?.getPosition();
  const distance = computeDistanceBetween(startPos!, endPos!);
  return distance < 1;
}
function isClosedFieldShapeCheckByCoords(coordinates: number[][]): boolean {
  const lastEntry = coordinates[coordinates.length - 1];
  return coordinates[0][0] == lastEntry[0] && coordinates[0][1] == lastEntry[1];
}

// to not get into trouble with circular structures (all gmap components do have those), data-sets with only the needed data exist
type FieldShapeLinePath = {
  dotSplit: number[] | undefined;
  dotEnd: number[] | undefined;
  path: number[][];
};
export type FieldShapeValues = {
  id: string;
  dotStart: number[] | undefined;
  lines: FieldShapeLinePath[];
};

export function resetMapOptions(mapInstance: MapComponent) {
  mapInstance.map.setOptions({
    tilt: 0,
    zoomControl: false,
    minZoom: 7,
    maxZoom: 21,
    draggableCursor: 'default',
    draggable: true,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit.station', // disables bus, rail and airport
        stylers: [{ visibility: 'off' }],
      },
    ],
  });
}

export function getSecondToLastFieldShapeLine(fieldShape: TempFieldData) {
  return fieldShape.lines[fieldShape.lineCount() - 2];
}

export function resetFieldShape(fieldShape: TempFieldData): TempFieldData {
  if (fieldShape.dotStart) {
    fieldShape.dotStart.setMap(null);
    fieldShape.dotStart = undefined;
  }
  for (const fLine of fieldShape.lines) {
    removeAllLineElements(fLine);
  }
  fieldShape.lines = [];

  for (const exclusion of fieldShape.exclusions)
    for (const fLine of exclusion.lines) {
      removeAllLineElements(fLine);
    }
  fieldShape.exclusions = [];

  fieldShape = new TempFieldData();
  return fieldShape;
}

type DrawLineOptions = {
  clickable?: boolean;
  strokeOpacity?: number;
  strokeWeight?: number;
  icons?: object;
};

export function drawLine(
  map: google.maps.Map,
  path: google.maps.LatLng[],
  overrColor = '',
  advOptions: DrawLineOptions | undefined = undefined
) {
  const newPolyline: AdvancedPolyline = new google.maps.Polyline({
    clickable:
      advOptions?.clickable != undefined ? advOptions.clickable : false, // clickable controls the cursor AND the mouse-events being called or not, so just setting this to false to keep the correct cursor on mouseover does not work
    path,
    geodesic: true,
    strokeColor: overrColor != '' ? overrColor : drawLineDefaultColor,
    strokeOpacity:
      advOptions?.strokeOpacity != undefined ? advOptions.strokeOpacity : 1,
    icons: advOptions?.icons != undefined ? [advOptions.icons] : [],
    strokeWeight:
      advOptions?.strokeWeight != undefined ? advOptions.strokeWeight : 2,
    map: map,
    zIndex: 100,
  });
  newPolyline.id = crypto.randomUUID();
  return newPolyline;
}

export function redrawLine(
  mapInstance: MapComponent,
  fLine: FieldLine,
  newStartPos: google.maps.LatLng,
  newEndPos: google.maps.LatLng,
  bDrawSplitDot = false,
  overrColour = true
) {
  if (fLine != undefined) {
    // remove dotSplit and line of the given line
    fLine.dotSplit!.setMap(null);
    fLine.line!.setMap(null);

    if (bDrawSplitDot) {
      fLine.dotSplit = drawMarker(
        mapInstance.map,
        DOTTYPE.Split,
        getPositionBetweenTwoPositions(
          newStartPos,
          fLine.dotEnd!.getPosition()!
        ),
        mapInstance
      );
    }
    fLine.line = drawLine(
      mapInstance.map,
      [newStartPos, newEndPos],
      overrColour ? drawLineRedrawColor : undefined
    );
  }
}
export function movePolyLine(
  polyLine: AdvancedPolyline,
  newStartPos: google.maps.LatLng,
  newEndPos: google.maps.LatLng
) {
  if (polyLine != undefined) {
    polyLine.setPath([newStartPos, newEndPos]);
  }
}
export function changeLineColor(fLine: FieldLine, overrColor = '') {
  if (fLine?.line != undefined) {
    fLine.line!.setOptions({
      strokeColor: overrColor != '' ? overrColor : drawLineDefaultColor,
    });
  }
}

export function changeVisibilityOfAllFieldPolyOverlays(
  mapInstance: MapComponent,
  bNewVisibility: boolean
) {
  for (const fieldPoly of mapInstance.allFieldPolys) {
    fieldPoly?.mapOverlays?.forEach((overlay) => {
      overlay.setMap(bNewVisibility ? mapInstance.map : null);
    });
    fieldPoly.setMap(null);
  }
}

// calculates the center/middle beween two LatLng objects
export function getPositionBetweenTwoPositions(
  latLngA: google.maps.LatLng,
  latLngB: google.maps.LatLng
) {
  const newDotSplitLat = (latLngA.lat() + latLngB.lat()) / 2;
  const newDotSplitLng = (latLngA.lng() + latLngB.lng()) / 2;
  return new google.maps.LatLng(newDotSplitLat, newDotSplitLng);
}
export function getStartPosOfLine(line: google.maps.Polyline) {
  return (line?.getPath() as google.maps.MVCArray).getArray()[0]!;
}

export function getEndPosOfLine(line: google.maps.Polyline) {
  return (line?.getPath() as google.maps.MVCArray).getArray()[1]!;
}

export function latLngToPointObj(latLng: google.maps.LatLng) {
  return {
    x: latLng.lng(),
    y: latLng.lat(),
  };
}

const DEGREE_RADIAN = Math.PI / 180;
const EQUATORIAL_RADIUS_CM = 637813700;
const DISTANCE_CM = DEGREE_RADIAN * EQUATORIAL_RADIUS_CM;
function getDistanceInCm(start: google.maps.LatLng, end: google.maps.LatLng) {
  const cos = Math.cos(((start.lat() + end.lat()) / 2) * DEGREE_RADIAN);

  const dx1 = DISTANCE_CM * start.lng() * cos;
  const dx2 = DISTANCE_CM * end.lng() * cos;
  const dx = dx2 - dx1;
  const dy = DISTANCE_CM * start.lat() - DISTANCE_CM * end.lat();
  return Math.round(Math.sqrt(dx * dx + dy * dy));
}

/*
function dragStartScenarioSplitFn(
  marker: AdvancedMarker,
  mapInstance: MapComponent
) {
  // cancels the usage of the magic wand to not accidentally draw whilst dragging a split-dot marker
  mapInstance.selectionHandler.onMouseUp(undefined); //TODO: check if OK for other drawing modes

  let pathStart: google.maps.LatLng | undefined = undefined;
  let pathEnd: google.maps.LatLng | undefined = undefined;
  for (const lines of mapInstance.scenarioData.lines)
    for (const fieldLine of lines) {
      if (fieldLine?.dotEnd?.uuid === marker.uuid) {
        console.log('enddot found');
        if (fieldLine.line) {
          pathEnd = (
            fieldLine.line.getPath() as google.maps.MVCArray
          ).getArray()[0];
          console.log(
            'enddot found >> pathstend set to',
            pathEnd?.lat(),
            pathEnd?.lng()
          );
        }
      }
      if (fieldLine?.dotSplit?.uuid === marker.uuid) {
        console.log('startdot found');
        if (fieldLine.line) {
          pathStart = (
            fieldLine.line.getPath() as google.maps.MVCArray
          ).getArray()[1];
          console.log(
            'startdot found >> pathstart set to',
            pathStart?.lat(),
            pathStart?.lng()
          );
        }
      }
    }

  if (pathStart !== undefined && pathEnd !== undefined) {
    // calc line between the start and end so that the dragged dot can't end up on one of the outer dots
    let newLatLngStart = getPositionBetweenTwoPositions(pathStart, pathEnd);
    do {
      newLatLngStart = getPositionBetweenTwoPositions(
        pathStart,
        newLatLngStart
      );
    } while (getDistanceInCm(pathStart, newLatLngStart) > 200);
    //console.log('calculated pathStart until distance in cm was', getDistanceInCm(pathStart, newLatLngStart));

    let newLatLngEnd = getPositionBetweenTwoPositions(pathStart, pathEnd);
    do {
      newLatLngEnd = getPositionBetweenTwoPositions(newLatLngEnd, pathEnd);
    } while (getDistanceInCm(pathEnd, newLatLngEnd) > 200);
    //console.log('calculated pathEnd until distance in cm was', getDistanceInCm(pathEnd, newLatLngEnd));

    mapInstance.scenarioData.draggingLine = new google.maps.Polyline({
      clickable: false,
      path: [newLatLngStart, newLatLngEnd],
      geodesic: true,
      strokeColor: drawFieldPolygonDefaultColor,
      strokeOpacity: 0.8,
      strokeWeight: 1,
      map: mapInstance.map,
      zIndex: 1000,
    });
  //     mapInstance.scenarioData.snapToRoute = new SnapToRoute(
  //    mapInstance.map,
  //    marker,
  //    mapInstance.scenarioData.draggingLine,
  //    mapInstance.scenarioData
  //  );
    
  } else {
    console.error('dragging-path for splitDot could not be calculated');
  }
}
*/

export function drawMarker(
  map: google.maps.Map,
  type: DOTTYPE,
  position: google.maps.LatLng,
  eventHandler?: MarkerEventHandler,
  customImgURL: string | null = ''
) {
  //if no handler is set, don't make marker interactable
  const blockInteractivity = eventHandler == undefined;

  let url = 'assets/images/map/dot-inactive.svg';
  switch (type) {
    case DOTTYPE.Start:
      break;
    case DOTTYPE.Split:
      url = 'assets/images/map/dotSplit.svg';
      break;
    case DOTTYPE.End:
      break;
    case DOTTYPE.Remove:
      url = 'assets/images/map/dotRemove.svg';
      break;
    case DOTTYPE.ScenarioSplit:
      url = 'assets/images/map/dotScenarioSplit.svg';
      break;
    default:
      break;
  }
  if (customImgURL == null) {
    url = 'assets/images/map/placeholderDot.png';
  } else if (customImgURL != '') {
    url = 'assets/images/map/' + customImgURL;
  }

  const newMarkerUUID = crypto.randomUUID();
  const newMarker: AdvancedMarker = new google.maps.Marker({
    icon: {
      url,
      size: new google.maps.Size(17, 17),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(9, 9),
      scaledSize: new google.maps.Size(17, 17),
    },
    position,
    map: map,
    //title: newMarkerUUID,
    clickable: type !== DOTTYPE.End && !blockInteractivity,
    draggable: !blockInteractivity,
    zIndex: type == DOTTYPE.Split ? 20 : 10,
  });
  newMarker.uuid = newMarkerUUID;
  newMarker.DOTTYPE = type;
  newMarker.eventHandler = eventHandler;

  if (!blockInteractivity) {
    google.maps.event.addListener(newMarker, 'click', () =>
      eventHandler.onMarkerClick(newMarker)
    );
    google.maps.event.addListener(newMarker, 'dragstart', () =>
      eventHandler.onMarkerDragStart(newMarker)
    );
    google.maps.event.addListener(newMarker, 'dragend', () =>
      eventHandler.onMarkerDragEnd(newMarker)
    );
    google.maps.event.addListener(newMarker, 'drag', () =>
      eventHandler.onMarkerDrag(newMarker)
    );

    switch (type) {
      case DOTTYPE.Start:
      case DOTTYPE.End:
        google.maps.event.addListener(newMarker, 'mouseover', () =>
          eventHandler.onMarkerMouseOver(newMarker)
        );
        google.maps.event.addListener(newMarker, 'mouseout', () =>
          eventHandler.onMarkerMouseOut(newMarker)
        );
        break;

      case DOTTYPE.ScenarioSplit:
      case DOTTYPE.Remove:
      default:
    }
  }

  return newMarker;
}

export function drawFieldAndFillFieldShape(
  map: google.maps.Map,
  eventHandler: MarkerEventHandler,
  fieldShape: TempFieldData,
  field: Field
) {
  let hideLastEndDot = false; // will be set to true if the fieldShape is closed
  // not drawing the enddot if the field is closed
  if (field.polygon.coordinates[0]) {
    hideLastEndDot = isClosedFieldShapeCheckByCoords(
      field.polygon.coordinates[0]
    );
  }

  let polygonCoordinatesCounter = 0; // to make sure that the last marker is only not drawn if this is the main-polygon (and not an exclusionArea)
  let isExclusionArea = false; //first Polygon is Field boundary, therefore false, true afterwards
  let isBorderClosed = isClosedField(field);
  for (const coordinates of field.polygon.coordinates) {
    polygonCoordinatesCounter++;
    let lineArray: FieldLine[] = fieldShape.lines;
    if (isExclusionArea) {
      lineArray = [];
      fieldShape.exclusions.push({ lines: lineArray });
    }

    if (coordinates.length == 0) continue; // nothing in the array

    // because the behaviour is different for the second or later entries, the start-position of the line to add has to be evaluated differently
    // (starting from the second line-entry, the start is the end of the previous line, for the first line-entry it's the start-dot)
    let startPosLine: google.maps.LatLng = new google.maps.LatLng(
      coordinates[0][1],
      coordinates[0][0]
    );
    if (!isExclusionArea) {
      // just add the marker for the first line:
      fieldShape.dotStart = drawMarker(
        map,
        isBorderClosed ? DOTTYPE.End : DOTTYPE.Start,
        startPosLine,
        eventHandler
      );
    }

    for (let i = 1; i < coordinates.length; i++) {
      const curCoord = new google.maps.LatLng(
        coordinates[i][1],
        coordinates[i][0]
      );
      let newMarker: AdvancedMarker | undefined = undefined;
      if (
        i <
        coordinates.length -
          (polygonCoordinatesCounter == 1 && hideLastEndDot ? 1 : 0)
      ) {
        newMarker = drawMarker(map, DOTTYPE.End, curCoord, eventHandler); // as lat/lng are swapped: curCoord[0] = lng, curCoord[1] = lat
      } else {
        newMarker = fieldShape.dotStart;
      }

      const curLineObj = {
        // add this line
        id: crypto.randomUUID(),

        //add dotSplit
        dotSplit: drawMarker(
          map,
          DOTTYPE.Split,
          getPositionBetweenTwoPositions(
            startPosLine,
            newMarker!.getPosition()!
          ),
          eventHandler
        ),
        dotEnd: newMarker,

        // draw line
        line: drawLine(map, [startPosLine!, newMarker!.getPosition()!]),
      };

      lineArray.push(curLineObj);

      startPosLine = curCoord;
      //getLastFieldShapeLine(mapInstance);
      isExclusionArea = true;
    }
  }
}
