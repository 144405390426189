import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions } from '../auth/auth.actions';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { FieldActions } from './fields.actions';
import { FieldsService } from './fields.service';
import { Field, Scenario } from './field.model';
import { Store, select } from '@ngrx/store';
import { selectFields } from './fields.selector';
import { FieldState } from './fields.states';
import { getNewFieldObjects } from '../map/map-state-control';

//we request loading fields if we get a new Token
export const requestLoadFields$ = createEffect(
  () => {
    return inject(Actions).pipe(
      ofType(AuthActions.tokenReceived),
      switchMap((/*result*/) => [FieldActions.requestLoad()])
    );
  },
  { functional: true, dispatch: true }
);

export const requestResetFields$ = createEffect(
  () => {
    return inject(Actions).pipe(
      ofType(AuthActions.logout),
      switchMap((/*result*/) => [FieldActions.reset()])
    );
  },
  { functional: true, dispatch: true }
);

export const loadFields$ = createEffect(
  (
    fieldsService = inject(FieldsService),
    store = inject(Store<{ fieldState: FieldState }>)
  ) => {
    return inject(Actions).pipe(
      ofType(FieldActions.requestLoad),
      withLatestFrom(store.select('fields').pipe(select(selectFields))),
      exhaustMap(([action, fields]) => {
        return fieldsService.getFields().pipe(
          map((data) => {
            return FieldActions.fieldsReceived({
              allFields: data,
              newFields: getNewFieldObjects(fields, data),
            });
          }),
          catchError(() => of(FieldActions.errorLoadingFields()))
        );
      })
    );
  },
  { functional: true, dispatch: true }
);

export const addOrUpdateFields$ = createEffect(
  (fieldsService = inject(FieldsService)) => {
    return inject(Actions).pipe(
      ofType(FieldActions.createOrUpdateField),
      mergeMap((action: { field: Field; reload: boolean }) => {
        console.log(action);
        if (action.field.id === undefined) {
          return fieldsService.createField(action.field).pipe(
            map((field) =>
              FieldActions.fieldCreated({ field: field, reload: action.reload })
            ),
            catchError(() => of(FieldActions.errorLoadingFields()))
          );
        } else {
          return fieldsService.updateField(action.field.id, action.field).pipe(
            map((field) =>
              FieldActions.fieldCreated({ field: field, reload: action.reload })
            ),
            catchError(() => of(FieldActions.errorLoadingFields()))
          );
        }
      })
    );
  },
  { functional: true, dispatch: true }
);

export const addFields$ = createEffect(
  (fieldsService = inject(FieldsService)) => {
    return inject(Actions).pipe(
      ofType(FieldActions.createField),
      mergeMap((action: { field: Field; reload: boolean }) =>
        fieldsService.createField(action.field).pipe(
          map((field) =>
            FieldActions.fieldCreated({ field: field, reload: action.reload })
          ),
          catchError(() => of(FieldActions.errorLoadingFields()))
        )
      )
    );
  },
  { functional: true, dispatch: true }
);

export const onFieldCreated$ = createEffect(
  () => {
    return inject(Actions).pipe(
      ofType(FieldActions.fieldCreated),
      mergeMap((action) => {
        if (action.reload) {
          return [FieldActions.requestLoad()];
        }
        return [];
      })
    );
  },
  { functional: true, dispatch: true }
);

export const updateFields$ = createEffect(
  (fieldsService = inject(FieldsService)) => {
    return inject(Actions).pipe(
      ofType(FieldActions.updateField),
      exhaustMap(({ id, field }) =>
        fieldsService.updateField(id, field).pipe(
          map(
            (/*data*/) => FieldActions.requestLoad() //TODO: check if we an optimize FieldActions.fieldsReceived({fields: data})
          ),
          catchError(() => of(FieldActions.errorLoadingFields()))
        )
      )
    );
  },
  { functional: true, dispatch: true }
);

export const deleteFields$ = createEffect(
  (fieldsService = inject(FieldsService)) => {
    return inject(Actions).pipe(
      ofType(FieldActions.deleteField),
      exhaustMap(({ field }) =>
        fieldsService.deleteField(field).pipe(
          map(
            (/*data*/) => FieldActions.requestLoad() //TODO: check if we an optimize FieldActions.fieldsReceived({fields: data})
          ),
          catchError(() => of(FieldActions.errorLoadingFields()))
        )
      )
    );
  },
  { functional: true, dispatch: true }
);

export const addOrUpdateScenario$ = createEffect(
  (fieldsService = inject(FieldsService)) => {
    return inject(Actions).pipe(
      ofType(FieldActions.createOrUpdateScenario),
      mergeMap((action: { field: Field; scenario: Scenario }) => {
        if (action.scenario.id !== undefined) {
          return fieldsService
            .updateScenario(action.field, action.scenario)
            .pipe(
              map(() => FieldActions.requestLoad()),
              catchError(() => of(FieldActions.errorLoadingFields()))
            );
        } else {
          return fieldsService
            .createScenario(action.field, action.scenario)
            .pipe(
              map(() => FieldActions.requestLoad()),
              catchError(() => of(FieldActions.errorLoadingFields()))
            );
        }
      })
    );
  },
  { functional: true, dispatch: true }
);

export const addScenario$ = createEffect(
  (fieldsService = inject(FieldsService)) => {
    return inject(Actions).pipe(
      ofType(FieldActions.addScenario),
      exhaustMap(({ field, scenario }) =>
        fieldsService.createScenario(field, scenario).pipe(
          map(
            (/*data*/) => FieldActions.requestLoad() //TODO: check if we an optimize FieldActions.fieldsReceived({fields: data})
          ),
          catchError(() => of(FieldActions.errorLoadingFields()))
        )
      )
    );
  },
  { functional: true, dispatch: true }
);

export const updateScenario$ = createEffect(
  (fieldsService = inject(FieldsService)) => {
    return inject(Actions).pipe(
      ofType(FieldActions.updateScenario),
      exhaustMap(({ field, scenario }) =>
        fieldsService.updateScenario(field, scenario).pipe(
          map(
            (/*data*/) => FieldActions.requestLoad() //TODO: check if we an optimize FieldActions.fieldsReceived({fields: data})
          ),
          catchError(() => of(FieldActions.errorLoadingFields()))
        )
      )
    );
  },
  { functional: true, dispatch: true }
);

export const deleteScenario$ = createEffect(
  (fieldsService = inject(FieldsService)) => {
    return inject(Actions).pipe(
      ofType(FieldActions.deleteScenario),
      exhaustMap(({ field, scenario }) =>
        fieldsService.deleteScenario(field, scenario).pipe(
          map(
            (/*data*/) => FieldActions.requestLoad() //TODO: check if we an optimize FieldActions.fieldsReceived({fields: data})
          ),
          catchError(() => of(FieldActions.errorLoadingFields()))
        )
      )
    );
  },
  { functional: true, dispatch: true }
);
