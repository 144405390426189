import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ImportBase } from './import.service';
import { ImportField, ImportState } from './import.states';
import { Store, select } from '@ngrx/store';
import { lastValueFrom, Observable, Subscription, take } from 'rxjs';
import { ImportActions } from './import.actions';
import { importedFieldsSelector, isLoadedSelector } from './import.selector';
import { FieldState } from '../fields/fields.states';
import { FieldActions } from '../fields/fields.actions';
import { Field } from '../fields/field.model';
import { ImportIsoxml } from './importISOXML.service';
import { ImportShapefile } from './importSHAPEFILE.service';

@Component({
  selector: 'app-field-import',
  templateUrl: './field-import.component.html',
  styleUrls: ['./field-import.component.scss'],
})
export class FieldImportComponent implements OnDestroy {
  @Output() isDisplayed = new EventEmitter<boolean>();

  files: File[] = [];
  fieldsLoaded$: Observable<boolean>;
  fieldOptions$: Observable<ImportField[]>;

  importFieldSub: Subscription;

  simplifyBorders: boolean = true;

  selectAll: boolean = true;
  allFields: ImportField[] = [];

  toBeImportedFields: ImportField[] = [];
  hasImportStarted = false;
  isImportFinished = false;
  succesfulImported: number = 0;
  currentProgress: number = 0;
  hasError = false;

  defaultHandler = 'IsoXML';
  isoXMLTypes = 'application/x-zip-compressed, text/xml';
  shapeFileTypes = 'application/x-zip-compressed';
  acceptedFileTypes;

  constructor(
    public store: Store<{ imports: ImportState; fields: FieldState }>,
    private isoXMLLoader: ImportIsoxml,
    private shapeFileLoader: ImportShapefile
  ) {
    this.fieldsLoaded$ = this.store
      .select('imports')
      .pipe(select(isLoadedSelector));
    this.fieldOptions$ = this.store
      .select('imports')
      .pipe(select(importedFieldsSelector));
    this.setAcceptedFileTypes(this.defaultHandler);

    this.importFieldSub = this.store
      .select('imports')
      .pipe(select(importedFieldsSelector))
      .subscribe((fields) => {
        if (this.selectAll) {
          this.toBeImportedFields = [...fields];
        }
        this.allFields = [...fields]; //for selection / unselection of all fields

        this.succesfulImported = 0;
        this.toBeImportedFields.forEach((importField) => {
          var fieldUpdated = fields.find(
            (field) => field.field.id == importField.field.id
          );
          if (fieldUpdated) {
            importField = fieldUpdated!;
          }
          if (importField.isImported) {
            this.succesfulImported++;
          }
          if (importField.hasError) {
            this.hasError = true;
          }
          if (
            this.isImportFinished &&
            this.succesfulImported == this.toBeImportedFields.length
          ) {
            setTimeout(() => {
              this.onClose();
            }, 2000);
          }
        });

        console.log('toBeImportedFields', this.toBeImportedFields);
        this.currentProgress =
          (this.succesfulImported / this.toBeImportedFields.length) * 100;
        // console.log(this.currentProgress)
      });
  }

  async getCurrentHandler(): Promise<ImportBase> {
    let state = await lastValueFrom(
      this.store.pipe(select('imports'), take(1))
    );
    return state.importHandler;
  }

  async getCurrentFields(): Promise<Field[]> {
    let state = await lastValueFrom(this.store.pipe(select('fields'), take(1)));
    return state.fields;
  }

  onToggleImportHandler(event: any) {
    this.defaultHandler = event.value;
    this.setAcceptedFileTypes(event.value);
    let myHandler: ImportBase = this.isoXMLLoader;
    if (event.value == 'ShapeFile') {
      myHandler = this.shapeFileLoader;
    }
    this.store.dispatch(
      ImportActions.selectFileType({ importHandler: myHandler })
    );
  }

  setAcceptedFileTypes(fileType: string) {
    if (fileType == 'ShapeFile') {
      this.acceptedFileTypes = this.shapeFileTypes;
    } else {
      this.acceptedFileTypes = this.isoXMLTypes;
    }
  }

  removeFile(file: File) {
    console.log(this.files);
    let index = this.files.indexOf(file);
    this.files.splice(index, 1);
    console.log(this.files);
  }

  droppedFiles(allFiles: File[]): void {
    const filesAmount = allFiles.length;
    for (let i = 0; i < filesAmount; i++) {
      const file = allFiles[i];
      this.files.push(file);
    }
    console.log(this.files);
  }

  onFileSelect(event: any) {
    const primitiveFileList: FileList = event.target.files;
    for (let file = 0; file < primitiveFileList.length; file++) {
      this.files.push(primitiveFileList[file]);
    }
  }

  async onUpload() {
    if (this.files.length > 0) {
      this.store.dispatch(
        ImportActions.loadFiles({
          files: this.files,
          importHandler: await this.getCurrentHandler(),
          existingFields: await this.getCurrentFields(),
          simplify: this.simplifyBorders,
        })
      );
    } else {
      this.onReset();
    }
  }

  onUnselectAll() {
    if (this.selectAll) {
      this.toBeImportedFields = [];
    } else {
      this.toBeImportedFields = this.allFields;
    }
    this.selectAll = !this.selectAll;
  }

  onSimplification() {
    this.simplifyBorders = !this.simplifyBorders;
  }

  isFieldSupposedToBeImported(fieldOption: ImportField) {
    let index = this.toBeImportedFields.indexOf(fieldOption);
    return index != -1;
  }

  onFieldSelect(fieldToBeImported: ImportField) {
    let index = this.toBeImportedFields.indexOf(fieldToBeImported);
    if (index == -1) {
      this.toBeImportedFields.push(fieldToBeImported);
      if (this.toBeImportedFields.length == this.allFields.length) {
        this.selectAll = true;
      }
    } else {
      this.toBeImportedFields.splice(index, 1);
      this.selectAll = false;
    }
  }

  onImport() {
    if (this.toBeImportedFields) {
      this.hasImportStarted = true;
      this.toBeImportedFields.forEach((fieldToBeImported) => {
        this.store.dispatch(
          FieldActions.createField({
            field: fieldToBeImported.field,
            reload: false,
          })
        );
      });
      this.isImportFinished = true;
    } else {
      this.store.dispatch(
        ImportActions.errorLoadingFields({ error: 'empty import not possible' })
      );
    }
  }

  onCancel() {
    //TODO: should we really have the same behaviour?
    this.onClose();
  }

  onClose() {
    this.store.dispatch(FieldActions.requestLoad());
    this.store.dispatch(ImportActions.closeImportDialog());
  }

  onReset() {
    this.files = [];
    this.store.dispatch(ImportActions.resetFile());
  }

  ngOnDestroy(): void {
    this.importFieldSub.unsubscribe();
  }
}
