import { createReducer, on } from '@ngrx/store';
import { ProcessProgressActions } from './process-progress.actions';
import { initialProcessProgressState } from './process-progress.states';
import { DRAWINGSTATE, MAPMODE } from '../map/map-state-control';
import { MapActions } from '../map/map.actions';

export const processReducer = createReducer(
  initialProcessProgressState,

  on(ProcessProgressActions.takeNextStep, (state, { processLabel }) => ({
    ...state,
    processLabel: processLabel,
    drawingState: DRAWINGSTATE.FINISHED,
    stepCounter: state.stepCounter + 1,
  })),

  //todo: make generic for more possible steps
  on(ProcessProgressActions.takeStepBack, (state) => ({
    ...state,
    processLabel:
      state.processMode == MAPMODE.SCENARIOCREATION ? 'creating' : 'drawing',
    drawingState: DRAWINGSTATE.UNFINISHED,
    stepCounter: state.stepCounter - 1,
  })),

  on(ProcessProgressActions.resetProcess, () => ({
    processMode: MAPMODE.DEFAULT,
    processLabel: '',
    drawingState: DRAWINGSTATE.UNFINISHED,
    stepCounter: 0,
  })),

  on(ProcessProgressActions.cancelProcess, () => ({
    processMode: MAPMODE.DEFAULT,
    processLabel: '',
    drawingState: DRAWINGSTATE.UNFINISHED,
    stepCounter: 0,
  })),

  on(MapActions.startCreateField, (state) => ({
    ...state,
    processMode: MAPMODE.FIELDCREATION,
    processLabel: 'drawing',
  })),

  on(MapActions.startEditField, (state) => ({
    ...state,
    processMode: MAPMODE.FIELDEDITING,
    processLabel: 'drawing',
  })),

  on(MapActions.startCreateScenario, (state) => ({
    ...state,
    processMode: MAPMODE.SCENARIOCREATION,
    processLabel: 'creating',
  })),

  on(MapActions.startEditScenario, (state) => ({
    ...state,
    processMode: MAPMODE.SCENARIOCREATION,
    processLabel: 'creating',
  }))
);
