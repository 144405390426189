import { Component, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { AuthState } from '../auth/auth.states';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent {
  isLoggedIn:boolean = false;// TODO: this.authService.isAuthenticated();

  constructor(public translate: TranslateService, private authService: AuthService) {
  }

  onToLogin() {
    this.authService.login();
  }
}
