import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DIALOGTYPE } from './modal-dialog.component';

export const ModalDialogActions = createActionGroup({
  source: 'ModalDialog',
  events: {
    'open Dialog': props<{ modalType: DIALOGTYPE; modalProps: object }>(),
    'close Dialog': emptyProps(),
  },
});
