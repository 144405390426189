<div class="export-scenario-overlay-bg">
  <div class="export-scenario-overlay-container">
    <div class="export-header">
      <div class="export-headline">
        {{ "export.headline" | translate }}
      </div>
      <div class="close-btn">
        <img
          [src]="'../../assets/images/common/icon_close.svg'"
          alt="icon close"
          role="button"
          (click)="onClose()"
          class="cursor-pointer"
        />
      </div>
    </div>
    <div class="separator"></div>
    <div class="export-body">
      <!-- content -->
      <div class="export-scenario-list" *ngIf="scenarios.length != 0">
        <div
          class="scenario-list-entry"
          *ngFor="let scenario of scenarios; index as i"
        >
          <img
            class="scenario-marker"
            src="/assets/images/marker/icon_marker_scenario.svg"
            width="22px"
            height="22px"
            alt="scenario"
          />
          <div class="scenario-info">
            <label class="scenario-name" for="{{ scenario.id }}">
              {{ scenario.name }}</label
            >
            <label class="scenario-state" for="{{ scenario.id }}">
              {{ scenario.state }}</label
            >
          </div>
          <label class="export-checkbox">
            <input
              type="checkbox"
              id="i"
              name="{{ scenario.id }}"
              value="{{ scenario.id }}"
              [checked]="isScenarioSupposedToBeExported(scenario)"
              (change)="onScenarioSelect(scenario)"
            />
          </label>

          <br />
          <!-- <label class="export-success-text" *ngIf="scenario.isExported" for="{{scenario.id}}"> {{ 'export.message.exporting.success' | translate }}</label>
              <label class="export-error-text" *ngIf="scenario.hasError for="{{scenario.id}}"> {{ 'export.message.exporting.error' | translate }}</label> -->
        </div>
      </div>
      <!-- no content -->
      <div class="export-empty" *ngIf="this.scenarios.length == 0">
        <img
          src="\assets\images\common\error_triangle.svg"
          width="24px"
          height="24px"
          alt="error_empty"
        />
        <label class="export-error-text" style="width: 160px; height: 34px">{{
          "export.message.empty.label" | translate
        }}</label>
      </div>
      <!-- error -->
      <div *ngIf="!hasError$">
        <div class="export-scenario-text">
          <span class="export-error-text"
            >{{ "export.message.exporting.error" | translate }}
          </span>
        </div>
      </div>
    </div>

    <!-- TODO: exporting single scenarios -->
    <label
      class="export-error-text"
      style="margin-top: 20px"
      *ngIf="this.toBeExportedScenarios.length == 0"
    >
      {{ "export.message.exporting.error_none" | translate }}</label
    >

    <div class="export-footer">
      <div class="export-btns">
        <button
          class="btn-large btn-blue-primary"
          [disabled]="toBeExportedScenarios.length == 0"
          (click)="onExport()"
        >
          {{ "export.btn.confirm.label" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
