import { DatePipe } from '@angular/common';
import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { DOMHelper } from '../DOMHelper';
import { FieldsService } from '../fields/fields.service';
import { ExportActions } from './export.actions';

export const requestExportAllScenarios$ = createEffect(
  (fieldsService = inject(FieldsService)) => {
    return inject(Actions).pipe(
      ofType(ExportActions.exportAllScenarios),
      exhaustMap((scenarios) => {
        return fieldsService.exportAllScenarios().pipe(
          map((exportBlob: Blob) => {
            downloadExport(exportBlob);
            return ExportActions.successfullExport({ exported: exportBlob });
          }),
          catchError((error) =>
            of(ExportActions.errorExporting({ error: `Error: ${error}` }))
          )
        );
      })
    );
  },
  { functional: true, dispatch: true }
);

export const requestExportScenarios$ = createEffect(
  (fieldsService = inject(FieldsService)) => {
    return inject(Actions).pipe(
      ofType(ExportActions.exportScenarios),
      exhaustMap((actions) => {
        console.log(actions.scenarios);
        return fieldsService.exportScenarios(actions.scenarios).pipe(
          map((exportBlob: Blob) => {
            downloadExport(exportBlob);
            return ExportActions.successfullExport({ exported: exportBlob });
          }),
          catchError((error) =>
            of(ExportActions.errorExporting({ error: `Error: ${error}` }))
          )
        );
      })
    );
  },
  { functional: true, dispatch: true }
);

function downloadExport(blob: Blob) {
  const datePipe = new DatePipe(DOMHelper.getLocale());
  const timestamp = Date.now();
  const name = `AmaPlan_Scenarios_${datePipe.transform(
    timestamp,
    'yyyyMMdd'
  )}_${datePipe.transform(timestamp, 'HHmmss')}.zip`;
  DOMHelper.downloadFile(blob, name);
}
