<mat-toolbar color="primary" class="header-container nav-height">
  <div class="header">
    <div class="logo item headline-font no-drag">
      <img src="../../assets/images/main/logo.svg" alt="AMAZONEN-WERKE H. DREYER SE & Co. KG" class="no-drag" />
    </div>
    <span class="flexExpand"></span>
    <mat-form-field class="language-selector">
      <span class="language-selector-icon" matPrefix>
        <img src="/assets/images/common/icon_language.svg" alt="lang" class="language-selector-icon no-drag"/>
      </span>
      <mat-select #langSelect (selectionChange)="translate.use(langSelect.value)"
        placeholder="{{ 'locale.' + selectedLanguage + '.label' | translate }}"
        panelClass="language-selector-select">
        <mat-option *ngFor="let lang of translate.getLangs()" [value]="lang">
          {{ 'locale.' + lang + '.label' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="item">
      <app-user></app-user>
    </div>
  </div>
</mat-toolbar>