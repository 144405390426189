import { Pipe, PipeTransform } from '@angular/core';
import { Field } from '../fields/field.model'
/*
 * filter the field-list by checking the name for the searched text
 * text may be empty
 * Usage:
 *   value | fieldFilter:searchText
 * Example:
 *   {{ originalFieldsArr | fieldFilter:'test' }}
 *   returns all fields that include 'test' in their name (not case sensitive, blank spaces are ignored on both ends)
 *
 * Info: might be extended to search in different parts of the Field-object ("description", owner/creator etc.)
*/
@Pipe({name: 'fieldSort'})
export class FieldSortPipe implements PipeTransform {
  transform(fields: Field[] | null, desc = true): Field[] {
    if (!fields) return [];
    return fields.sort((field, nextField) => {
      return field.name.localeCompare(nextField.name) * (desc ?  1 : -1);
    });
  }
}
