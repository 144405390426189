import { Pipe, PipeTransform } from '@angular/core';
import { Field } from '../fields/field.model'
import {AreaFilter, FieldFilter, LocationFilter} from "../field-filter/field-filter.model";
import { computeDistanceBetween } from 'spherical-geometry-js'
/*
 * filter the field-list by checking the name for the searched text
 * text may be empty
 * Usage:
 *   value | fieldFilter:searchText
 * Example:
 *   {{ originalFieldsArr | fieldFilter:'test' }}
 *   returns all fields that include 'test' in their name (not case sensitive, blank spaces are ignored on both ends)
 *
 * Info: might be extended to search in different parts of the Field-object ("description", owner/creator etc.)
*/
@Pipe({name: 'fieldFilter'})
export class FieldFilterPipe implements PipeTransform {

  transform(fArr: Field[] | null, filter?: FieldFilter): Field[] {
    if (!fArr) return [];
    if(!filter) return fArr;
    return fArr.filter(field => {
      return FieldFilterPipe.filterSearchText(field, filter.searchText)
        && FieldFilterPipe.filterLocation(field, filter.location)
        && FieldFilterPipe.filterArea(field, filter.area)
    });
  }

  private static filterSearchText(field: Field, searchText: string) {
    return field.name.trim().toUpperCase().indexOf(searchText.trim().toUpperCase()) != -1;
  }
  private static filterLocation(field: Field, location?: LocationFilter) {
    if(location == null) {
      return true;
    }
    for(let polygon of field.polygon.coordinates) {
      for(let coordinate of polygon) {
        let distance = computeDistanceBetween(location.location, {longitude: coordinate[0], latitude: coordinate[1]});
        if(distance <= location.distance * 1000) {
          return true;
        }
      }
    }

    return false;
  }
  private static filterArea(field: Field, area: AreaFilter) {
    return field.area >= area.min && (area.max == -1 || field.area <= area.max);
  }
}
