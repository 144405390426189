import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServicesConfig } from './services.config';
import { Observable } from 'rxjs';
import { Profile } from './profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private getProfileUrl = ServicesConfig.profileServiceUrl;


  constructor(private http:HttpClient) { }

  getProfile() : Observable<Profile>{
    console.log('getProfile');
   
    return this.http.get<Profile>(this.getProfileUrl);
  }

}
