import { Field } from 'src/app/fields/field.model';
import { renameFieldDuplicates } from 'src/app/map/map-objects-management';

const coordinatesThreshold = 200;
let epsilon;

export function simplifyFields(fields: Field[]): Field[] {
  let simplifiedFields: Field[] = [];
  fields.forEach((field) => {
    // console.log("simplification of field ", field.name);
    let newPolygon = simplifyPolygon(field.polygon.coordinates);
    field.polygon.coordinates = newPolygon;
    simplifiedFields.push(field);
  });
  return simplifiedFields;
}

function simplifyPolygon(fieldCoordinates: any[]) {
  for (let index = 0; index < fieldCoordinates.length; index++) {
    let epsilon_exponent = -10; //the larger epsilon the smaller the simplification

    // console.log("fieldCoordinates[" + index + "] length before: ", fieldCoordinates[index].length);

    while (fieldCoordinates[index].length > coordinatesThreshold) {
      epsilon_exponent++;
      epsilon = Math.pow(10, epsilon_exponent);
      let RDP_result = algorithmRDP(fieldCoordinates[index], epsilon);
      fieldCoordinates[index] = RDP_result;
      //make sure polygon is complete and closed
      if (
        RDP_result[0][0] != RDP_result[RDP_result.length - 1][0] ||
        RDP_result[0][1] != RDP_result[RDP_result.length - 1][1]
      ) {
        fieldCoordinates[index].push(RDP_result[0]);
      }
      // console.log("fieldCoordinates[" + index + "] length after RDP algorithm, epsilon =", epsilon, fieldCoordinates[index].length);
    }
  }

  return fieldCoordinates;
}

function algorithmRDP(coordinates: any[], epsilon) {
  // RamerDouglasPeucker Algorithm
  // See https://en.wikipedia.org/wiki/Ramer%E2%80%93Douglas%E2%80%93Peucker_algorithm for the pseudo code
  let dmax = 0;
  let index = 0;
  let end = coordinates.length - 1;

  for (let i = 1; i < end; i++) {
    let d = getPerpendicularDistance(
      coordinates[i],
      coordinates[0],
      coordinates[end - 1]
    ); //last entry in coordinates is same as first!
    if (d > dmax) {
      // console.log(d)
      index = i;
      dmax = d;
    }
  }

  let resultList: any[] = [];
  // If max distance is greater than epsilon, recursively simplify
  if (dmax > epsilon) {
    // Recursive call
    let recResults1: any[] = algorithmRDP(
      coordinates.slice(0, index + 1),
      epsilon
    ); //slice-end index not included
    let recResults2: any[] = algorithmRDP(
      coordinates.slice(index, end + 1),
      epsilon
    );
    //coordinates[index] should not be contained twice
    resultList = recResults1
      .slice(0, recResults1.length - 1)
      .concat(recResults2);
  } else {
    resultList.push(coordinates[0]);
    resultList.push(coordinates[end - 1]);
  }
  return resultList;
}

function getPerpendicularDistance(point, lineStart, lineEnd) {
  // See https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line for the formula

  //point = [lng, lat] !
  let x2x1Diff = lineEnd[1] - lineStart[1];
  let y1y0Diff = lineStart[0] - point[0];
  let x1x0Diff = lineStart[1] - point[1];
  let y2y1Diff = lineEnd[0] - lineStart[0];

  let distance =
    Math.abs(x2x1Diff * y1y0Diff - x1x0Diff * y2y1Diff) /
    Math.sqrt(Math.pow(x2x1Diff, 2) + Math.pow(y2y1Diff, 2));
  return Math.abs(distance);
}

export function checkForDuplicates(
  loadedFields: Field[],
  existingFields: Field[]
) {
  let names: string[] = [];
  loadedFields.forEach((field) => {
    let nameIndex = 0;
    let newName = field.name;

    //update duplicates within importList
    while (names.indexOf(newName) != -1) {
      nameIndex++;
      newName = field.name + ' (' + nameIndex + ')';
    }

    //check with list in backend for duplicates
    let nameBackEnd = renameFieldDuplicates(newName, existingFields);
    if (newName != nameBackEnd) {
      //as field.name is already in backend it also should be in list
      names.push(field.name);
      newName = nameBackEnd;
    }
    names.push(newName);
    field.name = newName;
  });
  return loadedFields;
}
