<div>
  <h2>{{ "error.title" | translate }}</h2>
  <br />
  <p>{{ "error.details" | translate }}</p>
  <br />
  <button
    mat-flat-button
    color="primary"
    (click)="onToLogout()"
    [hidden]="isLoggedIn"
  >
    {{ "error.logout.label" | translate }}
  </button>
</div>
