<div class="field-details-container">
  <div class="header">
    <div class="headline">
      <span class="title">{{ "field.details.title.label" | translate }}</span>
      <span class="subtitle">{{ selectedField?.name }}</span>
    </div>
    <button class="back-button btn-orange-secondary" (click)="deselectField()">
      {{ "field.details.btn.back.label" | translate }}
    </button>
  </div>
  <div class="separator"></div>
  <div class="field-info">
    <div class="info-body">
      <div *ngIf="selectedField?.area" class="info-entry">
        {{
          (selectedField!.area | number : "1.2-2")!
            .replace(",", "")!
            .replace(".", ",")
        }}
        ha
      </div>
      <div class="info-entry">
        <div>{{ "field.details.status.label" | translate }}:</div>
        <div></div>
      </div>
      <div class="info-entry">
        <div>{{ "field.details.seeds.label" | translate }}:</div>
        <div></div>
      </div>
      <div class="info-entry">
        <div>{{ "field.details.fertilizer.label" | translate }}:</div>
        <div></div>
      </div>
      <div class="info-entry">
        <div>{{ "field.details.created.label" | translate }}:</div>
        <div *ngIf="selectedField?.createdAt">
          {{
            formatDate(selectedField!.createdAt!, "dd.MM.yyyy hh:mm", "en-US")
          }}
        </div>
      </div>
      <div *ngIf="selectedField?.modifiedAt" class="info-entry">
        <div>{{ "field.details.modified.label" | translate }}:</div>
        <div>
          {{
            formatDate(selectedField!.modifiedAt!, "dd.MM.yyyy hh:mm", "en-US")
          }}
        </div>
      </div>
    </div>
    <div class="info-edit">
      <img
        class="edit-icon"
        src="/assets/images/common/icon_pen.svg"
        alt="icon pen"
        (click)="editField()"
      />
    </div>
  </div>
  <div class="separator"></div>
  <div class="scenario-info-headline">
    {{ "field.details.scenarios.headline" | translate }}
  </div>
  <div class="scenario-info">
    <div class="scenario-list">
      <div
        *ngFor="let scenario of selectedField?.scenarios; index as idx"
        aria-hidden="true"
        class="scenario-entry-wrapper"
        (click)="selectScenario(scenario)"
      >
        <div class="scenario-entry">
          <div class="scenario-name">
            <div class="name">
              {{ scenario.name }}
            </div>
          </div>
          <!-- shows last modification/createdAt if never modified after that -->
          <div
            *ngIf="scenario?.createdAt && !scenario?.modifiedAt"
            class="modification-date"
          >
            {{ formatDate(scenario?.createdAt!, "dd.MM.yyyy", "en-US") }}
          </div>
          <!-- hh:mm-->
          <div *ngIf="scenario?.modifiedAt" class="modification-date">
            {{ formatDate(scenario?.modifiedAt!, "dd.MM.yyyy", "en-US") }}
          </div>
          <!-- hh:mm-->

          <mat-slide-toggle
            hideIcon="true"
            *ngIf="scenario?.state && scenario?.state != 'invalid'"
            [checked]="scenario?.state == 'valid'"
            (change)="onActivate($event, scenario)"
          >
          </mat-slide-toggle>
          <!-- <div *ngIf="scenario?.state" class="scenario-status-pill">
            {{ "field.details.scenarios.state." + scenario.state | translate }}
          </div> -->
        </div>
        <div
          [class]="{
                    'scenario-interactions': true,
                    'scenario-interactions-open': selectedScenario?.id === scenario.id,
                    'scenario-interactions-closed': selectedScenario?.id !== scenario.id,
                  }"
        >
          <!-- if the restrictions on when to show the icons change, it might make sense to show disabled versions instead -->
          <img
            class="scenario-edit"
            *ngIf="
              selectedScenario?.id === scenario.id &&
              scenario.state !== 'invalid'
            "
            src="/assets/images/common/icon_pen.svg"
            alt="icon pen"
            width="17"
            height="17"
            (click)="editScenario()"
          />
          <img
            class="scenario-delete"
            *ngIf="selectedScenario?.id === scenario.id"
            src="/assets/images/common/icon_trashcan.svg"
            alt="icon trashcan"
            width="12"
            height="22"
            (click)="deleteScenario()"
          />
        </div>
      </div>
      <div
        *ngIf="
          !selectedField?.scenarios ||
          (selectedField?.scenarios && selectedField!.scenarios!.length === 0)
        "
        class="no-scenarios"
      >
        {{ "field.details.scenarios.noScenarios" | translate }}
      </div>
    </div>
  </div>
</div>
