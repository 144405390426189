import { createSelector } from "@ngrx/store";
import { AuthState } from "./auth.states";
import { Profile } from "../services/profile";

export const isAuthenticatedSelector = createSelector(
  (state:AuthState) => state.isAuthenticated,
  (isAuthenticated:boolean) => isAuthenticated
);
export const isLoadingDone = createSelector(
  (state:AuthState) => !state.isLoading,
  (isLoadingDone:boolean) => isLoadingDone
);

export const profileSelector = createSelector(
  (state:AuthState) => state.profile,
  (profile:Profile|undefined) => profile
);

export const tokenSelector = createSelector(
  (state:AuthState) => state.parsedAccessToken,
  (parsedAccessToken:undefined|Object) => parsedAccessToken
);
