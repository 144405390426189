import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { FieldActions } from '../fields/fields.actions';
import { MapHelper } from './map-helper';
import { MapActions } from './map.actions';
import { MapState, initialMapState } from './map.states';
import { DRAWINGSTATE, MAPMODE, getNewFieldObjects } from './map-state-control';
import { createNewScenarioFromField } from './scenario/map-drawing-logic-scenario';

import { Patch, applyPatches, produce } from 'immer';
import { SelectionModeFieldHandler } from '../selection-modes/selection-mode-field';
import { SelectionModeNoneHandler } from '../selection-modes/selection-mode';
import { SelectionModeCombinationHandler } from '../selection-modes/selection-mode-combination';
import { ProcessProgressActions } from '../process-progress/process-progress.actions';

const mapReducer = createReducer(
  initialMapState,
  // type: type of map that is displayed (roadmap, satellite...)

  on(MapActions.changeTypeTo, (state: MapState, { newMapType }) => ({
    ...state,
    mapType: newMapType,
  })),
  on(MapActions.enableDrawing, (state: MapState) => ({
    ...state,
    drawingState: DRAWINGSTATE.UNFINISHED,
  })),

  on(MapActions.finishFieldEdit, (state: MapState) => ({
    ...state,
    drawingState: DRAWINGSTATE.FINALIZED,
  })),
  on(MapActions.scenarioEdited, (state: MapState, { scenario }) => ({
    ...state,
    scenario: scenario,
  })),
  on(MapActions.finishScenarioEdit, (state: MapState) => ({
    ...state,
    drawingState: DRAWINGSTATE.FINALIZED,
  })),

  on(MapActions.enableDefaultMapMode, (state: MapState) => ({
    ...state,
    mapMode: MAPMODE.DEFAULT,
    // field: undefined,
    scenario: undefined,
    drawingState: DRAWINGSTATE.UNFINISHED,
    selectionHandler: SelectionModeNoneHandler,
  })),

  on(ProcessProgressActions.cancelProcess, (state: MapState) => ({
    ...state,
    mapMode: MAPMODE.DEFAULT,
    drawingState: DRAWINGSTATE.UNFINISHED,
    selectionHandler: SelectionModeNoneHandler,
  })),

  on(ProcessProgressActions.finishProcess, (state: MapState) => ({
    ...state,
    mapMode: MAPMODE.DEFAULT,
    drawingState: DRAWINGSTATE.UNFINISHED,

    selectionHandler: SelectionModeNoneHandler,
  })),

  // mode: state of the map (not drawing, creating/editing a field or creating/editing a scenario)
  on(
    MapActions.changeSelectedSpreadingType,
    (state: MapState, { newSpreadingType }) => ({
      ...state,
      currentSpreadingType: newSpreadingType,
    })
  ),
  // mode: change current selection Mode
  on(
    MapActions.changeSelectionMode,
    (state: MapState, { newSelectionHandler }) => ({
      ...state,
      selectionHandler: newSelectionHandler,
    })
  ),
  on(MapActions.startCreateField, (state: MapState) => ({
    ...state,
    drawingState: DRAWINGSTATE.UNFINISHED,
    mapMode: MAPMODE.FIELDCREATION,
    field: undefined,
    scenario: undefined,
    selectionHandler: new SelectionModeFieldHandler(),
  })),
  on(MapActions.startEditField, (state: MapState) => ({
    ...state,
    drawingState: DRAWINGSTATE.FINISHED,
    mapMode: MAPMODE.FIELDEDITING,
    scenario: undefined,
    selectionHandler: new SelectionModeFieldHandler(),
  })),
  on(MapActions.fieldEdited, (state: MapState, { field }) => ({
    ...state,
    field: field,
  })),
  //set drawingstate to finished if FieldCreation. Otherwise polygon should be closed already
  on(MapActions.fieldPolygonClosed, (state: MapState, { field }) => ({
    ...state,
    drawingState:
      state.mapMode == MAPMODE.FIELDCREATION
        ? DRAWINGSTATE.FINISHED
        : state.drawingState,
    field: field,
  })),
  //set drawingstate to unfinished if FieldCreation and current drawingstate is finished. Otherwise polygon should be closed already
  on(MapActions.fieldPolygonReopened, (state: MapState) => ({
    ...state,
    drawingState:
      state.mapMode == MAPMODE.FIELDCREATION &&
      state.drawingState == DRAWINGSTATE.FINISHED
        ? DRAWINGSTATE.UNFINISHED
        : state.drawingState,
  })),
  on(MapActions.startCreateScenario, (state: MapState, { field }) => ({
    ...state,
    drawingState: DRAWINGSTATE.FINISHED,
    mapMode: MAPMODE.SCENARIOCREATION,
    scenario: createNewScenarioFromField(field),
    selectionHandler: new SelectionModeCombinationHandler(),
  })),
  on(MapActions.startEditScenario, (state: MapState, { field, scenario }) => ({
    ...state,
    drawingState: DRAWINGSTATE.FINISHED,
    mapMode: MAPMODE.SCENARIOCREATION,
    scenario: scenario,
    selectionHandler: new SelectionModeCombinationHandler(),
  })),
  on(MapActions.setBrowserLocation, (state: MapState, { browserLocation }) => ({
    ...state,
    browserLocation: browserLocation,
    mapCenter: MapHelper.getMapCenter([], browserLocation),
    zoomLevel: MapHelper.getZoomLevel([], browserLocation),
  })),
  //center: center of map according to center between loaded fields
  on(MapActions.changeCenterTo, (state: MapState, { newCenter }) => ({
    ...state,
    mapCenter: newCenter,
  })),
  //////////////// Field Actions

  //center: center of map according to center between loaded fields
  //zoom: zoom level to make sure all fields are within map bounds
  on(MapActions.showFields, (state: MapState, { fields }) => ({
    ...state,
    mapCenter: MapHelper.getMapCenter(fields, state.browserLocation),
    zoomLevel: MapHelper.getZoomLevel(fields, state.browserLocation),
  })),
  //getMapCenter and getZoomLevel need list as argument
  on(FieldActions.selectField, (state: MapState, action) => ({
    ...state,
    field: action.field,
    mapCenter: MapHelper.getMapCenter([action.field], state.browserLocation),
    zoomLevel: MapHelper.getZoomLevel([action.field], state.browserLocation),
  }))
);

interface Patches {
  patches: Patch[];
  inversePatches: Patch[];
}

interface History {
  undone: Patches[];
  undoable: Patches[];
}

const setStepsToMapState = function (mapState: MapState, history: History) {
  return produce(mapState, (draft) => {
    draft.undoableSteps = history.undoable.length;
    draft.undoneSteps = history.undone.length;
  });
};

const undoRedo = (reducer: ActionReducer<MapState, Action>) => {
  let history: History = {
    undone: [], //list for redo's
    undoable: [],
  };
  function resetHistoryObject(keepInitialState = false) {
    history = {
      undoable: keepInitialState
        ? [history.undoable[history.undoable.length - 1]]
        : [],
      undone: [],
    };
  }
  return (state, action) => {
    switch (action.type) {
      case MapActions.undo.type: {
        // check if patch is possible at all at this point in time
        if (!history.undoable[0]) return state;
        // patches from last action
        const lastPatches = history.undoable[0];
        // push patches over so they can be used for redo
        history = {
          undone: [lastPatches, ...history.undone],
          undoable: history.undoable.slice(1),
        };
        // derive state before last action by applying inverse patches
        const newState = applyPatches(state, lastPatches.inversePatches);
        return setStepsToMapState(newState, history); // set correct steps after applying patches
      }
      case MapActions.redo.type: {
        // check if patch is possible at all at this point in time
        if (!history.undone[0]) return state;
        // patches from last undone action
        const nextPatches = history.undone[0];
        // push patches over so they can be used for undo
        history = {
          undoable: [nextPatches, ...history.undoable],
          undone: history.undone.slice(1),
        };
        // derive state before undo (or after original action) by applying patches
        const newState = applyPatches(state, nextPatches.patches);
        return setStepsToMapState(newState, history); // set correct steps after applying patches
      }
      case MapActions.resetDrawing.type:
        // check if patch is possible at all at this point in time
        if (!history.undoable[history.undoable.length - 1]) return state;
        // patches from last action
        const lastPatches = history.undoable[history.undoable.length - 1];
        // push patches over so they can be used for redo
        // derive state before last action by applying inverse patches
        const newState = applyPatches(state, lastPatches.inversePatches);
        // don't reset it earlier as the right content is needed before
        resetHistoryObject(true);
        return setStepsToMapState(newState, history); // set correct steps after applying patches

      //Actions that will reset History (prior of execution)
      case MapActions.startCreateField.type:
      case MapActions.startEditField.type:
      case MapActions.startCreateScenario.type:
      case MapActions.startEditScenario.type: {
        resetHistoryObject();
        const newState = reducer(state, action);
        return setStepsToMapState(newState, history);
      }

      default: {
        const newState = reducer(state, action);
        const myState = produce(
          state,
          () => newState,
          (patches, inversePatches) => {
            // record patches through reducer callback
            history = {
              undoable: [{ patches, inversePatches }, ...history.undoable],
              undone: [], // clear redo stack
            };
          }
        );
        return setStepsToMapState(myState, history);
      }
    }
  };
};

export const historyMapReducer = undoRedo(mapReducer);
