import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { MapActions } from '../map/map.actions';
import { MAPTYPE } from '../map/map-state-control';

@Component({
  selector: 'app-map-type',
  templateUrl: './map-type.component.html',
  styleUrls: ['./map-type.component.scss']
})
export class MapTypeComponent {
  isOpen = false;
  curMapType: MAPTYPE;

  constructor(public translate: TranslateService, private store: Store) {
    this.curMapType = MAPTYPE.HYBRID;
  }

  changeMapTypeToRoadmap(): void {
    this.setMapType(MAPTYPE.ROADMAP);
  }
  changeMapTypeToSatellite(): void {
    this.setMapType(MAPTYPE.SATELLITE);
  }
  changeMapTypeToHybrid(): void {
    this.setMapType(MAPTYPE.HYBRID);
  }
  changeMapTypeToTerrain(): void {
    this.setMapType(MAPTYPE.TERRAIN);
  }

  setMapType(newMapType: MAPTYPE) {
    if (newMapType != this.curMapType) {
      this.curMapType = newMapType;
      this.store.dispatch(MapActions.changeTypeTo({ newMapType: newMapType }));
    }
  }
}
