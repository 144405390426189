<div [class]="{'container': true, 'inactive-container': !active}">
  <div class="invalid-overlay" *ngIf="invalid"></div>
  <div [class]="{'spreading-type-item': true, active, invalid}"
       (click)="clickItem.emit(id)" (keyup)="clickItem.emit(id)" tabindex="{id}">
    <div class="spreading-type-icon">
      <img [src]="'/assets/images/spreading-type/' + asset + (invalid ? '_inactive' : '') + '.svg'"
      [alt]="alt" class="no-drag"/>
    </div>
    <div class="spreading-type-border {{'spreading-type-' + asset}}"></div>
  </div>
</div>
