import { Field } from "./field.model";

export interface FieldState {
    fields: Field[],
    isLoading: boolean,

    // UI Aspects
    isFieldListOpen: boolean,
}

export const initialFieldState:FieldState = {
    fields: [],
    isLoading: true,

    isFieldListOpen: true
}