import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ProcessProgressActions = createActionGroup({
  source: 'ProcessProgress',
  events: {
    'request next step': emptyProps(), // requests effect for new process step
    'take next step': props<{ processLabel: string }>(), //updates actual state

    'request step back': emptyProps(),
    'take step back': emptyProps(),

    'finish process': props<{ assignedName: string }>(),

    'reset process': emptyProps(),
    'cancel process': emptyProps(),
  },
});
