<div class="field-filter-container">
  <!-- TODO: png to svg ! download icons new-->
  <div class="header">
    <div class="headline">
      <img
        class="filter-icon"
        src="/assets/images/common/icon_filter.svg"
        alt="icon filter"
      />
      <span class="headline">{{
        "field.filter.header.headline" | translate
      }}</span>
    </div>
    <button class="back-button btn-orange-secondary" (click)="onCloseFilter()">
      {{ "field.filter.btn.back.label" | translate }}
    </button>
  </div>
  <div class="separator"></div>
  <div class="section">
    <div class="headline">
      {{ "field.filter.location.headline" | translate }}
    </div>
    <div class="element">
      <app-search
        class="search"
        [searchTypes]="[SearchItemType.Place]"
        [searchInput]="filter.location?.name ?? ''"
        placeholder="field.filter.location.placeholder"
        (selectItem)="onSelectSearchItem($event)"
      ></app-search>
    </div>
    <div class="headline">
      {{ "field.filter.location.perimeter" | translate }}
    </div>
    <div class="element">
      <button
        class="perimeter-button btn-blue-primary"
        (click)="onPerimeterDecrease()"
        [disabled]="
          filter.location?.distance === FieldFilter.MinLocationPerimeter
        "
      >
        <img
          [src]="'/assets/images/common/icon_minus_bold.svg'"
          alt="decrease"
        />
      </button>
      <input
        disabled
        class="perimeter-input"
        [ngModel]="
          filter.location?.distance == undefined
            ? 'km'
            : filter.location?.distance + ' km'
        "
      />
      <button
        class="perimeter-button btn-blue-primary"
        (click)="onPerimeterIncrease()"
        [disabled]="
          filter.location?.distance === FieldFilter.MaxLocationPerimeter
        "
      >
        <img
          [src]="'/assets/images/common/icon_plus_bold.svg'"
          alt="increase"
        />
      </button>
    </div>
  </div>
  <div class="separator"></div>
  <div class="section">
    <div class="headline">
      {{ "field.filter.area.headline" | translate }}
      <span class="headline-sub"
        >{{ filter.area.min }} -
        {{
          filter.area.max > -1 ? filter.area.max : FieldFilter.MaxAreaRange
        }}
        ha</span
      >
    </div>
    <div class="element">
      <mat-slider
        [min]="FieldFilter.MinAreaRange"
        [max]="FieldFilter.MaxAreaRange"
        class="perimeter-slider"
      >
        <input matSliderStartThumb [(ngModel)]="filter.area.min" />
        <input
          matSliderEndThumb
          (input)="onSetPerimeterMaxValue($event)"
          [value]="
            filter.area.max > 0 ? filter.area.max : FieldFilter.MaxAreaRange
          "
        />
      </mat-slider>
    </div>
  </div>
  <div class="actions-container">
    <button
      class="btn-large btn-blue-secondary"
      style="width: 100%"
      (click)="onCloseFilter(true)"
    >
      {{ "field.filter.btn.apply.label" | translate }}
    </button>
    <button
      class="btn-large btn-blue-primary"
      style="width: 100%"
      (click)="onResetFilter()"
    >
      {{ "field.filter.btn.reset.label" | translate }}
    </button>
  </div>
</div>
