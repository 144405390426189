<div class="mode-wrapper">
  <div class="mode-container">
    <app-selection-mode
      *ngFor="let selectionMode of selectionModes"
      [id]="selectionMode.id"
      [active]="(selectionMode$ | async)?.mode === selectionMode.selectionMode"
      [asset]="selectionMode.asset"
      [alt]="selectionMode.id.toString()"
      (clickItem)="toggleSelectionMode(selectionMode)"
    >
    </app-selection-mode>
  </div>
</div>
