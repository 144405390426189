import { AfterViewInit, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { SearchItem, SearchItemGroup, SearchItemType } from "./search-item.model";
import { select, Store } from "@ngrx/store";
import { selectFields } from "../fields/fields.selector";
import { Field } from "../fields/field.model";
import { FieldState } from "../fields/fields.states";
import { Debounce } from "../../utilities/debounce";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { TranslateService } from "@ngx-translate/core";
import PlaceResult = google.maps.places.PlaceResult;
import { MapReferenceService } from "../map/map-reference.service";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements AfterViewInit {

  @Input() searchInput = '';
  @Input() placeholder = 'map.default.search.placeholder';

  @Input() forceLocation?= false;
  @Input() searchTypes?= [SearchItemType.Field, SearchItemType.Scenario, SearchItemType.Place];

  @Output() selectItem = new EventEmitter<SearchItem>();

  source: SearchItemGroup[] = [];
  localSearchItems: SearchItem[] = [];
  inputDebounce = new Debounce();

  constructor(public translate: TranslateService, private store: Store<{ fields: FieldState }>, private ngZone: NgZone, private mapReferenceService: MapReferenceService) {
  }

  ngAfterViewInit() {
    this.store.select('fields').pipe(select(selectFields)).subscribe((fields: Field[]) => {
      this.buildLocalSearchItems(fields);
    });
  }

  // builds fields/scenarios search item source
  buildLocalSearchItems(fields: Field[]) {
    let fieldsSearchItems: SearchItem[] = [];
    if (this.searchTypes?.includes(SearchItemType.Field)) {
      // create fields search items
      fieldsSearchItems = fields.map(field => new SearchItem(SearchItemType.Field, field.name, this.latLngFromField(field), field.id));
    }

    let scenarioSearchItems: SearchItem[] = [];
    if (this.searchTypes?.includes(SearchItemType.Scenario)) {
      // create scenarios search items
      scenarioSearchItems = fields.flatMap(field => field.scenarios?.map(scenario => ({ scenario, field })) ?? [])
        .map(scenario => new SearchItem(SearchItemType.Scenario, scenario.scenario.name ?? 'undefined scenario', this.latLngFromField(scenario.field), scenario.field.id))
    }

    // merge fields and scenarios
    this.localSearchItems = [...fieldsSearchItems, ...scenarioSearchItems];
  }

  buildPlacesSearchItems() {
    // if no map is available there no need to search for places or reset source without places
    if (!this.searchTypes?.includes(SearchItemType.Place) || !this.mapReferenceService.isMapAvailable) {
      return this.buildSource();
    }

    // if input is empty build source without places
    if (this.searchInput.length == 0) {
      this.buildSource();
    }

    this.mapReferenceService.executeOnMap(map => {
      const request = {
        query: this.searchInput,
        fields: ['name', 'formatted_address', 'geometry']
      };

      const service = new google.maps.places.PlacesService(map);
      service.findPlaceFromQuery(request, (results, status) => {
        const placesSearchItems = results?.map(place => new SearchItem(SearchItemType.Place, this.buildPlaceName(place), place.geometry?.location ?? new google.maps.LatLng(1, 1))) ?? [];
        this.buildSource(placesSearchItems);
      });
    });
  }

  buildPlaceName(place: PlaceResult): string {
    const defaultName = 'undefined place';
    if (place.name == null && place.formatted_address == null) {
      return defaultName;
    }
    if (place.name == null) {
      return place.formatted_address ?? defaultName;
    }
    if (place.formatted_address == null) {
      return place.name ?? defaultName;
    }
    if (place.formatted_address.includes(place.name)) {
      return place.formatted_address;
    }
    return `${place.formatted_address} (${place.name})`;
  }

  // builds source by filtering local search items (fields/scenarios) and adding optional unfiltered search items (places)
  buildSource(unfilteredSearchItems?: SearchItem[]) {
    // set empty source when no filter is set
    if (!this.canFilter()) {
      this.ngZone.run(() => this.source = []);
      return;
    }
    let searchItems = this.localSearchItems.filter(searchItem => searchItem.value.toLowerCase().includes(this.searchInput.toLowerCase()));
    if (unfilteredSearchItems != null) {
      searchItems = searchItems.concat(unfilteredSearchItems);
    }
    // source is not updated in time for some reason, therefore we need to use this
    this.ngZone.run(() => this.source = this.groupByType(searchItems, searchItem => this.translateSearchItemType(searchItem.type)));
  }
  canFilter(searchInput?: string): boolean {
    return (searchInput ?? this.searchInput).length > 2;
  }
  latLngFromField(field: Field) {
    return new google.maps.LatLng(field.polygon.coordinates[0][0][1], field.polygon.coordinates[0][0][0]);
  }


  onSearchInput(event: Event) {
    this.inputDebounce.run(() => {
      const element = <HTMLInputElement>event.target;
      if ((this.searchInput.length > 0 && element.value.length == 0) || this.canFilter(element.value)) {
        this.searchInput = element.value;
        this.buildPlacesSearchItems();
      }
    }, 500);
  }

  onSelectItem(event: MatAutocompleteSelectedEvent) {
    this.selectItem.emit(<SearchItem>event.option.value);
  }

  groupByType(searchItems: SearchItem[], keySelector: (searchItem: SearchItem) => string): SearchItemGroup[] {
    const keySearchItems = searchItems.map(searchItem => ({ key: keySelector(searchItem), item: searchItem }));
    const searchItemGroups: SearchItemGroup[] = [];
    for (const item of keySearchItems) {
      const index = searchItemGroups.findIndex(searchItemGroup => searchItemGroup.type === item.key);
      if (index > -1) {
        searchItemGroups[index].items.push(item.item);
      } else {
        searchItemGroups.push(new SearchItemGroup(item.key, [item.item]));
      }
    }
    return searchItemGroups;
  }

  private translateSearchItemType(type: SearchItemType) {
    switch (type) {
      case SearchItemType.Field:
        return "map.default.search.fields";
      case SearchItemType.Scenario:
        return "map.default.search.scenarios";
      case SearchItemType.Place:
        return "map.default.search.places";
      default:
        return "undefined"
    }
  }
}
