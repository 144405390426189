import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SearchItem, SearchItemType} from "../search/search-item.model";
import {FieldFilter, LocationFilter} from "./field-filter.model";
import {FieldFilterPipe} from "../field-list/field-filter.pipe";
import {MapReferenceService} from "../map/map-reference.service";
import {MapHelper} from "../map/map-helper";
import {Field} from "../fields/field.model";

@Component({
  selector: 'app-field-filter',
  templateUrl: './field-filter.component.html',
  styleUrls: ['./field-filter.component.scss']
})
export class FieldFilterComponent {

  private _filter = new FieldFilter();
  @Input() set filter(filter: FieldFilter) {
    this._filter = filter.clone()
  }
  get filter() {
    return this._filter;
  }

  @Output() filterChange = new EventEmitter<FieldFilter>();
  @Output() close = new EventEmitter<void>();
  constructor(private mapReferenceService : MapReferenceService) {
  }

  onPerimeterIncrease() {
    if(this.filter.location?.distance != undefined) {
      if(this.filter.location.distance >= FieldFilter.MaxLocationPerimeter){
        this.filter.location.distance = FieldFilter.MaxLocationPerimeter;
      } else {
        this.filter.location.distance++;
      }
    }
    this.mapFitBoundsFilterLocation();
  }
  onPerimeterDecrease() {
    if(this.filter.location?.distance != undefined) {
      if(this.filter.location.distance - 1 <= FieldFilter.MinLocationPerimeter){
        this.filter.location.distance = FieldFilter.MinLocationPerimeter;
      } else {
        this.filter.location.distance--;
      }
    }
    this.mapFitBoundsFilterLocation();
  }

  onCloseFilter(applyChanges = false) {
    if(applyChanges) {
      this.filterChange.emit(this.filter.clone());
    }
    this.close.emit();
  }
  onResetFilter() {
    this.filter = new FieldFilter();
    this.filterChange.emit(this.filter.clone());
  }
  onSelectSearchItem(searchItem: SearchItem){
    this.filter.location ??= new LocationFilter(searchItem.value, searchItem.location, 10);
    this.filter.location.location = searchItem.location;
    this.mapFitBoundsFilterLocation();
  }
  onSetPerimeterMaxValue(event: Event) {
    // we have to use the HTMLInputElement instead of ngModel/valueChange to get all value changes not just the final one.
    let value = Number.parseInt((<HTMLInputElement>event.target).value);
    if(value >= FieldFilter.MaxAreaRange) {
      value = -1;
    }
    this.filter.area.max = value;
  }

  private mapFitBoundsFilterLocation() {
    this.mapReferenceService.executeOnMap(map => {
      if(this.filter.location) {
        MapHelper.fitBoundsRadius(map, this.filter.location.location, this.filter.location.distance * 1000);
      }
    });
  }


  protected readonly SearchItemType = SearchItemType;
  protected readonly FieldFilter = FieldFilter;
}
