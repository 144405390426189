import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { MapState } from '../map/map.states';
import { MapActions } from '../map/map.actions';
import { statesHandler, MAPMODE, DRAWINGSTATE } from '../map/map-state-control';
import { Field } from '../fields/field.model';
import { ProcessProgressState } from './process-progress.states';
import { ProcessProgressActions } from './process-progress.actions';
import {
  selectProcessLabel,
  selectDrawingState,
  selectProcessMode,
} from './process-progress.selector';

@Component({
  selector: 'app-process-progress',
  templateUrl: './process-progress.component.html',
  styleUrls: ['./process-progress.component.scss'],
})
export class ProcessProgressComponent implements OnDestroy {
  @Input() selField: Field | undefined;

  // local mapping to access the types in the html for better readability of the checks
  lMAPMODES = MAPMODE;
  lDRAWINGSTATES = DRAWINGSTATE;

  stateSub: Subscription;
  curStates: statesHandler;

  curProcessStep = 0;
  totalProcessSteps = 2;

  label$: Observable<String>;
  drawingState$: Observable<DRAWINGSTATE>;
  processMode$: Observable<MAPMODE>;

  processSteps = [{ id: 0 }, { id: 1 }];

  constructor(
    public translate: TranslateService,
    private store: Store<{
      mapdata: MapState;
      process: ProcessProgressState;
    }>
  ) {
    this.label$ = this.store.select('process').pipe(select(selectProcessLabel));
    this.drawingState$ = this.store
      .select('process')
      .pipe(select(selectDrawingState));
    this.processMode$ = this.store
      .select('process')
      .pipe(select(selectProcessMode));

    this.curStates = {
      mapMode: MAPMODE.DEFAULT,
      drawingState: DRAWINGSTATE.UNFINISHED,
      nameValid: false,
    };

    this.stateSub = this.store.select('mapdata').subscribe((newData) => {
      this.curStates = {
        mapMode: newData.mapMode,
        drawingState: newData.drawingState,
        nameValid: newData.nameValid,
      };
    });
  }

  ngOnDestroy(): void {
    this.stateSub.unsubscribe();
  }

  backBtnClicked() {
    this.store.dispatch(MapActions.undo());
    this.store.dispatch(ProcessProgressActions.takeStepBack());
  }

  cancelBtnClicked() {
    this.store.dispatch(ProcessProgressActions.cancelProcess());
  }

  nextBtnClicked() {
    this.store.dispatch(ProcessProgressActions.requestNextStep());
  }

  saveBtnClicked() {
    // this.store.dispatch(
    //   ProcessProgressActions.finishProcess({ assignedName: '' })
    // );
  }
}
