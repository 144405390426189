import { createReducer, on } from '@ngrx/store';
import { FieldActions } from './fields.actions';
import { initialFieldState } from './fields.states';
import { Field, cloneField } from './field.model';
import { MapActions } from '../map/map.actions';
import { ProcessProgressActions } from '../process-progress/process-progress.actions';
import { ImportActions } from '../field-import/import.actions';
import { ExportActions } from '../scenario-export/export.actions';

export const fieldsReducer = createReducer(
  initialFieldState,

  on(FieldActions.fieldsReceived, (state, { allFields, newFields }) => ({
    isFieldListOpen: state.isFieldListOpen,
    isLoading: false,
    fields: allFields.map((field: Field) => {
      const oldSelected = state.fields.find(
        (oldField) => oldField.id == field.id
      )?.isSelected;
      const newField = cloneField(field);
      newField.isSelected = oldSelected != undefined ? oldSelected : false;
      return newField;
    }),
  })),

  on(FieldActions.reset, (state) => ({
    isFieldListOpen: state.isFieldListOpen,
    isLoading: false,
    fields: [],
  })),

  on(FieldActions.toggleFieldListMenu, (state) => ({
    ...state,
    isFieldListOpen: !state.isFieldListOpen,
  })),

  on(FieldActions.deleteField, (state, action) => ({
    ...state,
    fields: state.fields.filter((field: Field) => field.id !== action.field.id),
  })),

  on(FieldActions.deleteScenario, (state, action) => ({
    ...state,
    fields: state.fields.map((field: Field) => {
      const filteredScenarios = field.scenarios?.filter(
        (scenario) => scenario.id != action.scenario.id
      );
      const newField = cloneField(field);
      newField.scenarios = filteredScenarios;
      return newField;
    }),
  })),

  on(FieldActions.selectField, (state, action) => ({
    ...state,
    fields: state.fields.map((field: Field) => {
      const newField = cloneField(field);
      newField.isSelected =
        field.id === action.field.id ? action.select : false; // currently only working for one selected field, for multiselect, a "selection add field" action might be more useful
      return newField;
    }),
  })),

  //field/scenario creation or Editing toggle field list
  on(MapActions.startCreateField, (state) => ({
    ...state,
    isFieldListOpen: false,
  })),
  on(MapActions.startEditField, (state) => ({
    ...state,
    isFieldListOpen: false,
  })),
  on(MapActions.saveCurrentField, (state) => ({
    ...state,
    isFieldListOpen: true,
  })),

  on(MapActions.startCreateScenario, (state, { field }) => ({
    ...state,
    isFieldListOpen: false,
  })),
  on(MapActions.startEditScenario, (state, { field }) => ({
    ...state,
    isFieldListOpen: false,
  })),
  on(MapActions.saveCurrentScenario, (state) => ({
    ...state,
    isFieldListOpen: true,
  })),

  //import / export toggle field list
  on(ImportActions.openImportDialog, (state) => ({
    ...state,
    isFieldListOpen: false,
  })),
  on(ImportActions.closeImportDialog, (state) => ({
    ...state,
    isFieldListOpen: true,
  })),

  on(ExportActions.openExportDialog, (state) => ({
    ...state,
    isExportDialogOpen: false,
  })),

  on(ExportActions.closeExportDialog, (state) => ({
    ...state,
    isExportDialogOpen: true,
  })),

  //process progress toggle field list
  on(ProcessProgressActions.cancelProcess, (state) => ({
    ...state,
    isFieldListOpen: true,
  })),

  on(ProcessProgressActions.finishProcess, (state) => ({
    ...state,
    isFieldListOpen: true,
  }))
);
