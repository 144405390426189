import { Component, OnChanges, OnDestroy } from '@angular/core';
import { EventBusService } from '../services/eventbus';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Field, Scenario, cloneScenario } from '../fields/field.model';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { FieldState } from '../fields/fields.states';
import { FieldActions } from '../fields/fields.actions';

import {
  selectFields,
  selectCurSelectedFields,
} from '../fields/fields.selector';
import { MapActions } from '../map/map.actions';
import { MAPMODE } from '../map/map-state-control';
import { ProcessProgressActions } from '../process-progress/process-progress.actions';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-field-details',
  templateUrl: './field-details.component.html',
  styleUrls: ['./field-details.component.scss'],
})
export class FieldDetailsComponent implements OnChanges, OnDestroy {
  public fields$: Observable<Field[]>;
  public fieldsCurSelectedSub: Subscription;
  selectedField: Field | undefined;
  selectedScenario: Scenario | undefined;

  formatDate = formatDate;

  constructor(
    public translate: TranslateService,
    private store: Store<{ fields: FieldState }>,
    private eventBus: EventBusService
  ) {
    this.fields$ = this.store.select('fields').pipe(select(selectFields));

    this.fieldsCurSelectedSub = this.store
      .select('fields')
      .pipe(select(selectCurSelectedFields))
      .subscribe((newSelectedFields: Field[]) => {
        if (
          newSelectedFields.length > 0 &&
          !newSelectedFields[0].equals(this.selectedField)
        ) {
          // current check for only one selected field at a time
          this.selectedField = newSelectedFields[0];
        }
      });
  }

  ngOnChanges(): void {
    this.store
      .select('fields')
      .pipe(select(selectFields))
      .subscribe((fields) => {
        console.log('new fields is', fields);
      });
  }

  ngOnDestroy(): void {
    this.fieldsCurSelectedSub.unsubscribe();
  }

  deselectField(): void {
    if (this.selectedField?.id) {
      this.store.dispatch(
        FieldActions.selectField({ field: this.selectedField, select: false })
      );
    }
  }

  onActivate(event: MatSlideToggleChange, scenario: Scenario): void {
    if (event.checked) {
      let newScenario = cloneScenario(scenario);
      newScenario.state = 'valid';
      this.store.dispatch(
        FieldActions.createOrUpdateScenario({
          field: this.selectedField!,
          scenario: newScenario,
        })
      );
    } else {
      event.source.checked = true;
    }
  }

  editField(): void {
    this.store.dispatch(
      MapActions.startEditField({ field: this.selectedField! })
    );
  }

  selectScenario(scenario) {
    if (this.selectedScenario == scenario) {
      this.selectedScenario = undefined;
    } else {
      this.selectedScenario = scenario;
    }
  }

  editScenario(): void {
    if (this.selectedScenario !== undefined) {
      this.store.dispatch(
        MapActions.startEditScenario({
          field: this.selectedField!,
          scenario: this.selectedScenario,
        })
      );
      // this.eventBus.publish({ name: 'edit scenario', data: this.selectedScenario.id });
    }
  }
  deleteScenario(): void {
    if (this.selectedScenario !== undefined) {
      this.store.dispatch(
        FieldActions.deleteScenario({
          field: this.selectedField!,
          scenario: this.selectedScenario,
        })
      );
      // this.eventBus.publish({ name: 'delete scenario', data: this.selectedScenario.id });
    }
  }
}
