import { createSelector } from "@ngrx/store";
import { FieldState } from "./fields.states";
import { Field } from "./field.model";



export const selectFields = createSelector(
    (state:FieldState) => state.fields,
    (fields:Field[]) => fields
);


export const selectFieldListOpen = createSelector(
    (state:FieldState) => state.isFieldListOpen,
    (isOpen:boolean) => isOpen
);

export const selectCurSelectedFields = createSelector(
  (state:FieldState) => state.fields,
  (fields:Field[]) => fields.filter(fieldEl => fieldEl.isSelected === true)
);
