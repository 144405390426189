import { Component, HostListener, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MapState } from '../map.states';
import { MapActions } from '../map.actions';

@Component({
  selector: 'app-map-history',
  templateUrl: './map-history.component.html',
  styleUrl: './map-history.component.scss',
})
export class MapHistoryComponent {
  public _undoableSteps: number;
  @Input() set undoableSteps(value: number | undefined) {
    if (value === undefined) return;
    this._undoableSteps = value;
    this.canUndo = value > 0;
  }
  get undoableSteps(): number {
    return this._undoableSteps;
  }

  public _undoneSteps: number;
  @Input() set undoneSteps(value: number | undefined) {
    if (value === undefined) return;
    this._undoneSteps = value;
    this.canRedo = value > 0;
  }
  get undoneSteps(): number {
    return this._undoneSteps;
  }

  canRedo: boolean = true;
  canUndo: boolean = true;

  constructor(
    public translate: TranslateService,
    public store: Store<{
      //      fields: FieldState;
      mapdata: MapState;
      //      imports: ImportState;
      //      exports: ExportState;
    }>
  ) {
    this._undoableSteps = 0;
    this._undoneSteps = 0;
  }

  onUndo() {
    if (!this.canUndo) return;
    this.store.dispatch(MapActions.undo());
  }
  // img functions are necessary for stopping the propagation of the click event to the parent, as it otherwise would fire twice (if used more often, should be added as a directive)
  onUndoImg(event) {
    if (!this.canUndo) return;
    this.store.dispatch(MapActions.undo());
    event.stopPropagation();
  }
  onRedo() {
    if (!this.canRedo) return;
    this.store.dispatch(MapActions.redo());
  }
  onRedoImg(event) {
    if (!this.canRedo) return;
    this.store.dispatch(MapActions.redo());
    event.stopPropagation();
  }
  onReset() {
    this.store.dispatch(MapActions.resetDrawing());
  }
  onResetImg(event) {
    this.store.dispatch(MapActions.resetDrawing());
    event.stopPropagation();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.key == 'z') {
      this.onUndo();
    }
    //TODO  this.clickUndoHistoryStep();
    if (($event.ctrlKey || $event.metaKey) && $event.key == 'y') {
      this.onRedo();
    }
    //TODO  this.clickRedoHistoryStep();
  }
}
