import { createSelector } from '@ngrx/store';
import { MapState } from './map.states';
import { MAPTYPE, MAPMODE, DRAWINGSTATE } from './map-state-control';
import { SelectedSpreadingType } from '../spreading-type-selector/spreading-type';
import { SelectionModeHandler } from '../selection-modes/selection-mode';
import { Field, Scenario } from '../fields/field.model';

export const selectMapType = createSelector(
  (state: MapState) => state.mapType,
  (mapType: MAPTYPE) => mapType
);

export const selectSelectionMode = createSelector(
  (state: MapState) => state.selectionHandler,
  (selectionHandler: SelectionModeHandler) => selectionHandler
);

export const selectCurMapMode = createSelector(
  (state: MapState) => state.mapMode,
  (mapMode: MAPMODE) => mapMode
);

export const selectMapCenter = createSelector(
  (state: MapState) => state.mapCenter,
  (mapCenter: object) => mapCenter
);

export const selectZoomLevel = createSelector(
  (state: MapState) => state.zoomLevel,
  (zoomLevel: number) => zoomLevel
);

export const selectCurDrawingState = createSelector(
  (state: MapState) => state.drawingState,
  (drawingState: DRAWINGSTATE) => drawingState
);

export const selectCurrentSpreadingType = createSelector(
  (state: MapState) => state.currentSpreadingType,
  (currentSpreadingType: SelectedSpreadingType) => currentSpreadingType
);

export const selectCurrentField = createSelector(
  (state: MapState) => state.field,
  (field: Field | undefined) => field
);

export const selectCurrentScenario = createSelector(
  (state: MapState) => state.scenario,
  (scenario: Scenario | undefined) => scenario
);
