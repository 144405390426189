<div class="field-list-container no-drag">
    <div class="field-main-page content-height">
        <div class="header">
            <div class="headline">
              <span class="title">{{ 'field.title.label' | translate }}</span>
            </div>
        </div>
        <div class="separator"></div>

        <div class="search field-list-width" *ngIf="searchOpen">
            <mat-form-field appearance="fill" class="field-list-content-width">
                <img class="search-icon" matPrefix src="../../assets/images/common/icon_search.svg" alt="search"/>
                <mat-label class="search-label">{{ 'sidebar.fields.search.placeholder' | translate }}</mat-label>
                <input matInput (input)="onInputSearchText($event)">
            </mat-form-field>
        </div>

        <div class="filter field-list-width">
            <button *ngIf="isFieldListOpen$ | async" class="btn-filter-sort" (click)="toggleSearch()">
                <img [src]="'/assets/images/field-list/icon_search_button.svg'" alt="icon search btn"/>
            </button>
            <button *ngIf="isFieldListOpen$ | async" class="btn-filter-sort" (click)="toggleFilter(true)">
                <img [src]="'/assets/images/field-list/' + (isFilterActive() ? 'icon_filter_active_button.svg' : 'icon_filter_button.svg')" alt="icon filter"/>
            </button>
            <button *ngIf="isFieldListOpen$ | async" class="btn-filter-sort" (click)="toggleSort()">
                <img [src]="'/assets/images/field-list/icon_sort_' + (sortFieldDesc ? 'desc' : 'asc') + '.svg'" alt="icon sort"/>
            </button>
        </div>

        <div class="field-list-wrapper">
            <div>
                <ul class="field-list field-list-width">
                    <li *ngFor="let field of fields$ | async | fieldFilter:filter | fieldSort:sortFieldDesc; index as idx; first as isFirst" class="list-entry"
                            (click)="onSelectField(field!)" (keyup)="onSelectField(field!)" role="button" aria-hidden="true">
                        <div class="entry-content field-list-content-width ">
                            <div class="field-name">{{ field.name }}</div>
                            <div class="field-size">{{ (field.area | number : '1.2-2')!.replace(',','')!.replace('.',',') }} ha</div>
                            <div class="scenario-icon">
                                <div *ngIf="field.scenarios != undefined && field.scenarios?.length != 0">
                                    <img *ngIf="hasValidScenario(field.scenarios)" [src]="'/assets/images/field-list/icon_scenario_valid.svg'" alt="icon scenario valid" />
                                    <img *ngIf="!hasValidScenario(field.scenarios)" [src]="'/assets/images/field-list/icon_scenario_invalid.svg'" alt="icon scenario invalid" />
                                </div>  
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="entries-container ">
                <div *ngIf="(fields$ | async | fieldFilter:filter).length === 1" class="counter">
                    {{ 'sidebar.fields.numberOfEntries.singular' | translate: { count: (fields$ | async | fieldFilter:filter).length } }}
                </div>
                <div *ngIf="(fields$ | async | fieldFilter:filter).length !== 1" class="counter">
                    {{ 'sidebar.fields.numberOfEntries.plural' | translate: { count: (fields$ | async | fieldFilter:filter).length } }}
                </div>
            </div>
        </div>
        <div class="actions-container field-list-width" *ngIf="isFieldListOpen$ | async" style="margin-bottom: 15px;">
            <button class="btn-large btn-blue-primary" (click)="clickStartCreateFieldProcess()">
                <div class="btn-with-icon">
                    <img [src]="'/assets/images/common/icon_add.svg'" alt="add new field"/>
                    <span style="margin:auto;">{{ 'field.add.field.label' | translate }} </span> 
                </div>
            </button>
        </div>
        <div class="actions-container field-list-width" *ngIf="isFieldListOpen$ | async">
            <button class="btn-small btn-blue-secondary" (click)="clickStartImportFieldProcess()">{{ 'field.import.label' | translate }}</button>
            <button class="btn-small btn-blue-secondary"  (click)="clickStartExportFieldProcess()">{{ 'field.export.label' | translate }}</button>
        </div>

        <div class="version-number">Version: {{appVersion}}</div>
    </div>
    <div class="field-sub-page content-height" *ngIf="areFieldDetailsVisible && (isFieldListOpen$ | async)" >
        <app-field-details></app-field-details>
    </div>
    <div class="field-sub-page content-height" *ngIf="!areFieldDetailsVisible && areFieldFilterVisible && (isFieldListOpen$ | async)" >
        <app-field-filter [(filter)]="filter" (close)="toggleFilter(false)"></app-field-filter>
    </div>
</div>
