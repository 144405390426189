<div class="process-progress-container">
  <div class="process-btns-left">
    <button
      *ngIf="(drawingState$ | async) === lDRAWINGSTATES.FINISHED"
      class="btn-small btn-blue-secondary process-btn"
      style="margin-left: 24px"
      (click)="backBtnClicked()"
    >
      {{ "map.default.progressbar.back.label" | translate }}
    </button>
    <button
      *ngIf="(drawingState$ | async) !== lDRAWINGSTATES.FINISHED"
      class="btn-small btn-blue-secondary process-btn"
      style="margin-left: 24px"
      (click)="cancelBtnClicked()"
    >
      {{ "map.default.progressbar.cancel.label" | translate }}
    </button>
  </div>

  <div class="progress">
    <div class="process-steps">
      <div class="process-step" *ngFor="let step of processSteps; index as idx">
        <div class="lines-and-dots">
          <div
            *ngIf="idx > 0"
            [class]="
              curProcessStep >= idx
                ? 'step-connector-line line-active'
                : 'step-connector-line line-inactive'
            "
          ></div>
          <div
            [class]="
              curProcessStep >= idx
                ? 'step-dot dot-active'
                : 'step-dot dot-inactive'
            "
          ></div>
        </div>
      </div>
    </div>
    <div class="step-label-container">
      <div class="step-label">
        {{ "map.default.progressbar.steps." + (label$ | async) | translate }}
        <span
          *ngIf="
            selField !== undefined &&
            (processMode$ | async) === lMAPMODES.FIELDEDITING
          "
          >&nbsp;({{ selField.name }})</span
        >
      </div>
    </div>
  </div>

  <div class="process-btns-right">
    <button
      *ngIf="(drawingState$ | async) === lDRAWINGSTATES.UNFINISHED"
      class="btn-small btn-blue-primary"
      style="margin-right: 24px"
      (click)="nextBtnClicked()"
      [disabled]="
        (curStates.mapMode === lMAPMODES.FIELDCREATION ||
          curStates.mapMode === lMAPMODES.FIELDEDITING) &&
        curStates.drawingState === lDRAWINGSTATES.UNFINISHED
      "
    >
      {{ "map.default.progressbar.next.label" | translate }}
    </button>

    <button
      *ngIf="(drawingState$ | async) === lDRAWINGSTATES.FINISHED"
      class="btn-small btn-blue-primary"
      style="margin-right: 24px"
      (click)="saveBtnClicked()"
    >
      {{ "map.default.progressbar.save.label" | translate }}
    </button>
  </div>
</div>
