import { Component, inject, ViewEncapsulation  } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavComponent {
  private breakpointObserver = inject(BreakpointObserver);
  public selectedLanguage;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


 
    /**
     * 
     * @param translate 
     */
    constructor(public translate: TranslateService) { 
      this.selectedLanguage = this.translate.currentLang
    }

  

}
