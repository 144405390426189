import { Field } from "../fields/field.model";
import { Injectable } from '@angular/core';
import { Observable} from "rxjs";

@Injectable()
export class ImportBase {

    loadFields(files: File[]): Observable<Field[]>  {
        console.log("importBase returns empty list.")
        return new Observable<Field[]>();
    }
}

