<!-- {{curStates.mapMode}} -->
<!-- {{curStates.drawingState}} -->
<div class="gmap-container">
  <div class="selection-rect"></div>
  <span
    id="tooltip-fieldshape-close"
    [class]="{
      'field-shape-close-tooltip': true,
      'field-shape-close-tooltip-visible': bIsTooltipFieldShapeCloseVisible,
      'field-shape-close-tooltip-hidden': !bIsTooltipFieldShapeCloseVisible
    }"
  >
    <img
      alt=""
      src="/assets/images/map/icon_close_shape.svg"
      width="11"
      height="11"
    />
  </span>

  <div
    #mapWrapper
    id="map"
    [class]="{
      gmap: true,
      'cursor-selection-magic-wand': selectionHandler.mode === 1,
      'cursor-selection-highlighter': selectionHandler.mode === 2,
      'cursor-selection-rectangle': selectionHandler.mode === 3
    }"
  ></div>

  <!-- default mode with toolbar -->
  <div
    *ngIf="(mapData$ | async)?.mapMode === lMAPMODES.DEFAULT"
    class="map-default-toolbar"
  >
    <app-search
      (selectItem)="selectSearchItem($event, false)"
      style="
        pointer-events: none;
        display: flex;
        justify-content: center;
        width: 90%;
      "
      id="app-search"
    ></app-search>
    <div class="toolbar-btns">
      <div class="btns-right-align">
        <img
          src="/assets/images/map/icon_zoom_fields.svg"
          height="50"
          width="50"
          alt="add field"
          role="button"
          aria-hidden="true"
          class="manual-zoom-btn toolbar-element btn-toolbar no-drag"
          (click)="clickShowAllFields()"
        />
      </div>
      <div class="btns-right-align" style="margin-top: 4px">
        <app-map-type></app-map-type>
      </div>
    </div>
  </div>

  <!-- dialog for asking the user whether or not to actually delete a field -->
  <div
    *ngIf="isDeleteFieldOrScenarioModalDisplayed"
    class="delete-field-overlay-bg"
  >
    <div class="delete-field-overlay-container">
      <div class="delete-field-question">
        <span *ngIf="isDeleteModeField">{{
          "field.delete.question" | translate
        }}</span>
        <span *ngIf="!isDeleteModeField">{{
          "field.delete.scenarioQuestion" | translate
        }}</span>
      </div>

      <div class="delete-field-btns">
        <div>
          <button
            *ngIf="isDeleteModeField"
            mat-flat-button
            class="toolbar-element btn-delete-field-confirm"
            (click)="deleteField()"
          >
            {{ "field.delete.confirm.label" | translate }}
          </button>
          <button
            *ngIf="!isDeleteModeField"
            mat-flat-button
            class="toolbar-element btn-delete-field-confirm"
            (click)="deleteScenario()"
          >
            {{ "field.delete.confirm.label" | translate }}
          </button>
        </div>
        <div>
          <button
            mat-flat-button
            class="toolbar-element btn-delete-field-cancel"
            (click)="cancelDeleteFieldOrScenario()"
          >
            {{ "field.delete.cancel.label" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- dialog for importing a field-->
  <div *ngIf="isImportModalOpen$ | async">
    <app-field-import></app-field-import>
  </div>

  <!-- dialog for exporting a field-->
  <div *ngIf="isExportModalOpen$ | async">
    <app-scenario-export></app-scenario-export>
  </div>

  <!-- assign-name -overlay for saving a new field/scenario -->
  <!-- <div
    *ngIf="(mapData$ | async)?.drawingState === lDRAWINGSTATES.FINALIZED"
    class="name-overlay-bg"
  >
    <div class="name-overlay-container">
      <div class="name-overlay">
        <div class="name-overlay-header">
          {{ "map.default.toolbar.save.name" | translate }}
        </div>
        <input
          style="margin-top: 16px"
          id="nameInput"
          class="search-input"
          [ngModel]="assignedName"
          (keyup.enter)="onConfirmNameInput(assignedName)"
          (ngModelChange)="onChangeFieldName($event)"
        />
        <div
          style="margin-top: 4px; display: flex; align-items: center"
          class="name-input-error-wrapper"
        >
          <div
            *ngIf="displayNameError"
            style="display: flex; align-items: center"
          >
            <img
              src="/assets/images/common/error_triangle.svg"
              alt="error symbol"
              height="24"
              width="24"
              style="margin-left: 8px"
            />
            <div *ngIf="nameTooShortError" style="margin-left: 12px">
              <div>
                {{ "map.default.toolbar.save.nameLengthError" | translate }}
              </div>
              <div>
                {{
                  "map.default.toolbar.save.nameLengthErrorResolution"
                    | translate
                }}
              </div>
            </div>
            <div *ngIf="nameAlreadyExistsError" style="margin-left: 12px">
              <div>
                {{
                  "map.default.toolbar.save.nameAlreadyExistsError" | translate
                }}
              </div>
              <div>
                {{
                  "map.default.toolbar.save.nameAlreadyExistsErrorResolution"
                    | translate
                }}
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <button
            style="margin-top: 8px; width: 49%"
            mat-flat-button
            (click)="clickBackCurrentProcess()"
            class="name-overlay-btn-back btn-blue-secondary"
          >
            {{ "map.default.progressbar.back.label" | translate }}
          </button>
          <button
            style="margin-top: 8px; width: 49%"
            mat-flat-button
            (click)="onConfirmNameInput(assignedName)"
            [class]="{
              'name-overlay-btn-save-active': true,
              'name-overlay-btn-save-inactive': false
            }"
          >
            {{ "map.default.toolbar.save.label" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div> -->

  <app-process-progress
    *ngIf="(mapData$ | async)?.mapMode !== lMAPMODES.DEFAULT"
    [selField]="selectedField"
  ></app-process-progress>

  <div class="process-search-input-container">
    <app-search
      [forceLocation]="true"
      (selectItem)="selectSearchItem($event, true)"
      *ngIf="
        (mapData$ | async)?.mapMode !== lMAPMODES.DEFAULT &&
        (mapData$ | async)?.mapMode !== lMAPMODES.SCENARIOCREATION
      "
      id="app-search"
      style="position: relative; z-index: 100; pointer-events: none"
    ></app-search>
  </div>

  <div
    class="scenario-drawing-container"
    *ngIf="(mapData$ | async)?.mapMode === lMAPMODES.SCENARIOCREATION"
  >
    <app-selection-modes></app-selection-modes>
    <app-spreading-type-selector></app-spreading-type-selector>
  </div>

  <app-map-history
    *ngIf="(mapData$ | async)?.mapMode !== lMAPMODES.DEFAULT"
    [undoableSteps]="(mapData$ | async)?.undoableSteps"
    [undoneSteps]="(mapData$ | async)?.undoneSteps"
  ></app-map-history>
</div>
