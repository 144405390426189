import { createSelector } from '@ngrx/store';
import { ModalDialogState } from './modal-dialog.states';
import { DIALOGTYPE } from './modal-dialog.component';

export const selectDialogType = createSelector(
  (state: ModalDialogState) => state.modalType,
  (modalType: DIALOGTYPE) => modalType
);

export const selectDialogProps = createSelector(
  (state: ModalDialogState) => state.modalProps,
  (modalProps: object) => modalProps
);
