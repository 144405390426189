import { Component, EventEmitter, Output } from '@angular/core';
import { FieldState } from '../fields/fields.states';
import { FieldActions } from '../fields/fields.actions';
import { ExportActions } from './export.actions';
import { ExportState } from './export.state';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Field, Scenario } from '../fields/field.model';
import { selectFields } from '../fields/fields.selector';
import { hasError, isExportedSelector } from './export.selector';

@Component({
  selector: 'app-scenario-export',
  templateUrl: './scenario-export.component.html',
  styleUrls: ['./scenario-export.component.scss'],
})
export class ScenarioExportComponent {
  @Output() isDisplayed = new EventEmitter<boolean>();

  fields$: Observable<Field[]>;
  hasError$: Observable<boolean>;
  exportSub: Subscription;
  scenarios: Scenario[] = [];
  toBeExportedScenarios: Scenario[] = [];

  constructor(
    public store: Store<{ exports: ExportState; fields: FieldState }>
  ) {
    this.fields$ = this.store.select('fields').pipe(select(selectFields));
    this.fields$.subscribe((fields) => {
      fields.forEach((field) => {
        let fieldScenarios = this.filterForValidScenarios(
          field.scenarios as []
        );
        this.scenarios = this.scenarios.concat(fieldScenarios);
        this.toBeExportedScenarios = [...this.scenarios];
      });
    });
    this.hasError$ = this.store.select('exports').pipe(select(hasError));
    this.exportSub = this.store
      .select('exports')
      .pipe(select(isExportedSelector))
      .subscribe((exported) => {
        if (exported) {
          setTimeout(() => {
            this.onClose();
          }, 2000);
        }
      });
  }

  filterForValidScenarios(allFieldScenarios: Scenario[]): Scenario[] {
    let validScenarios: Scenario[] = [];
    allFieldScenarios.forEach((scenario) => {
      if (scenario.state === 'valid') {
        validScenarios.push(scenario);
      }
    });
    return validScenarios;
  }

  isScenarioSupposedToBeExported(scenario: Scenario) {
    let index = this.toBeExportedScenarios.indexOf(scenario);
    return index != -1;
  }

  onScenarioSelect(scenario: Scenario) {
    let index = this.toBeExportedScenarios.indexOf(scenario);
    if (index == -1) {
      this.toBeExportedScenarios.push(scenario);
    } else {
      this.toBeExportedScenarios.splice(index, 1);
    }
  }

  onExport() {
    if (this.toBeExportedScenarios.length > 0) {
      this.store.dispatch(
        ExportActions.exportScenarios({ scenarios: this.toBeExportedScenarios })
      );
    }
  }

  onClose() {
    this.store.dispatch(ExportActions.closeExportDialog());
  }
}
