import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { Profile } from "../services/profile";

export const AuthActions = createActionGroup({
    source: 'Authorization',
    events: {
      'Token Received': props<{isAuthenticated: boolean, parsedAccessToken: undefined | Object}>(),

      'Logout': emptyProps(),

      'new Profile': props<{profile:Profile}>(),

      'Loading Done': emptyProps(),
      'Loading Error': props<{error: string}>(),
    },
});
