import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import {LoginResponse, OidcSecurityService} from "angular-auth-oidc-client";
import {AuthActions} from "./auth.actions";
import {Store} from "@ngrx/store";

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  /**
   *
   * @param oauthService
   */
  constructor(private oauthService: OidcSecurityService, private store: Store) {
  }

  public init() {
      this.oauthService
          .checkAuth()
          .subscribe((loginResponse: LoginResponse) => {
            this.isAuthenticatedSubject$.next(loginResponse.isAuthenticated);
            let parsedToken = undefined;
            if (loginResponse?.accessToken) {
              parsedToken = parseJwt(loginResponse.accessToken);
            }
            this.store.dispatch(AuthActions.tokenReceived({isAuthenticated: loginResponse.isAuthenticated, parsedAccessToken: parsedToken}));
            this.store.dispatch(AuthActions.loadingDone());

          });

  }

  public async loginAndWait() {
    this.oauthService
      .checkAuth()
      .subscribe((loginResponse: LoginResponse) => {

      });

  }


   /**
   *
   */
   public login() {
       this.oauthService.authorize();
   }

    /**
     *
     */
    public logout() {
        this.oauthService.logoff()
          .subscribe(() => {
            this.isAuthenticatedSubject$.next(false);
          });
    }
}
