import { createReducer, on } from '@ngrx/store';
import { ExportActions } from './export.actions';
import { initialExportState } from './export.state';

export const exportReducer = createReducer(
  initialExportState,

  on(ExportActions.openExportDialog, (state) => ({
    ...state,
    isExportDialogOpen: true,
  })),
  on(ExportActions.closeExportDialog, (state) => ({
    ...state,
    isExported: false,
    isExportDialogOpen: false,
  })),

  on(ExportActions.errorExporting, (state) => ({
    ...state,
    isExported: false,
    exported: undefined,
    error: true,
  })),

  on(ExportActions.successfullExport, (state) => ({
    ...state,
    isExported: true,
    exported: state.exported,
    error: false,
  }))
);
