<div class="map-history-toolbar">
  <div
    [class]="{
      'history-action': true,
      'history-action-activated': canUndo,
      'history-action-deactivated': !canUndo
    }"
    (click)="onUndo()"
    (keyup)="onUndo()"
    aria-hidden="true"
  >
    <span class="popup-tooltip">{{
      "map.default.historyActions.undo" | translate
    }}</span>
    <img
      [class]="{
        'history-action-icon-activated': canUndo,
        'history-action-icon-deactivated': !canUndo
      }"
      src="/assets/images/history/undo.svg"
      height="23"
      width="26"
      role="button"
      alt="undo history"
      (click)="onUndoImg($event)"
      (keyup)="onUndoImg($event)"
    />
  </div>

  <div
    [class]="{
      'history-action': true,
      'history-action-activated': canRedo,
      'history-action-deactivated': !canRedo
    }"
    (click)="onRedo()"
    (keyup)="onRedo()"
    aria-hidden="true"
  >
    <span class="popup-tooltip">{{
      "map.default.historyActions.redo" | translate
    }}</span>
    <img
      [class]="{
        'history-action-icon-activated': canRedo,
        'history-action-icon-deactivated': !canRedo
      }"
      src="/assets/images/history/redo.svg"
      height="23"
      width="26"
      role="button"
      alt="redo history"
      (click)="onRedoImg($event)"
      (keyup)="onRedoImg($event)"
    />
  </div>

  <div
    class="history-action history-action-activated"
    (click)="onReset()"
    (keyup)="onReset()"
    aria-hidden="true"
  >
    <span class="popup-tooltip">{{
      "map.default.historyActions.discard" | translate
    }}</span>
    <img
      class="history-action-icon no-drag"
      src="/assets/images/history/reset.svg"
      height="32"
      width="32"
      alt="reset drawing"
      (click)="onResetImg($event)"
      (keyup)="onResetImg($event)"
    />
  </div>
</div>
