import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { exhaustMap, switchMap, withLatestFrom } from 'rxjs';
import { ProcessProgressActions } from './process-progress.actions';
import { MapActions } from '../map/map.actions';
import { ProcessProgressState } from './process-progress.states';
import { Store, select } from '@ngrx/store';
import { MAPMODE } from '../map/map-state-control';
import {
  selectProcessMode,
  selectStepCounter,
} from './process-progress.selector';

export const requestFinishProcess$ = createEffect(
  (store = inject(Store<{ processState: ProcessProgressState }>)) => {
    return inject(Actions).pipe(
      ofType(ProcessProgressActions.finishProcess),
      withLatestFrom(store.select('process').pipe(select(selectProcessMode))),
      switchMap(([action, processMode]) => {
        switch (processMode) {
          case MAPMODE.SCENARIOCREATION:
            return [
              MapActions.saveCurrentScenario({
                assignedName: action.assignedName,
              }),
              ProcessProgressActions.resetProcess(),
            ];
          case MAPMODE.FIELDCREATION:
          case MAPMODE.FIELDEDITING:
            return [
              MapActions.saveCurrentField({
                assignedName: action.assignedName,
              }),
              ProcessProgressActions.resetProcess(),
            ];
          default:
            return [];
        }
      })
    );
  },
  { functional: true, dispatch: true }
);

export const requestCancelProcess$ = createEffect(
  () => {
    return inject(Actions).pipe(
      ofType(ProcessProgressActions.cancelProcess),
      exhaustMap(() => [
        MapActions.resetDrawing(),
        MapActions.enableDefaultMapMode(),
        ProcessProgressActions.resetProcess(),
      ])
    );
  },
  { functional: true, dispatch: true }
);

export const requestNextStep$ = createEffect(
  (store = inject(Store<{ processState: ProcessProgressState }>)) => {
    return inject(Actions).pipe(
      ofType(ProcessProgressActions.requestNextStep),
      withLatestFrom(
        store.select('process').pipe(select(selectProcessMode)),
        store.select('process').pipe(select(selectStepCounter))
      ),
      switchMap(([action, processMode, stepCounter]) => {
        if (stepCounter == 0) {
          //move from editing to drawingstate finished
          return handleDrawingStateFinished(processMode);
        }
        return []; //todo: add when more steps are involved
      })
    );
  },
  { functional: true, dispatch: true }
);

function handleDrawingStateFinished(processMode) {
  switch (processMode) {
    case MAPMODE.SCENARIOCREATION:
      return [
        MapActions.finishScenarioEdit(),
        ProcessProgressActions.takeNextStep({ processLabel: 'name' }),
      ];
    case MAPMODE.FIELDCREATION:
    case MAPMODE.FIELDEDITING:
      return [
        MapActions.finishFieldEdit(),
        ProcessProgressActions.takeNextStep({ processLabel: 'name' }),
      ];
    default:
      return [];
  }
}
