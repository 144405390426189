import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectionMode } from './selection-mode';

@Component({
  selector: 'app-selection-mode',
  templateUrl: './selection-mode.component.html',
  styleUrls: ['./selection-mode.component.scss'],
})
export class SelectionModeComponent {
  @Input() id: SelectionMode = SelectionMode.None;
  @Input() active = false;
  @Input() asset = '';
  @Input() alt = '';
  @Input() invalid = false;

  @Output() clickItem = new EventEmitter<SelectionMode>();
}
