import { convertNumberArrayToLatLng } from '../map/map-helper';
import {
  SelectedSpreadingType,
  getSpreadingTypeEnumForString,
  getSpreadingTypeStringForEnum,
} from '../spreading-type-selector/spreading-type';

export class Field {
  id: string | undefined;
  public name!: string;
  area!: number;
  type: string | undefined;
  polygon!: Polygon;

  isSelected: boolean | undefined;

  customerId: string | undefined;

  scenarios: Scenario[] | undefined;

  createdBy: string | undefined;
  createdAt: Date | undefined;
  modifiedBy: string | undefined;
  modifiedAt: Date | undefined;
  deletedBy: string | undefined;
  deletedAt: Date | undefined;
  newField: any;

  equals(comp: Field | undefined): boolean {
    if (!comp) return false;

    if (this.id != comp.id) return false;
    if (this.name != comp.name) return false;
    if (this.type != comp.type) return false;

    //TODO: check if we need to compare dates as well

    if (!this.polygon.equals(comp.polygon)) return false;

    if (this.scenarios?.length != comp.scenarios?.length) return false;
    if (this.scenarios && this.scenarios.length > 0 && comp.scenarios) {
      for (let i = 0; i < this.scenarios.length; i++) {
        try {
          if (this.scenarios[i] && !this.scenarios[i].equals(comp.scenarios[i]))
            return false;
        } catch (e) {
          console.warn('Issue in equals for ', this.scenarios, comp.scenarios);
          console.warn(e);
          return false;
        }
      }
    }
    return true;
  }

  getPaths4Polygon() {
    const paths: google.maps.LatLng[][] = [];
    for (const polygon of this.polygon.coordinates) {
      paths.push(convertNumberArrayToLatLng(polygon));
    }
    return paths;
  }
}

export function cloneField(oldField: Field): Field {
  const newField = new Field();
  newField.id = oldField.id;
  newField.name = oldField.name;
  newField.area = oldField.area;
  newField.type = oldField.type;
  newField.polygon = oldField.polygon;
  newField.isSelected = oldField.isSelected;
  newField.customerId = oldField.customerId;
  newField.scenarios = oldField.scenarios;
  newField.createdAt = oldField.createdAt;
  newField.createdBy = oldField.createdBy;
  newField.modifiedAt = oldField.modifiedAt;
  newField.modifiedBy = oldField.modifiedBy;
  newField.deletedAt = oldField.deletedAt;
  newField.deletedBy = oldField.deletedBy;
  return newField;
}

export function renameField(oldField: Field, newName: string): Field {
  const newField = new Field();
  newField.id = oldField.id;
  newField.name = newName ? newName : oldField.name;
  newField.area = oldField.area;
  newField.type = oldField.type;
  newField.polygon = oldField.polygon;
  return newField;
}

export class Polygon {
  type!: string;

  coordinates!: number[][][];

  constructor(coord: number[][][], pType = 'Polygon') {
    this.type = pType;
    this.coordinates = coord;
  }

  equals(comp: Polygon): boolean {
    if (this.type != comp.type) return false;
    if (this.coordinates.length != comp.coordinates.length) return false;
    for (let i = 0; i < this.coordinates.length; i++) {
      if (this.coordinates[i].length != comp.coordinates[i].length)
        return false;
      for (let j = 0; j < this.coordinates[i].length; j++) {
        if (this.coordinates[i][j][0] != comp.coordinates[i][j][0])
          return false;
        if (this.coordinates[i][j][1] != comp.coordinates[i][j][1])
          return false;
      }
    }
    return true;
  }
}

export class Scenario {
  id!: string;
  name: string | undefined;
  state!: string;
  border!: ScenarioBorder;

  createdBy: string | undefined;
  createdAt: Date | undefined;
  modifiedBy: string | undefined;
  modifiedAt: Date | undefined;
  deletedBy: string | undefined;
  deletedAt: Date | undefined;

  equals(compScenario: Scenario | undefined): boolean {
    if (!compScenario) return false;

    if (this.id != compScenario.id) return false;
    if (this.name != compScenario.name) return false;
    if (this.state != compScenario.state) return false;

    //TODO: check if we need to compare dates as well

    if (!this.border.equals(compScenario.border)) return false;

    return true;
  }
}

export function renameScenario(
  oldScenario: Scenario,
  newName: string
): Scenario {
  const newScenario = new Scenario();
  newScenario.id = oldScenario.id;
  newScenario.name = newName ? newName : oldScenario.name;
  newScenario.state = oldScenario.state;
  newScenario.border = oldScenario.border;
  return newScenario;
}

export function cloneScenario(oldScenario: Scenario): Scenario {
  const newScenario = new Scenario();
  newScenario.id = oldScenario.id;
  newScenario.name = oldScenario.name;
  newScenario.state = oldScenario.state;
  newScenario.border = oldScenario.border;

  return newScenario;
}
export class ScenarioBorder {
  outer!: ScenarioBorderRing;
  inners: ScenarioBorderRing[] | undefined;

  equals(comp: ScenarioBorder): boolean {
    if (!this.outer.equals(comp.outer)) return false;
    if (!this.inners && comp.inners) return false;
    if (this.inners && !comp.inners) return false;
    if (this.inners) {
      if (this.inners?.length != comp.inners?.length) return false;
      for (let i = 0; i < this.inners.length; i++) {
        if (!this.inners[i].equals(comp.inners[i])) return false;
      }
    }
    return true;
  }
}

export class ScenarioBorderRing {
  segments!: ScenarioBorderSegment[];

  constructor() {
    this.segments = [];
  }

  equals(comp: ScenarioBorderRing): boolean {
    if (this.segments.length != comp.segments.length) return false;

    for (let i = 0; i < this.segments.length; i++) {
      if (!this.segments[i].equals(comp.segments[i])) return false;
    }
    return true;
  }
}

export class ScenarioBorderSegment {
  coordinates!: number[][];
  outfacing!: string; //currently sent this way from Backend
  infacing!: string; //currently sent this way from Backend

  getSpreadingType(): SelectedSpreadingType {
    return new SelectedSpreadingType(
      getSpreadingTypeEnumForString(this.outfacing),
      getSpreadingTypeEnumForString(this.infacing)
    );
  }

  setSpreadingType(spreadingType: SelectedSpreadingType) {
    this.outfacing = getSpreadingTypeStringForEnum(spreadingType.outfacing);
    this.infacing = getSpreadingTypeStringForEnum(spreadingType.infacing);
  }

  equals(comp: ScenarioBorderSegment): boolean {
    if (this.outfacing != comp.outfacing) return false;
    if (this.infacing != comp.infacing) return false;

    //check coordinates
    if (this.coordinates.length != comp.coordinates.length) return false;
    for (let i = 0; i < this.coordinates.length; i++) {
      if (this.coordinates[i].length != comp.coordinates[i].length)
        return false;
      for (let j = 0; j < this.coordinates[i].length; j++) {
        if (this.coordinates[i][j] != comp.coordinates[i][j]) return false;
      }
    }

    return true;
  }
}
