<div class="map-type-container">
    <img *ngIf="!isOpen" src="/assets/images/map/icon_layers_maptype.svg" height="50" width="50" alt="map type" role="button" aria-hidden="true" class="toolbar-element btn-toolbar button-maptype no-drag" (click)="isOpen = !isOpen"/>
    <img *ngIf="isOpen" src="/assets/images/map/icon_layers_maptype_active.svg" height="50" width="50" alt="map type active" role="button" aria-hidden="true" class="toolbar-element btn-toolbar button-maptype no-drag" (click)="isOpen = !isOpen"/>
    <div *ngIf="isOpen">
        <button mat-flat-button class="toolbar-element btn-overview btn-type-option" (click)="changeMapTypeToRoadmap()"
            [style]="(curMapType === 1) ? 'color: #ccc' : ''">{{ 'map.default.mapTypeSettings.roadmap.label' | translate}}
        </button>
    </div>
    <div *ngIf="isOpen">
        <button mat-flat-button class="toolbar-element btn-overview btn-type-option" (click)="changeMapTypeToSatellite()"
            [style]="(curMapType === 2) ? 'color: #ccc' : ''">{{ 'map.default.mapTypeSettings.satellite.label' | translate}}
        </button>
    </div>
    <div *ngIf="isOpen">
        <button mat-flat-button class="toolbar-element btn-overview btn-type-option" (click)="changeMapTypeToHybrid()"
            [style]="(curMapType === 3) ? 'color: #ccc' : ''">{{ 'map.default.mapTypeSettings.hybrid.label' | translate}}
        </button>
    </div>
    <div *ngIf="isOpen">
        <button mat-flat-button class="toolbar-element btn-overview btn-type-option" (click)="changeMapTypeToTerrain()"
            [style]="(curMapType === 4) ? 'color: #ccc' : ''">{{ 'map.default.mapTypeSettings.terrain.label' | translate}}
        </button>
    </div>
</div>