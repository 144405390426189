// Spreading Types
export const SpreadingTypeDefaultColor = '#222222';
export const NormalSpreadingColor = '#FFFFFF';
export const EdgeSpreadingColor = '#ffbf08';
export const BorderSpreadingColor = '#ff5c00';
export const DigSpreadingColor = '#930912';
export const InsideControlColor = '#CFCFCF';
export const PatchSpreadingWithSpreadingScreenColor = '#e29b55';
export const BorderSpreadingWithSpreadingScreenColor = '#e8e5a1';

//draw Line
export const drawLineDefaultColor = '#f2f2f2';
export const drawLineRedrawColor = '#777';
export const drawLinePossibleColor = '#FF6900';
export const drawLineTempClosingColor = '#8A8A8A';

//draw Field Polygon
export const drawFieldPolygonDefaultColor = '#fff';
export const drawFieldPolygonDefaultFillColor = '#cecece';

export const drawFieldPolygonSelectedColor = '#ff3b00';
export const drawFieldPolygonLowVisibilityColor = '#999';
export const drawFieldPolygonAdjustedPathColor = '#ff2222';

//draw Scenario
export const splitScenarioDefaultColor = '#bbb';

//Highlighter
export const trimmedHighlightedPathColor = '#555555';
export const liveHighlighterMarkerColor = '#229327';
export const liveHighlighterHoverlineColor = '#387D33';
export const scenarioHighlighterPolylineColor = '#FF0000';
