import { createSelector } from "@ngrx/store";
import { ImportBase } from "./import.service";
import { ImportField, ImportState } from "./import.states";

export const isLoadedSelector = createSelector(
    (state:ImportState) => state.isLoaded,
    (loaded:boolean) => loaded
);

export const isImportDialogOpenSelector = createSelector(
    (state:ImportState) => state.isImportDialogOpen,
    (isImportDialogOpen:boolean) => isImportDialogOpen
);

export const importedFieldsSelector = createSelector(
    (state:ImportState) => state.importedFields,
    (importedFields:ImportField[]) => importedFields
);

export const importHandlerSelector = createSelector(
    (state: ImportState) => state.importHandler,
    (importHandler: ImportBase) => importHandler
);
