import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Store, select } from '@ngrx/store';
import { AuthState } from './auth.states';
import {isAuthenticatedSelector, isLoadingDone, tokenSelector} from './auth.selector';
import {Helper} from "../Helper";

const accessPermission = "amaplan-access";// permission necessary to access the Amaplan. checks happen in permissions of the token (string+array)

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  public isAuthenticated = false;
  private isLoadingDone = false;
  private jwtToken: any = undefined;

  constructor(private store: Store<{ auth: AuthState }>, private router: Router, private authService: AuthService) {
    this.store.select('auth').pipe(select(isAuthenticatedSelector)).subscribe( v => this.isAuthenticated = v);
    this.store.select('auth').pipe(select(isLoadingDone)).subscribe( v => this.isLoadingDone = v);
    this.store.select('auth').pipe(select(tokenSelector)).subscribe( v => this.jwtToken = v);
  }

  private waitForLoadingDone() {
    return Helper.waitFor(() => this.isLoadingDone, 100);
  }

  private checkTokenForPermissions(): boolean {
    if (this.jwtToken && typeof this.jwtToken === 'object') {
      if (this.jwtToken?.permissions && (typeof this.jwtToken.permissions == "string" || Array.isArray(this.jwtToken.permissions))) {
        if (typeof this.jwtToken?.permissions == "string" && this.jwtToken.permissions === accessPermission) {
          return true;
        } else if(Array.isArray(this.jwtToken.permissions) && this.jwtToken.permissions.includes(accessPermission)){
          return true;
        }
      }
    }
    return false;
  }

  /**
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.waitForLoadingDone().then(() => {
      if(this.isAuthenticated) {
        // check if the necessary permissions are present
        if (this.checkTokenForPermissions()) {
          return true;
        }
        this.router.navigate(['error']);
        return false;
      }
      this.router.navigate(['welcome']);
      return false;
    });
  }

}
