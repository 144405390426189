import {
  computeDistanceBetween,
  computeHeading,
  computeOffset,
} from 'spherical-geometry-js';
import {
  Field,
  Scenario,
  ScenarioBorder,
  ScenarioBorderRing,
  ScenarioBorderSegment,
} from '../../fields/field.model';
import {
  DefaultSpreadingType,
  SelectedSpreadingType,
  SpreadingType,
} from '../../spreading-type-selector/spreading-type';
import {
  AdvancedMarker,
  AdvancedPolyline,
  FieldLineData,
  drawLine,
  drawMarker,
  getEndPosOfLine,
  getStartPosOfLine,
} from '../map-drawing-logic';
import { DOTTYPE } from '../map-state-control';
import { MapComponent } from '../map.component';
import { createInsideControlLine } from './map-drawing-logic-insideControl';
import { TempScenarioData } from './temp-scenario-data';

// type for the lines of the scenario border/shape
export type ScenarioLine = {
  id: string;
  line?: AdvancedPolyline; // single polyline-element of the border
  dotEnd?: AdvancedMarker;
  lineData?: FieldLineData;
};

///////////////////////////
// Config
///////////////////////////

const splitGroupGradientThreshold = 0.75; // value as radius of slope angle
const splitGroupLengthThreshold = 100; // value in meters

const spreadingIconDefaultOffset = 27;
const spreadingIconLatInsideControlOffset = 43;

function splitGroupsForUsability(groups: ScenarioLine[][]): ScenarioLine[][] {
  const finalGroups: ScenarioLine[][] = [];

  for (const spreadingTypeGroup of groups) {
    let priorGradient = 0;
    let lastSplitIndex = 0;

    for (const section of spreadingTypeGroup) {
      const startDot = getStartPosOfLine(section.line!);
      const endDot = getEndPosOfLine(section.line!);
      const slopeAngle = Math.atan(
        (endDot.lng() - startDot.lng()) / (endDot.lat() - startDot.lat())
      );

      if (Math.abs(priorGradient - slopeAngle) > splitGroupGradientThreshold) {
        const newGroup = spreadingTypeGroup.slice(
          lastSplitIndex,
          spreadingTypeGroup.indexOf(section) + 1
        );
        const groupLength = computeDistanceBetween(
          getStartPosOfLine(newGroup[0]?.line!),
          endDot
        );

        if (groupLength > splitGroupLengthThreshold) {
          finalGroups.push(newGroup);
          lastSplitIndex = spreadingTypeGroup.indexOf(section) + 1;
        }
      }
      priorGradient = slopeAngle;
    }

    const lastGroup = spreadingTypeGroup.slice(
      lastSplitIndex,
      spreadingTypeGroup.length
    );
    finalGroups.push(lastGroup);
    // console.log("final amount of groups used for icon display: ", finalGroups.length);
  }
  return finalGroups;
}

export function removeAllScenarioDrawingElements(
  scenarioData: TempScenarioData
) {
  removeAllSpreadingTypeIcons(scenarioData);

  if (scenarioData?.bgPoly !== undefined) {
    scenarioData.bgPoly.setMap(null);
    scenarioData.bgPoly = undefined;
  }
  if (
    scenarioData?.bgMarker !== undefined &&
    scenarioData.bgMarker.length > 0
  ) {
    for (const marker of scenarioData.bgMarker) {
      marker.setMap(null);
    }
    scenarioData.bgMarker = [];
  }

  if (scenarioData.lines.length > 0) {
    for (const lines of scenarioData.lines)
      for (const fLine of lines) {
        if (fLine.dotEnd !== undefined) {
          fLine.dotEnd.setMap(null);
          fLine.dotEnd = undefined;
        }
        if (fLine.lineData?.spreadingTypeOverlay !== undefined) {
          fLine.lineData.spreadingTypeOverlay.setMap(null);
          fLine.lineData.spreadingTypeOverlay = undefined;
        }
        if (fLine.line !== undefined) {
          fLine.line.insideControlLine?.setMap(null); //remove inside control line if exists
          fLine.line.setMap(null);
          fLine.line = undefined;
        }
      }
    scenarioData.lines = [];
  }
}

export function redrawAllSpreadingTypeIcons(mapInstance: MapComponent) {
  //  setTimeout(() => {
  if (mapInstance?.scenarioData?.lines !== undefined) {
    for (const lines of mapInstance.scenarioData.lines) {
      const groups = getSpreadingLineGroups(lines);
      const finalGroups = splitGroupsForUsability(groups);
      for (const spreadingLineGroup of finalGroups) {
        drawSpreadingTypeIcon(mapInstance, spreadingLineGroup);
      }
    }
  }
  //  }, 10);
}

//TODO: should really be internal
export function removeAllSpreadingTypeIcons(scenarioData: TempScenarioData) {
  if (scenarioData?.lines !== undefined) {
    for (const lines of scenarioData.lines) {
      for (const sLine of lines) {
        if (sLine?.lineData?.spreadingTypeOverlay) {
          sLine.lineData.spreadingTypeOverlay.setMap(null);
          sLine.lineData.spreadingTypeOverlay = undefined;
        }
      }
    }
  }
}

function getLengthOfFieldLines(sLines: ScenarioLine[]): number {
  let borderLength = 0;
  for (const sLine of sLines) {
    const points = sLine.line?.getPath().getArray();
    if (points === undefined) continue;
    borderLength += computeDistanceBetween(points[0], points[1]);
  }
  return borderLength;
}

/**
 * create an overlay for provided field lines which are assumed to have the same spreading type
 * existend overlays are supposed to be removed upfront
 * @param mapInstance
 * @param fLine
 */
function drawSpreadingTypeIcon(
  mapInstance: MapComponent,
  sLines: ScenarioLine[]
) {
  //savetycheck to ensure we have all data we need
  if (
    sLines[0]?.lineData?.spreadingType?.outfacing === undefined ||
    sLines[0].line === undefined
  )
    return;

  const spreadingType = sLines[0]?.lineData?.spreadingType;
  const hasInsideControl =
    sLines[0]?.lineData?.spreadingType.infacing == SpreadingType.InsideControl;
  const borderLength = getLengthOfFieldLines(sLines);

  //setting expected position to half of the length
  let pathToGo = borderLength / 2;
  for (const fLine of sLines) {
    const points = fLine.line?.getPath().getArray();
    if (points === undefined) continue; // to keep code clean... should not happen
    const segmentLength = computeDistanceBetween(points[0], points[1]);
    if (segmentLength > pathToGo) {
      //icon should be on this segment
      const ratio = pathToGo / segmentLength;

      const newPos = new google.maps.LatLng(
        points[0].lat() + ratio * (points[1].lat() - points[0].lat()),
        points[0].lng() + ratio * (points[1].lng() - points[0].lng())
      );

      const heading = getHeading(fLine);
      const offsetMeters = getOffsetAccordingToZoom(
        mapInstance.map,
        hasInsideControl,
        heading
      );
      const markerPos = offsetByMeters(heading, newPos, offsetMeters);

      // let newPos = new google.maps.LatLng(points[0].lat() + ratio * (points[1].lat() - points[0].lat()), points[0].lng() + ratio * (points[1].lng() - points[0].lng()));
      fLine.lineData!.spreadingTypeOverlay =
        mapInstance.overlayFnObj!.createSpreadingTypeOverlayFn(
          mapInstance.map,
          markerPos,
          spreadingType
        );
      return;
    } else {
      pathToGo -= segmentLength;
    }
  }
}

function getHeading(sLine: ScenarioLine): number {
  return (
    computeHeading(
      getStartPosOfLine(sLine.line!),
      getEndPosOfLine(sLine.line!)
    ) - 90
  );
}

function offsetByMeters(heading, pos, meters): google.maps.LatLng {
  const newLatLng = computeOffset(pos, meters, heading);
  return new google.maps.LatLng(newLatLng.latitude, newLatLng.longitude);
}

function getOffsetAccordingToZoom(
  map,
  hasInsideControl,
  heading: number
): number {
  const metersPerPx =
    (156543.03392 * Math.cos((map.getCenter().lat() * Math.PI) / 180)) /
    Math.pow(2, map.getZoom());

  const offsetIconMeters =
    hasInsideControl && Math.abs(heading) < 25
      ? metersPerPx * spreadingIconLatInsideControlOffset
      : metersPerPx * spreadingIconDefaultOffset;

  return Math.round(offsetIconMeters);
}

export function isNewSpreadingTypeGroup(
  scenarioData: TempScenarioData,
  path: google.maps.LatLng[],
  spreadingType
): boolean {
  const startLine = scenarioData.getLineRefForPosition(path[0]);
  const endLine = scenarioData.getLineRefForPosition(path[0]);

  let differentGroupOrNewSpreadingType: boolean | undefined = undefined;
  //check the outer and inner border lines
  for (let idx = 0; idx < scenarioData.lines.length; idx++) {
    const borderGroups = getSpreadingLineGroups(scenarioData.lines[idx]);

    if (startLine == undefined || endLine == undefined) {
      console.error(
        'lines could not be found and therefor matched to a spreading type group'
      );
      break;
    }

    //if group contains start and end check if spreading type changes
    borderGroups.forEach((group) => {
      //there was no group found that contains start and end
      if (
        (group.includes(startLine!) && !group.includes(endLine!)) ||
        (!group.includes(startLine!) && group.includes(endLine!))
      ) {
        differentGroupOrNewSpreadingType = true;
        return;
      }
      if (group.includes(startLine!) && group.includes(endLine!)) {
        differentGroupOrNewSpreadingType =
          spreadingType.uiName != group[0].lineData?.spreadingType.uiName;
        return;
      }
    });
    //differentGroupOrNewSpreadingType only set when group includes both lines
    if (differentGroupOrNewSpreadingType != undefined)
      return differentGroupOrNewSpreadingType;
  }
  //fall back
  return true;
}

export function getSpreadingLineGroups(
  lines: ScenarioLine[]
): ScenarioLine[][] {
  const groups: ScenarioLine[][] = [];
  let currentGroup: ScenarioLine[] = [];
  for (const l of lines) {
    if (
      currentGroup.length == 0 ||
      !currentGroup[0].lineData?.spreadingType.equals(l.lineData?.spreadingType)
    ) {
      // type changed, we need to start a new group
      if (currentGroup.length > 0) {
        //we only "save" groups with content
        groups.push(currentGroup);
        currentGroup = [];
      }
    }
    currentGroup.push(l);
  }

  //check if last group need to get added to first one or kept seperate
  if (
    groups.length > 0 &&
    currentGroup[0].lineData?.spreadingType.equals(
      groups[0][0].lineData?.spreadingType
    )
  ) {
    //we have several groups and last and first group have the same type => we should merge them
    groups[0] = [...currentGroup, ...groups[0]];
  } else {
    groups.push(currentGroup);
  }
  return groups;
}

function checkIsDotFieldCorner(
  dotPos: google.maps.LatLng,
  borderPath: google.maps.LatLng[]
): boolean {
  for (const latLng of borderPath) {
    if (latLng.lat() == dotPos!.lat() && latLng.lng() == dotPos!.lng()) {
      return true;
    }
  }
  return false;
}

function isSpreadingModeChange(
  borderRing: ScenarioBorderRing,
  dotPos: google.maps.LatLng
): boolean {
  for (let idx = 0; idx < borderRing.segments.length; idx++) {
    let line = borderRing.segments[idx];
    let lineEnd = line.coordinates[1];

    if (dotPos.lng() == lineEnd[0] && dotPos.lat() == lineEnd[1]) {
      let nextIdx = idx == borderRing.segments.length - 1 ? 0 : idx + 1;
      let lineSpreaingType = line.getSpreadingType().uiName;
      let nextSpreadingType =
        borderRing.segments[nextIdx].getSpreadingType().uiName;

      return lineSpreaingType != nextSpreadingType;
    }
  }
  return false;
}

/**
 * add a scenario line to scenarioData in mapInstance and returns new length of scenario-Data line array
 * if applicable add inside control line
 *
 * @param mapInstance
 * @param startOfLine
 * @param endOfLine
 * @param spreadingTypeOutfacing
 * @param spreadingTypeInfacing
 * @returns new length of line array in mapInstance scenario Data
 */
function createFieldLineFromScenarioElement(
  mapInstance: MapComponent,
  startOfLine: google.maps.LatLng,
  endOfLine: google.maps.LatLng,
  spreadingType: SelectedSpreadingType,
  borderRing: ScenarioBorderRing,
  cornerPoints: google.maps.LatLng[]
): ScenarioLine {
  let isCorner = checkIsDotFieldCorner(endOfLine, cornerPoints);
  let isModeChanging = isSpreadingModeChange(borderRing, endOfLine);

  const scenarioLine: ScenarioLine = {
    id: crypto.randomUUID(),
    dotEnd: drawMarker(
      mapInstance.map,
      checkIsDotFieldCorner(endOfLine, cornerPoints)
        ? DOTTYPE.End
        : DOTTYPE.ScenarioSplit,
      endOfLine,
      undefined, //no event Handler
      isModeChanging ? 'dotScenarioSplit.svg' : null
    ),
    line: drawLine(
      mapInstance.map,
      [startOfLine!, endOfLine],
      spreadingType.color,
      {
        clickable: false,
        strokeWeight: 6,
      }
    ),
    lineData: {
      spreadingType: spreadingType,
    },
  };

  //TODO: check infacing handling
  if (spreadingType.infacing == SpreadingType.InsideControl) {
    scenarioLine.line!.insideControlLine = createInsideControlLine(
      mapInstance,
      scenarioLine,
      borderRing
    ); //new line is getting returned
  }

  return scenarioLine;
}

export function createNewScenarioFromField(field: Field): Scenario {
  const scenario = new Scenario();

  scenario.border = new ScenarioBorder();
  scenario.border.outer = new ScenarioBorderRing();
  scenario.border.outer.segments = [];
  scenario.border.inners = [];
  let segments = scenario.border.outer.segments;

  // use outer boundary
  let isExclusion = false;
  for (const coordinates of field.polygon.coordinates) {
    if (isExclusion) {
      segments = [];
      const ring = new ScenarioBorderRing();
      ring.segments = segments;
      scenario.border.inners.push(ring);
    }

    // create segments for all fieldLines in the scenarioData.lines
    for (let i = 0; i < coordinates.length - 1; i++) {
      const sbs = new ScenarioBorderSegment();
      sbs.coordinates = [coordinates[i], coordinates[i + 1]];
      // get the outfacing spreading type (name)
      sbs.setSpreadingType(DefaultSpreadingType);
      segments.push(sbs);
    }
    isExclusion = true;
  }

  return scenario;
}

function drawSegmentLine(
  mapInstance: MapComponent,
  segment: ScenarioBorderSegment,
  borderRing: ScenarioBorderRing,
  cornerPoints: google.maps.LatLng[]
): ScenarioLine {
  const startOfLine = new google.maps.LatLng(
    segment.coordinates[0][1]!,
    segment.coordinates[0][0]!
  );
  const endOfLine = new google.maps.LatLng(
    segment.coordinates[1][1]!,
    segment.coordinates[1][0]!
  );
  const newestLineObj = createFieldLineFromScenarioElement(
    mapInstance,
    startOfLine,
    endOfLine,
    segment.getSpreadingType(),
    borderRing,
    cornerPoints
  );
  return newestLineObj;
}

export function drawScenarioPolygon(
  mapInstance: MapComponent,
  scenarioObj: Scenario
) {
  mapInstance.scenarioData.lines = []; //reset scenarioData Lines!
  const paths = mapInstance.selectedField?.getPaths4Polygon()!;
  const cornerPoints = paths[0]!;
  // get all segments from the structure and draw them
  let lineStore: ScenarioLine[] = [];
  for (const element of scenarioObj!.border.outer.segments) {
    lineStore.push(
      drawSegmentLine(
        mapInstance,
        element,
        scenarioObj!.border.outer,
        cornerPoints
      )
    );
  }
  mapInstance.scenarioData.lines.push(lineStore);

  if (scenarioObj!.border.inners !== undefined) {
    //we might have exclusions

    for (
      let exclusionIndex = 0;
      exclusionIndex < scenarioObj!.border.inners.length;
      exclusionIndex++
    ) {
      const exclusion = scenarioObj!.border.inners[exclusionIndex];
      const exclusionPoints = paths[exclusionIndex + 1]!; //paths contains outer border as well!
      lineStore = [];
      for (const element of exclusion.segments) {
        lineStore.push(
          drawSegmentLine(mapInstance, element, exclusion, exclusionPoints)
        );
      }
      mapInstance.scenarioData.lines.push(lineStore);
    }
  }

  redrawAllSpreadingTypeIcons(mapInstance);
}

//TODO: remove
export function getLatLngArrFromLine(line: google.maps.Polyline) {
  return [
    line.getPath().getAt(0),
    line.getPath().getAt(line.getPath().getLength() - 1),
  ];
}
