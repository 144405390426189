import { Field, Scenario } from "../fields/field.model"

export interface ExportState {
    isExported: boolean,
    isExportDialogOpen: boolean,

    exported: Blob | undefined,

    error: boolean
}

export const initialExportState: ExportState = {
    isExported: false,
    isExportDialogOpen: false,
    exported: undefined,
    error: false
}


