<div class="container">
  <div class="container">
    <div
      [class]="{
        'spreading-type-btn': true,
        'spreading-type-btn-active': isActive(spreadingTypeInfo.type)
      }"
      *ngFor="let spreadingTypeInfo of outfacingSpreadingTypes"
    >
      <span class="popup-tooltip popup-tooltip-centered">{{
        "spreadingTypes." + spreadingTypeInfo.uiName | translate
      }}</span>
      <!-- {{ translationsObj?.translation1 ? translationsObj.translation1 : '' }} -->
      <app-spreading-type-selector-item
        [id]="spreadingTypeInfo.type"
        [active]="isActive(spreadingTypeInfo.type)"
        [asset]="assetName(spreadingTypeInfo.type)"
        [alt]="altName(spreadingTypeInfo.type)"
        (clickItem)="toggleSpreadingType($event)"
      ></app-spreading-type-selector-item>
    </div>
  </div>
  <div class="container">
    <div
      class="spreading-type-btn"
      *ngFor="let spreadingTypeInfo of infacingSpreadingTypes"
    >
      <span class="popup-tooltip popup-tooltip-left">{{
        "spreadingTypes." + spreadingTypeInfo.uiName | translate
      }}</span>
      <app-spreading-type-selector-item
        [id]="spreadingTypeInfo.type"
        [active]="isActive(spreadingTypeInfo.type)"
        [asset]="assetName(spreadingTypeInfo.type)"
        [alt]="altName(spreadingTypeInfo.type)"
        [invalid]="!isValid(spreadingTypeInfo.type)"
        (clickItem)="toggleSpreadingType($event)"
      ></app-spreading-type-selector-item>
    </div>
  </div>
</div>
