import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MapActions } from './map.actions';
import { inject } from '@angular/core';
import { exhaustMap, switchMap, withLatestFrom } from 'rxjs';
import { FieldActions } from '../fields/fields.actions';
import { MapState } from './map.states';
import { Store, select } from '@ngrx/store';
import { selectCurrentField, selectCurrentScenario } from './map.selector';
import { renameField, renameScenario } from '../fields/field.model';

export const requestSaveCurrentFieldProcess$ = createEffect(
  (store = inject(Store<{ mapState: MapState }>)) => {
    return inject(Actions).pipe(
      ofType(MapActions.saveCurrentField),
      withLatestFrom(store.select('mapdata').pipe(select(selectCurrentField))),
      switchMap(([action, field]) => {
        if (field) {
          let updatedField = renameField(field, action.assignedName); //renameField returns new Field object
          return [
            FieldActions.createOrUpdateField({
              field: updatedField,
              reload: true,
            }),
          ];
        }
        return [MapActions.enableDefaultMapMode()];
      })
    );
  },
  { functional: true, dispatch: true }
);

export const requestSaveCurrentScenarioProcess$ = createEffect(
  (store = inject(Store<{ mapState: MapState }>)) => {
    return inject(Actions).pipe(
      ofType(MapActions.saveCurrentScenario),
      withLatestFrom(
        store.select('mapdata').pipe(select(selectCurrentField)),
        store.select('mapdata').pipe(select(selectCurrentScenario))
      ),
      switchMap(([action, field, scenario]) => {
        if (field && scenario) {
          let updatedScenario = renameScenario(scenario, action.assignedName); //renameScenario returns new Scenario object
          return [
            FieldActions.createOrUpdateScenario({
              field,
              scenario: updatedScenario,
            }),
          ];
        }
        return [MapActions.enableDefaultMapMode()];
      })
    );
  },
  { functional: true, dispatch: true }
);

export const requestFocusReceivedFields$ = createEffect(
  () => {
    return inject(Actions).pipe(
      ofType(FieldActions.fieldsReceived),
      exhaustMap(({ allFields: allFields, newFields: newFields }) => {
        if (newFields.length == 1) {
          return [
            FieldActions.selectField({ field: newFields[0], select: true }),
          ];
        } else {
          if (
            newFields.length != 0 ||
            (newFields.length == 0 && allFields.length == 0)
          )
            return [MapActions.showFields({ fields: newFields })];
          return [];
        }
      })
    );
  },
  { functional: true, dispatch: true }
);
