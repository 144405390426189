type PointObj = {
  x: number;
  y: number;
};

export function doLineSegmentsIntersect(
  startLine1: PointObj,
  endLine1: PointObj,
  startLine2: PointObj,
  endLine2: PointObj
) {
  const r = subtractPoints(endLine1, startLine1);
  const s = subtractPoints(endLine2, startLine2);

  const uNumerator = crossProduct(subtractPoints(startLine2, startLine1), r);
  const denominator = crossProduct(r, s);

  if (uNumerator == 0 && denominator == 0) {
    // Do they touch? (Are any of the points equal?)
    if (
      equalPoints(startLine1, startLine2) ||
      equalPoints(startLine1, endLine2) ||
      equalPoints(endLine1, startLine2) ||
      equalPoints(endLine1, endLine2)
    ) {
      return true;
    }
    // Do they overlap? (Are all the point differences in either direction the same sign)
    return (
      !allEqual([
        startLine2.x - startLine1.x < 0,
        startLine2.x - endLine1.x < 0,
        endLine2.x - startLine1.x < 0,
        endLine2.x - endLine1.x < 0,
      ]) ||
      !allEqual([
        startLine2.y - startLine1.y < 0,
        startLine2.y - endLine1.y < 0,
        endLine2.y - startLine1.y < 0,
        endLine2.y - endLine1.y < 0,
      ])
    );
  }

  if (denominator == 0) {
    return false; // lines are paralell
  }

  const u = uNumerator / denominator;
  const t =
    crossProduct(subtractPoints(startLine2, startLine1), s) / denominator;

  return t >= 0 && t <= 1 && u >= 0 && u <= 1;
}

function crossProduct(point1: PointObj, point2: PointObj) {
  return point1.x * point2.y - point1.y * point2.x;
}

function subtractPoints(point1: PointObj, point2: PointObj) {
  const result = { x: 0, y: 0 };
  result.x = point1.x - point2.x;
  result.y = point1.y - point2.y;

  return result;
}

function equalPoints(point1: PointObj, point2: PointObj) {
  return point1.x == point2.x && point1.y == point2.y;
}

function allEqual(args: boolean[]) {
  const firstValue = args[0];
  for (let i = 1; i < args.length; i += 1) {
    if (args[i] != firstValue) {
      return false;
    }
  }
  return true;
}

// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// Determine the intersection point of two line segments
// Return first line point if the lines don't intersect
export function intersection(
  line1Start: google.maps.LatLng,
  line1End: google.maps.LatLng,
  line2Start: google.maps.LatLng,
  line2End: google.maps.LatLng
) {
  let x1 = line1Start.lng();
  let y1 = line1Start.lat();
  let x2 = line1End.lng();
  let y2 = line1End.lat();
  let x3 = line2Start.lng();
  let y3 = line2Start.lat();
  let x4 = line2End.lng();
  let y4 = line2End.lat();

  let denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
  let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;

  // Return a object with the x and y coordinates of the intersection
  let x = x1 + ua * (x2 - x1);
  let y = y1 + ua * (y2 - y1);

  return new google.maps.LatLng(y, x);
}

export function extendLine(
  currentPoint: google.maps.LatLng,
  slope: number,
  extensionValue: number
) {
  if (slope < 0) {
    extensionValue *= -1;
  }

  let newLongitude;
  let newLatitude;
  newLongitude = currentPoint.lng() + extensionValue;
  newLatitude =
    currentPoint.lat() + (newLongitude - currentPoint.lng()) * slope;

  return new google.maps.LatLng(newLatitude, newLongitude);
}
