import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { computeArea } from 'spherical-geometry-js';
import { Field, Polygon } from '../fields/field.model';
import { ImportBase } from './import.service';
import shp from 'shpjs';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';

@Injectable({ providedIn: 'root' })
export class ImportShapefile extends ImportBase {
  override loadFields(files: File[]): Observable<Field[]> {
    return new Observable<Field[]>((subscriber) => {
      files.forEach((file) => {
        if (file.type == 'application/x-zip-compressed') {
          return this.loadFieldsFromZip(file, subscriber);
        } else {
          return this.loadFieldsFromShapefile(files, subscriber); //TODO: not files but list of files depending on each other
        }
      });
    });
  }

  private loadFieldsFromZip(file: File, subscriber: Subscriber<Field[]>) {
    file.arrayBuffer().then((buff) => {
      shp.parseZip(buff).then((geojson) => {
        if (Array.isArray(geojson)) {
          geojson.forEach((gjson) => {
            this.processShapeFile(subscriber, gjson);
          });
        } else {
          this.processShapeFile(subscriber, geojson);
        }
      });
    });
  }

  private loadFieldsFromShapefile(
    files: File[],
    subscriber: Subscriber<Field[]>
  ) {}

  private processShapeFile(
    subscriber: Subscriber<Field[]>,
    geojson: shp.FeatureCollectionWithFilename
  ) {
    const fields: Field[] = [];

    geojson.features.forEach((partfield) => {
      let geoInfo = this.getGeoInformation(partfield.geometry);

      //create new Field based off partfield
      const newField = new Field();
      newField.name = this.findFieldName(partfield); //Schlagname maybe individuall?
      newField.area = geoInfo?.area || 0; // check if we want to have result in ha or in m²
      newField.polygon = new Polygon(geoInfo?.coordinates || []);

      newField.isSelected = false;
      fields.push(newField);
    });
    subscriber.next(fields);
  }

  getGeoInformation(geometry: Geometry) {
    let fieldCoordinates: any[] = [];
    let area: number = 0;

    //only working for polygons aka fields
    if (geometry.type == 'Polygon') {
      for (
        let areaIndex = 0;
        areaIndex < geometry.coordinates.length;
        areaIndex++
      ) {
        let areaCoordinates: any[] = [];
        let geoCoordinates: any = [];

        geometry.coordinates[areaIndex].forEach((point) => {
          areaCoordinates.push(point);
          let pNorth: number = point[0];
          let pEast: number = point[1];
          geoCoordinates.push({ lat: pNorth, lng: pEast });
        });
        fieldCoordinates.push(areaCoordinates);

        // check if we want to have result in ha or in m²
        if (areaIndex == 0) {
          area += computeArea(geoCoordinates) / 10000;
        } else {
          area -= computeArea(geoCoordinates) / 10000; //TODO: exclusions could overlapp!!!
        }
      }
    } else {
      return; //skip this partfield
    }
    return { coordinates: fieldCoordinates, area: area };
  }

  findFieldName(partfield: Feature<Geometry, GeoJsonProperties>) {
    //TODO: Find better solution that will work with more flexible
    let props = Object.keys(partfield.properties!) || [];
    if (props.includes('Schlagname')) {
      return partfield.properties!['Schlagname'];
    } else if (props.includes('name')) {
      return partfield.properties!['name'];
    }
    return 'unknown';
  }
}
