import { createReducer, on } from '@ngrx/store';
import { initialModalDialogState } from './modal-dialog.states';
import { ModalDialogActions } from './modal-dialog.actions';
import { MapActions } from '../map/map.actions';
import { DIALOGTYPE } from './modal-dialog.component';

export const dialogReducer = createReducer(
  initialModalDialogState,

  on(ModalDialogActions.openDialog, ({ modalType, modalProps }) => ({
    modalType: modalType,
    modalProps: modalProps,
  })),

  on(ModalDialogActions.closeDialog, () => ({
    modalType: DIALOGTYPE.DEFAULT,
    modalProps: {},
  })),

  //todo: move to another action
  on(MapActions.finishFieldEdit, () => ({
    modalType: DIALOGTYPE.ASSIGNNAME,
    modalProps: {},
  })),
  on(MapActions.finishScenarioEdit, () => ({
    modalType: DIALOGTYPE.ASSIGNNAME,
    modalProps: {},
  }))
);
