import { DRAWINGSTATE, MAPMODE } from '../map/map-state-control';

export interface ProcessProgressState {
  processMode: MAPMODE;
  processLabel: String;
  drawingState: DRAWINGSTATE;
  stepCounter;
}

export const initialProcessProgressState: ProcessProgressState = {
  processMode: MAPMODE.DEFAULT,
  processLabel: '',
  drawingState: DRAWINGSTATE.UNFINISHED,
  stepCounter: 0,
};
