import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthActions } from 'src/app/auth/auth.actions';
import { isAuthenticatedSelector, profileSelector } from 'src/app/auth/auth.selector';
import { AuthState } from 'src/app/auth/auth.states';
import { Profile } from 'src/app/services/profile';
import {TranslateService} from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {

  public isAuthenticated$: Observable<boolean>;

  public profile$ : Observable<Profile|undefined>;

  /**
     *
     */
  constructor(private store: Store<{ auth: AuthState }>, public translate: TranslateService, private authService: AuthService) {
    this.profile$ = store.select('auth').pipe(select(profileSelector));
    this.isAuthenticated$ = store.select('auth').pipe(select(isAuthenticatedSelector));
  }


  /**
   *
   */
  public logout() {
    this.store.dispatch(AuthActions.logout());
  }

    /**
   *
   */
    public login() {
      this.authService.login();
    }
}
