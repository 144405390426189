<div style="min-width: 100px; display: none">
  <div class="container">
    <div class="invalid-overlay" *ngIf="invalid"></div>
    <div
      [class]="{
        'selection-mode-item': true,
        active,
        inactive: !active,
        invalid
      }"
      (click)="clickItem.emit(id)"
      (keyup)="clickItem.emit(id)"
      tabindex="{id}"
    >
      <img
        [src]="'/assets/images/selection-mode/' + asset"
        [alt]="alt"
        width="67"
        height="67"
        class="no-drag"
      />
    </div>
  </div>
</div>
