class LocationFilter {
  name: string;
  location: google.maps.LatLng;
  distance: number;

  constructor(name: string, location: google.maps.LatLng, distance: number) {
    this.name = name;
    this.location = location;
    this.distance = distance
  }

  clone(): LocationFilter {
    return new LocationFilter(this.name, this.location, this.distance);
  }
}

class AreaFilter {
  min: number
  max: number

  constructor(min: number, max: number) {
    this.min = min;
    this.max = max;
  }

  clone(): AreaFilter {
    return new AreaFilter(this.min, this.max);
  }
}
class FieldFilter {

  static MaxLocationPerimeter = 10;
  static MinLocationPerimeter = 1;

  static MaxAreaRange = 100;
  static MinAreaRange = 0;

  location?: LocationFilter;
  area: AreaFilter = new AreaFilter(0, -1);
  searchText = '';

  public clone() {
    let filter =  new FieldFilter();
    filter.location = this.location?.clone();
    filter.area = this.area.clone();
    filter.searchText = this.searchText;
    return filter;
  }
}

export {
  LocationFilter,
  AreaFilter,
  FieldFilter
}
